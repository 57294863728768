import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Grid, Paper } from "@mui/material";
import { Typography } from "../../components/Typography";
import { motion } from "framer-motion";
import aboutUsContent from "./AboutUsContent";

import aboutUsImage from "../../assets/cardMedia/aboutUs.jpg";

// Motion components
const MotionBox = motion(Box);
const MotionPaper = motion(Paper);

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: "center",
}));

const SectionBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(8),
}));

const ValueCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "auto",
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  transition: "transform 0.3s ease-in-out",
  [theme.breakpoints.up("md")]: {
    height: 150, // Fixed height of 200px for md and above
  },
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const StoryImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: {
    xs: "fit-content",
    md: "auto",
  },
  borderRadius: theme.spacing(1),
}));

const AboutUs = () => {
  return (
    <StyledContainer maxWidth="lg">
      <HeaderTypography variant="titlePage" fontWeight="bold">
        About Us
      </HeaderTypography>

      <Typography
        variant="subHeading"
        align="center"
        paragraph
        sx={{ fontStyle: "italic" }}
      >
        {aboutUsContent.tagline}
      </Typography>

      <SectionBox>
        <Grid container spacing={4} alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { md: "center" },
              alignItems: { md: "center" },
            }}
          >
            <StoryImage src={aboutUsImage} alt="MOCHSHA Story" />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { md: "center" },
            }}
          >
            <Typography variant="subtitle" fontWeight="semiBold" gutterBottom>
              {aboutUsContent.brandStory.title}
            </Typography>
            {aboutUsContent.brandStory.paragraphs.map((paragraph, index) => (
              <Typography key={index} variant="body" paragraph>
                {paragraph}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </SectionBox>

      <SectionBox>
        <Typography
          variant="titlePage"
          fontWeight="semiBold"
          gutterBottom
          align="center"
        >
          Our Values
        </Typography>
        <Grid
          container
          columnSpacing={3}
          rowSpacing={8}
          alignItems="center"
          justifyContent="center"
        >
          {aboutUsContent.values.map((value, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MotionPaper
                component={ValueCard}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                style={{ width: "100%" }}
              >
                <Typography
                  variant="subHeading"
                  fontWeight="semiBold"
                  gutterBottom
                >
                  {value.title}
                </Typography>
                <Typography variant="body">{value.description}</Typography>
              </MotionPaper>
            </Grid>
          ))}
        </Grid>
      </SectionBox>

      <SectionBox>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} xl={6} sm={12}>
            <MotionBox
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  height: {
                    xs: "auto",
                    md: "200px",
                    xl: "175px",
                  },
                }}
              >
                <Typography
                  variant="titlePage"
                  fontWeight="semiBold"
                  gutterBottom
                >
                  {aboutUsContent.mission.title}
                </Typography>
                <Typography variant="body">
                  {aboutUsContent.mission.description}
                </Typography>
              </Paper>
            </MotionBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MotionBox
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  height: {
                    xs: "auto",
                    md: "200px",
                    xl: "175px",
                  },
                }}
              >
                <Typography
                  variant="titlePage"
                  fontWeight="semiBold"
                  gutterBottom
                >
                  {aboutUsContent.vision.title}
                </Typography>
                <Typography variant="body">
                  {aboutUsContent.vision.description}
                </Typography>
              </Paper>
            </MotionBox>
          </Grid>
        </Grid>
      </SectionBox>
    </StyledContainer>
  );
};

export default AboutUs;

import { FormControl, RadioGroup } from "@mui/material";
import { useController } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import { RadioCardInput } from "../../Inputs/RadioCardInput";

export function RadioCardGroupField({
  name,
  control,
  options,
  dataCy,
  dataPTag,
}) {
  const { field } = useController({ name, control });

  const theme = useTheme();

  return (
    <FormControl
      data-cy={dataCy}
      data-ptag={dataPTag}
      sx={{
        "& .MuiFormGroup-root": {
          width: "100%",
          flexWrap: "nowrap",
          flexDirection: "row",
          [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
            flexDirection: "column",
          },
        },
      }}
    >
      <RadioGroup {...field}>
        {options.map((option) => {
          return (
            <RadioCardInput
              isSelected={field.value === option.id}
              {...option}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

import { Stack, Skeleton, Box } from '@mui/material';

export function AppBarLogoSkeleton() {
  return (
    <Stack flexDirection="row" sx={{ minWidth: '150px' }}>
      <Box>
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
      </Box>
      <Stack
        justifyContent="center"
        sx={{
          paddingInline: '1.6rem',
          width: '100%',
        }}
      >
        <Skeleton
          animation="wave"
          height={10}
          width="80%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton animation="wave" height={10} />
      </Stack>
    </Stack>
  );
}

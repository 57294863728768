import { styled } from '@mui/material/styles';

import { Avatar, Stack } from '@mui/material';
import { Typography } from '../../../Typography';

export const ImageContainer = styled(Stack)({
  alignItems: 'center',
  gap: '0.8rem',
  inlineSize: '12rem',
});

export const ImageUploadContainer = styled(Stack)(
  ({ error, theme }) => {
    const { galacticGrape, cosmicTangerine, eclipseCherry } =
      theme.mochshaColorPalette;

    const defaultStyle = {
      '& svg': {
        color: galacticGrape[600],
      },
      alignItems: 'center',
      backgroundColor: galacticGrape[300],
      border: `1.25px solid ${galacticGrape[600]}`,
      borderRadius: '8px',
      cursor: 'pointer',
      gap: '2.4rem',
      transition: 'background-color 0.3s, border 0.3s, color 0.3s',
      inlineSize: '100%',
      blockSize: '8.4rem',
      justifyContent: 'center',
      paddingInline: '0.4rem',
      paddingBlock: '0.8rem',
    };

    const hoverStyle= {
      '&:hover': {
        '& svg': {
          color: cosmicTangerine[800],
        },
        background: cosmicTangerine[400],
        border: `1.25px solid ${cosmicTangerine[600]}`,
        color: cosmicTangerine[900],
      },
    };

    const errorStyles = {
      border: `1.25px solid ${eclipseCherry[700]}`,
      '& svg': {
        color: eclipseCherry[700],
      },
    };

    return {
      ...defaultStyle,
      ...hoverStyle,
      ...(error && errorStyles),
    };
  }
);

export const HelperTypographyStyled = styled(Typography)(
  ({ error, theme }) => {
    const { galacticGrape, eclipseCherry } =
    theme.mochshaColorPalette;

    return {
      color: error ? eclipseCherry[700] : galacticGrape[700],
      padding: '0 1rem',
      textAlign: 'center',
    };
  }
);

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  border: `1.25px solid ${theme.mochshaColorPalette.galacticGrape[400]}`,
  borderRadius: '8px',
  height: '12rem',
  width: '12rem',
}));

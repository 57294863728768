import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, Box } from "@mui/material";
import { Button } from "../Button";
import { useTheme } from "@mui/material/styles";
import PerformanceCompletedCheckbox from "./PerformanceCompletedCheckbox";
import ReviewSection from "./ReviewSection";
import IssuesSection from "./issuesSection";
import { useUser } from "../../context/UserContext";
import { useMutation } from "@tanstack/react-query";
import { rawAxios } from "../../axios";
import { toasts } from "../Toast/toasts";
import { useNavigate, useSearchParams } from "react-router-dom";

const ValidatePerformance = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { control, watch, handleSubmit, getValues } = useForm();
  const [issues, setIssues] = useState("");
  const { userDetails, role } = useUser();
  const [searchParams] = useSearchParams();

  const mutation = useMutation({
    mutationFn: (data) => rawAxios.post("/api/v1/ratings", data),
    onSuccess: () => {
      toasts.success({
        title: "Rating Submitted Successfully",
        description: "Thank you for your feedback",
      });
      navigate("/");
    },
    onError: () => {
      toasts.error({
        title: "Something went wrong",
        description: "Please try again",
      });
    },
  });

  const onSubmit = (data) => {
    console.log(data.eventRequirements, "formSubmission");
    const performanceCompleted = getValues("performanceCompleted");
    const submissionData = {};

    if (performanceCompleted === "yes") {
      submissionData.rater_user_id = userDetails.id;
      submissionData.ratee_user_id = searchParams.get("providerId");
      submissionData.event_id = searchParams.get("eventId");
      submissionData.rating = (data.eventRequirements + data.arrivalTime) / 2;
      submissionData.comment = data.comment; // Optional: Include if you have a comment field
    }

    console.log("Submission Data:", submissionData);
    mutation.mutate(submissionData);
  };

  const performanceCompleted = watch("performanceCompleted");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={4}>
        <Typography variant="titlePage" fontWeight="bold" gutterBottom>
          Validate Your Experience
        </Typography>
        <Typography variant="bodyStrong" fontWeight="bold" gutterBottom>
          {role === "client"
            ? "How was your experience with the client?"
            : "Was the performance completed as expected"}
        </Typography>
        <PerformanceCompletedCheckbox control={control} />

        {performanceCompleted === "yes" && (
          <ReviewSection control={control} role={role} />
        )}

        {performanceCompleted === "no" && (
          <IssuesSection
            control={control}
            issues={issues}
            setIssues={setIssues}
          />
        )}
        {performanceCompleted === "yes" && (
          <Box mt={4}>
            <Button type="submit">Submit</Button>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default ValidatePerformance;

import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { TextFieldStyled } from "./TextInputV2.styled";
import { InputLabel } from "../InputLabel";
import React from "react";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export const TextInputV2 = forwardRef(
  (
    {
      error,
      helperText,
      value,
      name,
      inputRef,
      size = "small",
      variant = "outlined",
      label,
      disabled,
      labelContext,
      reducesize = false,
      required,
      socialMediaIcon,
      ...rest
    },
    ref
  ) => {
    return (
      <Stack gap={label ? "0.8rem" : ""} width="100%">
        <InputLabel
          htmlFor={`${name}input`}
          label={label}
          disabled={disabled}
          labelContext={labelContext}
          required={required}
        />

        <TextFieldStyled
          name={name}
          value={value ?? ""}
          disabled={disabled}
          fullWidth
          size={size}
          variant={variant}
          error={error}
          {...rest}
          ref={inputRef ?? ref}
          reducesize={reducesize}
          helperText={helperText && <span>{helperText}</span>}
        />
      </Stack>
    );
  }
);

TextInputV2.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.node,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  labelContext: PropTypes.string,
  reducesize: PropTypes.bool,
  required: PropTypes.bool,
};

TextInputV2.defaultProps = {
  size: "small",
  variant: "outlined",
  reducesize: false,
};

TextInputV2.displayName = "TextInputV2Component";

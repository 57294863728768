import React from "react";
import { Stack, Typography, Slider } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { TextFieldV2 } from "../../components/Forms/Fields/TextFieldV2";
import { Controller } from "react-hook-form";

export function BidForm({ control, errors, budget, parent }) {
  const theme = useTheme();
  const { galacticGrape } = theme.mochshaColorPalette;

  const ResponsiveStack = styled(Stack)(({ theme }) => ({
    gap: "10rem",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      gap: "5rem",
      flexDirection: "column",
    },
  }));

  const FormSection = styled(Stack)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));

  const SliderSection = styled(Stack)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .MuiSlider-root": {
      width: "90%",
      margin: "1rem 0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }));

  return (
    <ResponsiveStack
      direction={parent ? "column" : "row"}
      sx={parent ? { gap: "5rem" } : { gap: "10rem" }}
      padding={parent ? "2rem" : "0rem"}
      alignItems={parent ? "flex-start" : "flex-end"}
    >
      <FormSection>
        <TextFieldV2
          control={control}
          name="proposal"
          label="What makes you the right person for this gig?"
          error={!!errors.description}
          helperText={errors.description?.message}
          placeholder="Eg. I am the right person for this gig because..."
          required
          multiline
          rows={4}
          // sx={{
          //   width: "100%",
          //   "& .MuiFormLabel-root": {
          //     whiteSpace: "break-spaces",
          //     maxWidth: "100%",
          //   },
          // }}
        />
      </FormSection>

      <SliderSection>
        <Typography
          variant="bodyTiny"
          color={galacticGrape[700]}
          fontWeight="bold"
        >
          Select your bid amount
        </Typography>

        <Controller
          name="amount"
          control={control}
          defaultValue={Number(budget.min)}
          render={({ field }) => (
            <>
              <Slider
                {...field}
                valueLabelDisplay="auto"
                min={Number(budget.min)}
                max={Number(budget.max)}
                step={100}
                marks={[
                  { value: Number(budget.min), label: `₹${budget.min}` },
                  { value: Number(budget.max), label: `₹${budget.max}` },
                ]}
                aria-label="Bid Amount"
                sx={{
                  color: galacticGrape[500],
                  "& .MuiSlider-thumb": {
                    backgroundColor: galacticGrape[700],
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: galacticGrape[900],
                  },
                  "& .MuiSlider-mark": {
                    backgroundColor: galacticGrape[500],
                  },
                }}
              />
              {errors.amount && (
                <Typography color="error" variant="caption">
                  {errors.amount.message}
                </Typography>
              )}
              <Typography variant="bodySmall" color={galacticGrape[700]} mt={1}>
                Selected amount: ₹{Number(field.value).toFixed(0)}
              </Typography>
            </>
          )}
        />
      </SliderSection>
    </ResponsiveStack>
  );
}

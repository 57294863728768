import { useMemo } from "react";

import { StyledAvatar, TypographyStyled } from "./Avatar.styled";
import { EmployeeRoundSmall } from "../../../assets/icons";

export const avatarsPalette = {
  bone: { backgroundColor: "#F2D5CD", color: "#6E4839" },
  easternBlue: { backgroundColor: "#CBD8E8", color: "#3F556B" },
  edgeWater: { backgroundColor: "#CFE0D6", color: "#405E4F" },
  lightPink: { backgroundColor: "#F5D4DF", color: "#8D425C" },
  tropicalBlue: { backgroundColor: "#CDDAEA", color: "#3F556B" },
};

const colors = Object.keys(avatarsPalette);

const getInitials = (firstName, lastName) =>
  `${firstName?.[0] || ""}${lastName?.[0] || ""}`;

export const UserAvatar = ({
  firstName,
  lastName,
  src,
  size = "medium",
  ...props
}) => {
  const fullName = useMemo(
    () => `${firstName || ""} ${lastName || ""}`,
    [firstName, lastName]
  );

  const nameInitials = useMemo(
    () => getInitials(firstName, lastName),
    [firstName, lastName]
  );

  const color = useMemo(
    () => colors[fullName.length % colors.length],
    [fullName]
  );

  return (
    <StyledAvatar
      src={src}
      alt={nameInitials}
      color={color}
      {...props}
      size={size}
    >
      {nameInitials ? (
        <TypographyStyled size={size}>{nameInitials}</TypographyStyled>
      ) : (
        <EmployeeRoundSmall />
      )}
    </StyledAvatar>
  );
};

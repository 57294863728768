import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { BidForm } from "./BidForm";
import { Button } from "../Button";

export function BidDialog({ open, onClose, budget, onSubmit, initialValue }) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: {
      // description: initialValue?.description || "",
      bidAmount: initialValue?.budget_min,
    },
  });

  const handleFormSubmit = (data) => {
    onSubmit(data);
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      // sx={{
      //   "& .MuiDialog-paper": {
      //     width: {
      //       xs: "95%", // Extra small devices
      //       sm: "80%", // Small devices
      //       md: "70%", // Medium devices
      //       lg: "60%", // Large devices
      //     },
      //     maxWidth: "800px",
      //     margin: {
      //       xs: "16px", // Extra small devices
      //       sm: "24px", // Small devices
      //       md: "32px", // Medium devices and up
      //     },
      //   },
      // }}
    >
      <DialogTitle
        sx={{
          padding: {
            xs: "16px",
            sm: "24px",
          },
        }}
      >
        Place Your Bid
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: { xs: 4, sm: 8 },
            top: { xs: 4, sm: 8 },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent
          sx={{
            padding: {
              xs: "16px",
              sm: "24px",
            },
          }}
        >
          <BidForm control={control} errors={errors} budget={budget} parent />
        </DialogContent>
        <DialogActions
          sx={{
            padding: {
              xs: "12px 16px",
              sm: "16px 24px",
            },
            gap: { xs: 1, sm: 2 },
          }}
        >
          <Button
            onClick={onClose}
            variant="secondary"
            sx={{
              minWidth: { xs: "80px", sm: "100px" },
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isValid}
            sx={{
              minWidth: { xs: "80px", sm: "100px" },
            }}
          >
            Submit Bid
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import RegisterPage from "./pages/Onboarding/RegisterPage";
import HomePage from "./pages/Home/HomePage";
import OtpPage from "./pages/Onboarding/OtpPage";
import LoginPage from "./pages/Onboarding/LoginPage";
import MusicianProfile from "./pages/MusicianProfile/MusicianProfile";
import { ClientOnBoardingDialog } from "./components/ClientOnboarding/ClientOnBoardingDialog";
import { MusicianOnBoardingDialog } from "./components/MusicianOnboarding/MusicianOnboardingDialog";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Language, LogOut, BellNotification, LightBulbOn } from "iconoir-react";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

import ValidatePerformance from "./components/validationScreen/Validation";
import { Button } from "./components/Button/Button";
import { Alert } from "./components/Alert";
import Discover from "./pages/Discover/Discover";
import "./App.css";
import themeObj from "./styles/theme";
import { AppBarToolbar } from "./components/AppBar/AppBar.styled";
import { AppBarLogo } from "./components/AppBar/AppBarLogo/AppBarLogo";
import { UserProfileMenuV2 } from "./components/UserProfileMenu/UserProfileMenuV2";
import Bidding from "./pages/Bidding/Bidding";
import { Toaster } from "./components/Toast";
import ManageBiddingPage from "./pages/Bidding/ManageBidding";
import { useAuth, AuthProvider } from "./context/AuthContext";
import { useUser, UserProvider } from "./context/UserContext";
import Journey from "./pages/Journey/Journey";
import { Profile } from "./components/Profile/Profile";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import Demo from "./pages/Demo/Demo";
import PrivacyPolicy from "./pages/PublicPages/Privacy";
import TermsOfUse from "./pages/PublicPages/TermsOfUse";
import AboutUs from "./pages/PublicPages/AboutUs";
import Services from "./pages/PublicPages/Services";

function App() {
  const AppContent = () => {
    const josysUiTheme = createTheme(themeObj);
    const navigate = useNavigate();
    const location = useLocation();

    const { authDetails } = useAuth();
    const { userDetails, isLoading } = useUser();

    const [currentDayEvents, setCurrentDayEvents] = useState([]);
    const [timeRemaining, setTimeRemaining] = useState({});

    const { data: eventsData, isLoading: isLoadingEvents } = useQuery({
      queryKey: ["TypeofEvent", "confirmed", 1, 100],
      queryFn: async () => {
        const response = await axios.get(
          `https://api.mochsha.in/api/v1/eventsInfo?status=${"confirmed"}&page=${1}&limit=${100}`,
          {
            withCredentials: true,
          }
        );
        return response.data;
      },
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      retry: false,
    });

    const menulist = [
      {
        key: "profile",
        label: "Profile",
        icon: <Language />,
        onClick: () => {
          navigate("/profile");
        },
      },
      {
        key: "notifications",
        label: "Notifications",
        icon: <BellNotification />,
        onClick: () => {
          navigate("/profileonboarding");
        },
      },
      {
        key: "signOut",
        label: "Sign Out",
        icon: <LogOut />,
        divider: true,
        sx: {
          color: "red",
        },
        onClick: async () => {
          try {
            await axios.get("https://api.mochsha.in/api/v1/users/logout", {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            });
            console.log("Logout successful");
            window.location.href = "/login";
          } catch (error) {
            navigate("/login");
          }
        },
      },
    ];

    const findTodaysEvents = (events) => {
      if (!events) return [];

      const now = DateTime.now();
      console.log("Current time:", now.toISO());

      return events.filter((event) => {
        const eventDate = DateTime.fromISO(event.event_date);
        const endTime = DateTime.fromFormat(event.end_time, "HH:mm:ss");

        const eventEndDateTime = eventDate.set({
          hour: endTime.hour,
          minute: endTime.minute,
          second: endTime.second,
        });

        console.log("Event:", event.title);
        console.log("Event end time:", eventEndDateTime.toISO());
        console.log(
          "24h after end:",
          eventEndDateTime.plus({ hours: 24 }).toISO()
        );

        return (
          eventDate.hasSame(now, "day") &&
          now <= eventEndDateTime.plus({ hours: 24 })
        );
      });
    };

    useEffect(() => {
      if (!currentDayEvents.length) return;

      const timers = currentDayEvents.map((event) => {
        return setInterval(() => {
          const now = DateTime.now();
          const eventDate = DateTime.fromISO(event.event_date);
          const endTime = DateTime.fromFormat(event.end_time, "HH:mm:ss");

          const eventEndDateTime = eventDate.set({
            hour: endTime.hour,
            minute: endTime.minute,
            second: endTime.second,
          });

          const deadline = eventEndDateTime.plus({ hours: 24 });
          const diff = deadline
            .diff(now, ["hours", "minutes", "seconds"])
            .toObject();

          if (diff.hours <= 0 && diff.minutes <= 0 && diff.seconds <= 0) {
            setCurrentDayEvents((prev) =>
              prev.filter((e) => e.id !== event.id)
            );
          } else {
            // setTimeRemaining((prev) => ({
            //   ...prev,
            //   [event.id]: `${Math.floor(diff.hours)}h ${Math.floor(
            //     diff.minutes
            //   )}m ${Math.floor(diff.seconds)}s`,
            // }));
          }
        }, 1000);
      });

      return () => timers.forEach((timer) => clearInterval(timer));
    }, [currentDayEvents]);

    useEffect(() => {
      if (eventsData?.data) {
        const todayEvents = findTodaysEvents(eventsData.data);
        setCurrentDayEvents(todayEvents);
      }
    }, [eventsData]);

    const unProtectedRoutes = [
      "/register",
      "/otp",
      "/login",
      "/demo",
      "/privacy",
      "/terms",
      "/about",
      "/services",
    ];

    const UnprotectedRoute = ({ children }) => {
      const location = useLocation();

      if (isLoading) {
        return (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        );
      }

      const isAuthenticated = userDetails;

      if (isAuthenticated) {
        if (userDetails?.profile) {
          return <Navigate to={location.state?.from || "/"} replace />;
        }
        return <Navigate to="/journey" replace />;
      }

      return children;
    };

    const FrozenRoute = ({ children }) => {
      const location = useLocation();

      if (isLoading) {
        return (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        );
      }

      if (!userDetails?.profile && location.pathname !== "/journey") {
        return <Navigate to="/journey" />;
      }

      return children;
    };

    const ProtectedRoute = ({ children }) => {
      const location = useLocation();

      if (isLoading) {
        return (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        );
      }

      const isAuthenticated = authDetails || userDetails;

      if (!isAuthenticated) {
        return (
          <Navigate
            to="/login"
            state={{
              from: location.pathname,
              message: "Please log in to access this page",
            }}
            replace
          />
        );
      }

      return children;
    };

    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={josysUiTheme}>
            <Toaster />
            <AppBarToolbar
              secondaryColor={unProtectedRoutes.includes(location.pathname)}
            >
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                gap="2rem"
              >
                <AppBarLogo
                  onLogoClick={() => {
                    navigate("/");
                  }}
                />
                {
                  <Box sx={{ marginInlineStart: "auto" }}>
                    {!unProtectedRoutes.includes(location.pathname) && (
                      <UserProfileMenuV2
                        menuItems={menulist}
                        userDetails={userDetails}
                      />
                    )}
                  </Box>
                }
              </Stack>
            </AppBarToolbar>

            {!unProtectedRoutes.includes(location.pathname) && (
              <Stack spacing={1} sx={{ position: "relative", zIndex: 800 }}>
                {currentDayEvents.map((event) => (
                  <Alert
                    key={event.id}
                    color="eclipseCherry"
                    icon={LightBulbOn}
                    title={`Event ID: ${event.id}`}
                    description={`Time remaining: ${
                      timeRemaining[event.id] || ""
                    } | ${event.title}`}
                    onClose={() => {
                      setCurrentDayEvents((prev) =>
                        prev.filter((e) => e.id !== event.id)
                      );
                    }}
                    actions={
                      <Button
                        onClick={() => {
                          navigate(
                            `/validate?providerId=${event?.bids[0]?.provider.id}&eventId=${event.id}`
                          );
                        }}
                      >
                        Provide Rating
                      </Button>
                    }
                  />
                ))}
              </Stack>
            )}

            <Routes>
              <Route
                path="/register"
                element={
                  <UnprotectedRoute>
                    <RegisterPage />
                  </UnprotectedRoute>
                }
              />
              <Route
                path="/otp"
                element={
                  <UnprotectedRoute>
                    <OtpPage />
                  </UnprotectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <UnprotectedRoute>
                    <LoginPage />
                  </UnprotectedRoute>
                }
              />

              {/* Protected routes */}
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <HomePage />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobposting"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <ClientOnBoardingDialog />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bidding"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <Bidding />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manageBidding"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <ManageBiddingPage
                        userRole={userDetails?.profile?.user_role}
                      />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <Profile userDetails={userDetails} />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <DashboardPage />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route path="/demo" element={<Demo />} />
              <Route
                path="/musicianonboarding"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <MusicianOnBoardingDialog />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/musicianprofile"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <MusicianProfile />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/validate"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <ValidatePerformance />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/journey"
                element={
                  <ProtectedRoute>
                    <Journey />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/privacy"
                element={
                  // <UnprotectedRoute>
                  <PrivacyPolicy />
                  // </UnprotectedRoute>
                }
              />
              <Route
                path="/terms"
                element={
                  // <UnprotectedRoute>
                  <TermsOfUse />
                  // </UnprotectedRoute>
                }
              />
              <Route
                path="/about"
                element={
                  // <UnprotectedRoute>
                  <AboutUs />
                  // </UnprotectedRoute>
                }
              />

              <Route path="/services" element={<Services />} />
              <Route
                path="/discover"
                element={
                  <ProtectedRoute>
                    <FrozenRoute>
                      <Discover />
                    </FrozenRoute>
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    );
  };

  return (
    <AuthProvider>
      <Router>
        <UserProvider>
          <AppContent />
        </UserProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;

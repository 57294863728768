import React from "react";
import { Stack, Typography, Chip, styled } from "@mui/material";

export function JobDetails({
  bidConfirmation,
  categories,
  budget,
  date,
  location,
  description,
  title,
}) {
  const LocationStack = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: "0.5rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  }));

  const BottomStack = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gap: "2rem",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }));

  return (
    <Stack spacing="2rem" padding="2rem">
      <Stack spacing="0.5rem">
        <Typography variant="body">{title}</Typography>
        <LocationStack>
          <Typography variant="body" color="textSecondary">
            📍 {location}
          </Typography>
          <Typography variant="body" color="textSecondary">
            📅 {date}
          </Typography>
        </LocationStack>
      </Stack>

      <Typography variant="bodySmall" fontWeight="regular">
        {description}
      </Typography>

      <BottomStack>
        <Stack spacing="0.5rem">
          <Typography variant="bodySmallStrong">Categories</Typography>
          <Stack direction="row" spacing="0.5rem">
            {categories.map((category) => (
              <Chip
                key={category}
                label={category}
                variant="outlined"
                size="medium"
              />
            ))}
          </Stack>
        </Stack>
        {bidConfirmation && (
          <Stack>
            <Typography variant="bodySmallStrong">
              ₹{budget.min} - ₹{budget.max}
            </Typography>
            <Typography variant="bodySmall">Budget</Typography>
          </Stack>
        )}
      </BottomStack>
    </Stack>
  );
}

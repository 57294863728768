import { palette, mochshColorpalette } from "./palette";

import { typographyThemeOptions } from "./typography";

const theme = {
  palette: { ...palette.palette },
  mochshaColorPalette: { ...mochshColorpalette.palette },
  typography: typographyThemeOptions,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#F5F5F5",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#F30175",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: "#F8761F",
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "#F8761F",
          },
          "&.Mui-completed": {
            color: "#F8761F",
          },
          "&.Mui-disabled": {
            color: "#E0E0E0",
          },
        },
      },
    },
    MuiStepIcon: {
      height: "12px",
      styleOverrides: {
        root: {
          color: "#E0E0E0",
          "&.Mui-active": {
            color: "black",
          },
          "&.Mui-completed": {
            color: "black",
          },
          "&.Mui-disabled": {
            color: "#E0E0E0",
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          borderColor: "#E0E0E0",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "13px",
          lineHeight: "24px",
          letterSpacing: "0.15px",
          color: "#000000",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          "&.Mui-selected": {
            backgroundColor: "#00000014",
            "&:hover": {
              backgroundColor: "#00000014",
            },
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderColor: "#E0E0E0",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedInherit: {
          borderColor: "#E0E0E0",
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked": {
            "&.Mui-disabled": {
              color: "#bdbdbd",
            },
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "8px 24px",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          marginBottom: "12px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          lineHeight: 1.43,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: "1.25rem !important",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          background: "hsl(173, 19%, 87%)",
          paddingInline: "2rem",
          paddingBlock: "0.8rem",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          titleHero: "p",
          titlePage: "p",
          subtitle: "p",
          heading: "p",
          subHeading: "p",
          bodyBase: "p",
          bodyStrong: "p",
          bodyEmphasis: "p",
          bodyLink: "p",
          bodySmall: "p",
          bodySmallStrong: "p",
          bodyCode: "p",
        },
      },
    },
  },
};

export default theme;

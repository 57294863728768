import { styled, Typography } from "@mui/material";

import { fontWeightStyles } from "../../styles/typography";

export const TypographyStyled = styled(Typography)(
  ({ fontWeight = "regular" }) => {
    return {
      ...fontWeightStyles[fontWeight],
    };
  }
);

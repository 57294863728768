import { useMutation } from "@tanstack/react-query";
import { rawAxios } from "../../axios";
import { toasts } from "../../components/Toast/toasts";

export const useBidMutation = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (bidData) => rawAxios.post("/api/v1/bids", bidData),
    onSuccess: (response) => {
      toasts.success({
        title: "Success",
        description: "Bid submitted successfully",
      });
      onSuccess?.(response);
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || error.message;
      const errorResponse = error.response?.data;

      toasts.error({
        title: "Something went wrong",
        description: errorMessage || "Please try again",
      });

      onError?.({ error, errorResponse });
    },
  });
};

import React from "react";
import { Stack } from "@mui/material";
import HomePageContent from "../../components/HomePage/HomePageContent";

function HomePage() {
  return (
    <Stack>
      <HomePageContent />
    </Stack>
  );
}

export default HomePage;

// frontend/src/components/HomePage/HomePageContent.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Chip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
import HeroSection from "../../components/hero/HeroSection";
import CardCarousel from "../CardCarousel/CardCarousel";
import { useForm } from "react-hook-form";
import { Footer } from "../footer/FooterSection";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import { rawAxios } from "../../axios";
import { ClientOnBoardingDialog } from "../ClientOnboarding/ClientOnBoardingDialog";
import { MusicianOnBoardingDialog } from "../MusicianOnboarding/MusicianOnboardingDialog";
import { useQueryClient } from "@tanstack/react-query";
import { BidDialog } from "../Bidding/BidDialog";
import { useBidMutation } from "../Bidding/useBidMutation";

import { mockProviderData, mockEventData, categories } from "./mockData";

const fetchProviderDetails = async () => {
  const response = await rawAxios.get("/api/v1/providers?per_page=5");
  console.log("Provider Details: ", response.data);
  return response.data;
};

const fetchEventDetails = async () => {
  const response = await rawAxios.get("/api/v1/events?per_page=5");
  console.log("Event Details: ", response.data);
  return response.data;
};

const HomePageContent = () => {
  const { userDetails, role } = useUser();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const User = userDetails ? userDetails.profile.first_name : "Guest";

  const queryClient = useQueryClient();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const handleClick = () => {
    navigate("/discover");
  };

  const handleSearch = (searchValue) => {
    console.log("Search Value:", searchValue);
  };

  const [isClientOnboardingDialogOpen, setIsClientOnboardingDialogOpen] =
    useState(false);
  const [isMusicianOnboardingDialogOpen, setIsMusicianOnboardingDialogOpen] =
    useState(false);

  const [isBidDialogOpen, setIsBidDialogOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const {
    data: eventOrProviderDetails,
    error,
    isLoading,
  } = useQuery({
    queryKey: [
      `${role === "client" ? "providerDetails" : "eventDetails"}`,
      role,
    ],
    queryFn: () => {
      if (role === "client") {
        return fetchProviderDetails();
      } else if (role === "provider") {
        return fetchEventDetails();
      }
    },
    enabled: !!role,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const cards = mockProviderData.data;

  useEffect(() => {
    if (role === "provider" && !userDetails.provider) {
      console.log("Provider Details: ", userDetails.provider, role);
      setIsMusicianOnboardingDialogOpen(true);
    }
    setIsClientOnboardingDialogOpen(false);
  }, [role, userDetails]);

  console.log("Event or Provider Details: ", eventOrProviderDetails);

  const handleBidNowClick = (selectedCard) => {
    setSelectedCard(selectedCard);
    console.log("Selected Card: ", selectedCard);
    setIsBidDialogOpen(true);
  };

  const { mutate } = useBidMutation({
    onSuccess: () => {
      setIsBidDialogOpen(false);
    },
    onError: ({ error, errorResponse }) => {
      console.error("Bid submission failed:", error);
    },
  });

  const handleBidSubmit = (data) => {
    console.log("Bid Data: ", data);
    mutate({
      event_id: selectedCard.id,
      provider_id: userDetails?.id,
      amount: data.amount,
      proposal: data.proposal, // TODO: enable when Bids API works for message
    });
  };

  return (
    <Stack spacing="2rem">
      <HeroSection
        User={User}
        control={control}
        watch={watch}
        errors={errors}
        onSearch={handleSearch}
      />
      <Stack spacing="2rem">
        <Stack paddingX={isMobile ? "2rem" : "4rem"} spacing="2rem">
          {/* Opportunities at Mochsha */}
          {role === "client" && (
            <Box>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: "5rem" }}
                textAlign={{ xs: "center", sm: "left" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="heading" fontWeight="bold">
                  Profile Alert!
                  </Typography>
                  <Typography variant="subHeading">
                  Explore Active Profiles for your music event requirements
                  </Typography>
                </Box>
                <Stack direction="row" spacing="1rem">
                  {role === "client" && (
                    <Button
                      onClick={() => setIsClientOnboardingDialogOpen(true)}
                    >
                      Post an Event
                    </Button>
                  )}
                  <Button
                    variant="text"
                    sx={{
                      textDecoration: "underline",
                      color: theme.mochshaColorPalette.deepCharcoal[900],
                    }}
                    onClick={handleClick}
                  >
                    See More
                  </Button>
                </Stack>
              </Stack>
            </Box>
          )}
          {role === "client" && !isLoading && (
            <CardCarousel cards={eventOrProviderDetails.data} />
          )}

          {/* Discover Trends */}
          {/* {role === "client" && (
            <Box textAlign="center">
              <Typography variant="heading" fontWeight="bold">
                Discover Trends
              </Typography>
              <Typography variant="subHeading">
                What's Hot in the Music Scene
              </Typography>
              <Box mt={2}>
                {categories.map((category, index) => (
                  <Chip
                    key={index}
                    label={category}
                    sx={{ margin: "0.5rem" }}
                  />
                ))}
              </Box>
            </Box>
          )}
          {role === "client" && <CardCarousel cards={cards} />} */}

          {/* Local Gems */}
          {role === "provider" && (
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="heading" fontWeight="bold">
                    Gig Alert!
                  </Typography>
                  <Typography variant="subHeading">
                   Explore Most Recently Posted Events
                  </Typography>
                </Box>

                <Stack direction="row" spacing="1rem">
                  <Button
                    variant="text"
                    sx={{ textDecoration: "underline" }}
                    onClick={handleClick}
                  >
                    See More
                  </Button>
                </Stack>
              </Stack>
            </Box>
          )}
          {role === "provider" && eventOrProviderDetails?.data && (
            <CardCarousel
              cards={eventOrProviderDetails?.data}
              onBidNowClick={handleBidNowClick}
            />
          )}
        </Stack>
      </Stack>

      <ClientOnBoardingDialog
        isDialogOpen={isClientOnboardingDialogOpen}
        setIsDialogOpen={setIsClientOnboardingDialogOpen}
        onSuccess={async () => {
          await queryClient.invalidateQueries({
            queryKey: ["userDetails"],
          });
          setIsClientOnboardingDialogOpen(false);
        }}
      />

      <MusicianOnBoardingDialog
        isDialogOpen={isMusicianOnboardingDialogOpen}
        setIsDialogOpen={setIsMusicianOnboardingDialogOpen}
        onClose={() => {
          setIsMusicianOnboardingDialogOpen(false);
        }}
      />

      {selectedCard && (
        <BidDialog
          open={isBidDialogOpen}
          onClose={() => setIsBidDialogOpen(false)}
          onSubmit={handleBidSubmit}
          budget={{
            min: selectedCard.budget_min,
            max: selectedCard.budget_max,
          }}
          initialValue={selectedCard}
        />
      )}
      <Footer />
    </Stack>
  );
};

export default HomePageContent;

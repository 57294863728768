import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "../../Button";

export const ReviewBidDataGridDialog = ({
  open,
  onClose,
  initialData,
  onReviewBid,
  eventDetails,
  countParam,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { galacticGrape } = theme.mochshaColorPalette;

  const columns = [
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={`https://api.mochsha.in${params.row.provider.profile.profile_picture}`}
            alt={params.row.provider.profile.display_name}
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              border: `2px solid ${galacticGrape[200]}`,
            }}
          />
          <div>
            <Typography variant="body1" fontWeight="600">
              {params.row.provider.profile.display_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {params.row.provider.email}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Bid Amount",
      width: 130,
      renderCell: (params) => (
        <Typography>₹{Number(params.value).toLocaleString("en-IN")}</Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Typography
          sx={{
            textTransform: "capitalize",
            color: params.value === "pending" ? "warning.main" : "success.main",
            fontWeight: 500,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "created_at",
      headerName: "Submitted On",
      width: 180,
      renderCell: (params) => (
        <Typography>
          {new Date(params.value).toLocaleString("en-IN", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      ),
    },
    ...(countParam !== "confirmed"
      ? [
          {
            field: "actions",
            headerName: "Actions",
            width: 120,
            renderCell: (params) => (
              <Button onClick={() => onReviewBid(params.row)} size="small">
                Review Bid
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableRowSelectionOnClick
      onRowClick={(params) => onReviewBid(params.row)}
      maxWidth="lg"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          m: isMobile ? 0 : 2,
          maxHeight: isMobile ? "100%" : "90vh",
          bgcolor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle
        sx={{
          px: { xs: 2, sm: 3 },
          py: 2,
          position: "sticky",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h5" fontWeight="600">
          Review Bids ({initialData.length})
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          px: { xs: 2, sm: 3 },
          py: 3,
        }}
      >
        <DataGrid
          rows={initialData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          autoHeight
          disableSelectionOnClick
          onRowClick={(params) => onReviewBid(params.row)}
          getRowId={(row) => row.id || Math.random()}
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            "& .MuiDataGrid-cell": {
              borderColor: theme.palette.divider,
            },
            "& .MuiDataGrid-columnHeaders": {
              bgcolor: "background.paper",
              borderColor: theme.palette.divider,
            },
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          px: { xs: 2, sm: 3 },
          py: 2,
          position: "sticky",
          bottom: 0,
          bgcolor: "background.paper",
          borderTop: 1,
          borderColor: "divider",
        }}
      >
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

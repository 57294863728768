import { Stack, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  CheckCircle,
  PlusCircle,
  WarningCircle,
  Xmark,
  XmarkCircle,
} from 'iconoir-react';

import { IconoirIcon } from '../IconoirIcon';
import { ToastBody } from './ToastBody';
import { CloseButton } from './Toaster.styled';
import { ToastFooterLink } from './ToastFooterLink';


const toastIcons = {
  info: PlusCircle,
  success: CheckCircle,
  warning: WarningCircle,
  error: XmarkCircle,
};

export const Toast = ({
  closeToast,
  type,
  title,
  description,
  href,
  linkText,
}) => {
  const { mochshaColorPalette } = useTheme();

  const toastColors = {
    info: mochshaColorPalette.galacticGrape[700],
    success: mochshaColorPalette.auroraLime[700],
    warning: mochshaColorPalette.solarSquash[800],
    error: mochshaColorPalette.eclipseCherry[700],
  };

  return (
    <Box sx={{ padding: '2rem', paddingLeft: '1.6rem' }}>
      <Stack direction="row" gap="1.6rem" alignItems="flex-start">
        <Stack>
          <IconoirIcon icon={toastIcons[type]} color={toastColors[type]} />
        </Stack>

        <Stack alignItems="flex-start" gap="1.2rem" flexGrow={1}>
          <ToastBody title={title} description={description} />

          {href && <ToastFooterLink href={href} linkText={linkText} />}
        </Stack>

        <CloseButton onClick={closeToast} size="small" disableRipple>
          <IconoirIcon icon={Xmark} width="2rem" height="2rem" />
        </CloseButton>
      </Stack>
    </Box>
  );
};

import React from "react";
import { Box, Dialog, Stack, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SuccessIcon } from "../../assets/icons/successIcon";
import { Typography } from "../Typography";

const ResponsiveDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: "12px",
    width: "38rem",
    maxWidth: "90%",
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4.4, 4, 2.8),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3, 2, 2),
  },
}));

const ActionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

export const SuccessDialog = ({
  open,
  title,
  description,
  actions,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ResponsiveDialog open={open} {...rest}>
      <Stack>
        <ContentStack>
          <Stack gap={3.6} justifyContent="center" alignItems="center">
            <Typography
              variant="heading"
              fontWeight="medium"
              color={theme.mochshaColorPalette.galacticGrape[900]}
              textAlign="center"
            >
              {title}
            </Typography>

            <Box>
              <SuccessIcon />
            </Box>

            <Typography
              variant="bodySmall"
              color={theme.mochshaColorPalette.galacticGrape[900]}
              textAlign="center"
            >
              {description}
            </Typography>
          </Stack>
        </ContentStack>

        <ActionBox>{actions}</ActionBox>
      </Stack>
    </ResponsiveDialog>
  );
};
import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Stack,
  IconButton,
  Pagination,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconoirIcon } from "../../components/IconoirIcon";
import { ArrowLeft } from "iconoir-react";
import { Header } from "../../components/Bidding/Header";
import { JobDetails } from "../../components/Bidding/JobDetails";
import { Typography } from "../../components/Typography";
import { BidSectionCard } from "../../components/Bidding/BidSectionCard";
import { BidDialog } from "../../components/Bidding/BidDialog";
import {
  jobData,
  pastBidsData,
  bidRequestsData,
  draftsData,
} from "../../MockData/ManageBidding";
import { toasts } from "../../components/Toast/toasts";
import { TabPanel } from "../../components/Tabs";
import { Button } from "../../components/Button";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "2rem",
}));
const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.mochshaColorPalette.galacticGrape[600]}`,
  "& .MuiTabs-indicator": {
    backgroundColor: theme.mochshaColorPalette.galacticGrape[900],
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: theme.mochshaColorPalette.galacticGrape[700],
  "&.Mui-selected": {
    color: theme.mochshaColorPalette.galacticGrape[900],
  },
  "&:hover": {
    color: theme.mochshaColorPalette.galacticGrape[800],
  },
}));

const JobCard = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.mochshaColorPalette.galacticGrape[600]}`,
  borderRadius: "1px",
}));

const BidBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.mochshaColorPalette.galacticGrape[900],
  width: "12rem",
  height: "8rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "0 1rem",
  gap: "0.5rem",
}));

const BudgetBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.mochshaColorPalette.galacticGrape[300],
  border: `0.5px solid ${theme.mochshaColorPalette.galacticGrape[600]}`,
  minWidth: "12rem",
  height: "8rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "0 1rem",
  gap: "0.5rem",
}));

const WhiteTypography = styled(Typography)({
  color: "white",
});

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: theme.mochshaColorPalette.galacticGrape[900],
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: theme.mochshaColorPalette.galacticGrape[900],
    color: "white",
    "&:hover": {
      backgroundColor: theme.mochshaColorPalette.galacticGrape[800],
    },
  },
}));

export default function ManageBiddingPage({ userRole }) {
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const ITEMS_PER_PAGE = 3;

  console.log(userRole);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1); // Reset to first page when changing tabs
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [bidDialogOpen, setBidDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [editingJob, setEditingJob] = useState(null);

  const handleBidNow = (job) => {
    setSelectedJob(job);
    setBidDialogOpen(true);
  };

  const handleBidSubmit = (data) => {
    toasts.success({
      title: "Bid submitted successfully",
      description: "Your bid has been successfully submitted.",
    });

    console.log("Bid submitted:", data);
  };

  const handleEditBid = (job) => {
    console.log (job)
    setEditingJob(job);
    setBidDialogOpen(true);
  };

  const handleDeleteBid = (job) => {
    // Implement delete functionality here
    console.log("Deleting bid for job:", job);
    toasts.success({
      title: "Bid deleted",
      description: "Your bid has been successfully deleted.",
    });
  };

  const renderActiveJobCard = (job) => (
    <JobCard key={job.title}>
      <Header avatar={""} name={job.name} rating={job.rating} role={job.role} />
      <JobDetails
        bidConfirmation={false}
        categories={job.categories}
        budget={job.budget}
        date={job.date}
        location={job.location}
        description={job.description}
        title={job.title}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0 2rem 2rem 2rem"
      >
        <Stack direction="row" gap="1rem">
          <BidBox>
            <WhiteTypography variant="heading" fontWeight="bold">
              ₹{job.yourBid}
            </WhiteTypography>
            <WhiteTypography variant="bodyTiny">Your Bid</WhiteTypography>
          </BidBox>
          <BudgetBox>
            <Typography variant="heading" fontWeight="bold">
              ₹{job.budgetDisplay}
            </Typography>
            <Typography variant="bodyTiny">Budget</Typography>
          </BudgetBox>
        </Stack>
        <Stack alignItems="flex-end" spacing={1.5}>
  <Box>
    <Typography variant="bodyStrong" fontWeight="bold">
      {job.endDate}
    </Typography>
    <Typography variant="bodyTiny" color="text.secondary">
      End Date
    </Typography>
  </Box>
  <Stack direction="row" spacing={1}>
    <Button
      variant="secondary"
      size="small"
      onClick={() => handleEditBid(job)}
    >
      Edit
    </Button>
    <Button
      variant="secondary"
      size="small"
      onClick={() => handleDeleteBid(job)}
    >
      Delete
    </Button>
  </Stack>
</Stack>
      </Stack>
    </JobCard>
  );

  const renderPastBidCard = (job) => (
    <JobCard key={job.title}>
      <Header avatar={""} name={job.name} rating={job.rating} role={job.role} />
      <JobDetails
        bidConfirmation={false}
        categories={job.categories}
        budget={job.budget}
        date={job.date}
        location={job.location}
        description={job.description}
        title={job.title}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0 2rem 2rem 2rem"
      >
        <Stack direction="row" gap="1rem">
          <BidBox>
            <WhiteTypography variant="heading" fontWeight="bold">
              ₹{job.yourBid}
            </WhiteTypography>
            <WhiteTypography variant="bodyTiny">Your Bid</WhiteTypography>
          </BidBox>
          <BudgetBox>
            <Typography variant="heading" fontWeight="bold">
              ₹{job.budget.min} - ₹{job.budget.max}
            </Typography>
            <Typography variant="bodyTiny">Budget Range</Typography>
          </BudgetBox>
        </Stack>
        <Typography variant="bodyStrong">{job.status}</Typography>
      </Stack>
    </JobCard>
  );

  const renderBidRequestCard = (job) => (
    <Stack direction="row" gap="2rem">
      <Box width="70%">
        <JobCard key={job.title} flexGrow={1}>
          <Header
            avatar={""}
            name={job.name}
            rating={job.rating}
            role={job.role}
          />
          <JobDetails
            bidConfirmation={false}
            categories={job.categories}
            budget={job.budget}
            date={job.date}
            location={job.location}
            description={job.description}
            title={job.title}
          />
        </JobCard>
      </Box>

      <Box width="30%">
        <BidSectionCard
          {...job.bidSection}
          onBidNow={() => handleBidNow(job)}
        />
      </Box>
    </Stack>
  );

  const renderDraftCard = (job) => (
    <JobCard key={job.title}>
      <Header avatar={""} name={job.name} rating={job.rating} role={job.role} />
      <JobDetails
        bidConfirmation={false}
        categories={job.categories}
        budget={job.budget}
        date={job.date}
        location={job.location}
        description={job.description}
        title={job.title}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0 2rem 2rem 2rem"
      >
        <Stack direction="row" gap="1rem">
          <BidBox>
            <WhiteTypography variant="heading" fontWeight="bold">
              ₹{job.draftBid}
            </WhiteTypography>
            <WhiteTypography variant="bodyTiny">Draft Bid</WhiteTypography>
          </BidBox>
          <BudgetBox>
            <Typography variant="heading" fontWeight="bold">
              ₹{job.budgetDisplay}
            </Typography>
            <Typography variant="bodyTiny">Budget</Typography>
          </BudgetBox>
        </Stack>
        <Stack alignItems="flex-end">
          <Typography variant="bodyStrong" fontWeight="bold">
            {job.lastEdited}
          </Typography>
          <Typography variant="bodyTiny">Last Edited</Typography>
        </Stack>
      </Stack>
    </JobCard>
  );

  const paginateData = (data) => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return data.slice(startIndex, endIndex);
  };

  const renderPaginatedContent = (data, renderFunction) => (
    <>
      <Stack spacing="2rem">{paginateData(data).map(renderFunction)}</Stack>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <StyledPagination
          count={Math.ceil(data.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </>
  );

  const navigate = useNavigate();

  return (
    <StyledBox>
      <Stack direction="row" alignItems="center" gap="0.5rem">
        <IconButton onClick={() => navigate(-1)}>
          <IconoirIcon width="2rem" height="2rem" icon={ArrowLeft} />
        </IconButton>
      </Stack>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {["Active", "Past Bids", "Bid Requests", "Drafts"].map((label) => (
          <StyledTab key={label} label={label} />
        ))}
      </StyledTabs>
      <TabPanel value={value} index={0}>
        {renderPaginatedContent(jobData, renderActiveJobCard)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {renderPaginatedContent(pastBidsData, renderPastBidCard)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {renderPaginatedContent(bidRequestsData, renderBidRequestCard)}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {renderPaginatedContent(draftsData, renderDraftCard)}
      </TabPanel>

      <BidDialog
        open={bidDialogOpen}
        onClose={() => {
          setBidDialogOpen(false);
          setEditingJob(null);
        }}
        budget={editingJob?.budget || selectedJob?.budget || { min: 0, max: 0 }}
        onSubmit={handleBidSubmit}
        initialValue={editingJob ? { bidAmount: editingJob.yourBid, description: editingJob.description } : null}
      />
    </StyledBox>
  );
}

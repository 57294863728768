import React from "react";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import externalLinkIcon from "../../assets/icons/external-link.png";
import ProfilePicture from "../../assets/samples/profilepic.jpg"; // Import the external link icon

const Profile = () => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate("/musicianonboarding/pinfo");
  };
  return (
    <div className="profile-container">
      <div className="profile-top">
        <div className="profile-picture">
          <img src={ProfilePicture} alt="" />
        </div>
        <div className="profile-info">
          <h1>Shreyas Bharadhwaj</h1>
          <p>
            Electric Guitarist{" "}
            <div className="profile-rating">
              <span>
                <FontAwesomeIcon icon={faStarRegular} />
              </span>
              <span>4.8</span>
            </div>
          </p>
        </div>
        <div className="profile-edit">
          <FontAwesomeIcon
            icon={faEdit}
            className="edit-icon"
            onClick={handleEdit}
          />
        </div>
      </div>
      <div className="profile-bottom">
        <h2>About Me</h2>
        <p>
          John Doe is a renowned musician known for his eclectic style and
          innovative compositions.
        </p>
        <div className="profile-links">
          <div className="profile-link">
            <h3>Location</h3>
            <p>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="location-icon"
              />{" "}
              Bengaluru, KA
            </p>
          </div>
          <div className="profile-link">
            <h3>LinkedIn</h3>
            <a
              href="https://www.linkedin.com/in/shreyas-bharadhwaj/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shreyas Bharadhwaj
              <img
                src={externalLinkIcon}
                alt="External Link"
                className="external-link-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <h3>Sample</h3>
            <a
              href="https://www.instagram.com/shreyas_bharadhwaj/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sample
              <img
                src={externalLinkIcon}
                alt="External Link"
                className="external-link-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <h3>Email</h3>
            <a href="mailto:shreyasbharadhwaj18@gmail.com">
              Email
              <img
                src={externalLinkIcon}
                alt="External Link"
                className="external-link-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { Xmark } from "iconoir-react";
import { isValidElement } from "react";
import _isFunction from "lodash/isFunction";
import { Typography } from "../Typography";
import { IconoirIcon } from "../IconoirIcon";
import { AlertStyled } from "./Alert.styled";

export function Alert({ color, icon, title, description, actions, onClose }) {
  const theme = useTheme();
  const { galacticGrape } = theme.mochshaColorPalette;

  return (
    <AlertStyled color={color} variant="outlined" icon={false}>
      <Stack
        direction="row"
        gap="1.2rem"
        alignItems={title ? "flex-start" : "center"}
      >
        <Stack sx={{ flexShrink: 0 }}>
          {isValidElement(icon) ? (
            icon
          ) : (
            <Stack sx={{ paddingBlock: "0.2rem" }}>
              <IconoirIcon
                className="alertIcon"
                icon={icon}
                width="2rem"
                height="2rem"
              />
            </Stack>
          )}
        </Stack>

        <Stack
          direction="row"
          gap="1.2rem"
          alignItems="center"
          justifyContent="space-between"
          flex="1 1 auto"
        >
          <Stack>
            {title && (
              <Typography variant="subHeader2" fontWeight="medium">
                {title}
              </Typography>
            )}
            <Typography
              color={galacticGrape[800]}
              variant="body"
              fontWeight="medium"
            >
              {description}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            gap="1.2rem"
            alignItems="center"
            flexShrink="0"
          >
            {actions}

            {_isFunction(onClose) && (
              <IconButton sx={{ padding: "0.6rem" }} onClick={onClose}>
                <IconoirIcon icon={Xmark} color={galacticGrape[700]} />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </AlertStyled>
  );
}

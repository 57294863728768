import { Box, Divider } from '@mui/material';

import { SubMenu } from './SubMenu';
import { MenuStyled } from './UserProfileMenu.styled';
import { MenuItem } from './MenuItem';

export function Menu({
  open,
  anchorEl,
  closeMenu,
  menuItems,
  anchorOrigin,
  transformOrigin,
  children,
  ...rest
}) {
  return (
    <MenuStyled
      open={open}
      anchorEl={anchorEl}
      onClose={closeMenu}
      sx={{
        marginTop: '0.5rem',
      }}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'right',
        }
      }
      {...rest}
    >
      {children}

      {menuItems.map((menuItem, index) => {
        if ('subMenuItems' in menuItem) {
          return <SubMenu closeMenu={closeMenu} {...menuItem} />;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key -- List is not altered
          <Box key={`${index}_container`}>
            {menuItem.divider && <Divider />}
            <MenuItem closeMenu={closeMenu} {...menuItem} />
          </Box>
        );
      })}
    </MenuStyled>
  );
}

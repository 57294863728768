import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebookF,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import axios from "axios";
import {
  Stack,
  Typography,
  CircularProgress,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import logo from "../../assets/logo/Completelogo.png";
import { Button } from "../Button";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";

const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.mochshaColorPalette.mistyMint[900],
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "2rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "2rem 2rem",
    gap: "2rem",
  },
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("md")]: {
    width: "45%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: "2rem",
  },
}));

const FormStack = styled(Stack)(({ theme }) => ({
  width: "40%",
  [theme.breakpoints.down("md")]: {
    width: "45%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const LogoImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "40rem",
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  width: "5rem",
  height: "5rem",
  borderRadius: "50%",
  border: "1px solid black",
  [theme.breakpoints.down("md")]: {
    width: "4rem",
    height: "4rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "3rem",
    height: "3rem",
  },
}));

const loginSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters"),
});

export const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { setAuthDetails } = useAuth();

  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleGoogle = async () => {
    try {
      window.location.href = "https://api.mochsha.in/auth/google";
    } catch (error) {
      console.error("Error signing in with Google:", error);
      // setError("root", { message: "An error occurred during sign in" });
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://api.mochsha.in/api/v1/users/login",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response && response.data) {
        await queryClient.invalidateQueries({
          queryKey: ["userDetails"],
        });

        setAuthDetails(response.data);

        if (response.data.userDetails) {
          navigate("/", { state: { email: data.email } });
        } else {
          navigate("/journey", { state: { email: data.email } });
        }
      } else {
        console.error("Unexpected response structure:", response);
        setError("root", { message: "An error occurred during sign in" });
        setAuthDetails(null);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      setError("root", { message: "Invalid email or password" });
      setAuthDetails(null);
    }
  };

  return (
    <PageContainer>
      <ContentStack spacing="2rem">
        <Typography variant="titlePage" fontWeight="bold" textAlign="center">
          The Digital Marketplace For Live Music
        </Typography>
        {/*<Typography variant="subHeading">
          The Digital Marketplace For Live Music
        </Typography> */}
        <Box sx={{ textAlign: "center" }}>
          <LogoImage src={logo} alt="Website Logo" />
        </Box>
      </ContentStack>
      <FormStack spacing="2rem">
        <Typography variant="bodyStrong" fontWeight="bold" align="center">
          Sign in with
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <SocialIconButton onClick={handleGoogle}>
            <FontAwesomeIcon
              icon={faGoogle}
              size={isSmallScreen ? "sm" : "lg"}
            />
          </SocialIconButton>
        </Stack>
        <Typography align="center">or</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="2rem">
            <TextFieldV2
              name="email"
              control={control}
              label="Email"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <TextFieldV2
              name="password"
              control={control}
              label="Password"
              type="password"
              fullWidth
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            {errors.root && (
              <Typography color="error">{errors.root.message}</Typography>
            )}
            <Stack
              width="100%"
              justifyContent="center"
              alignItems="center"
              paddingY="1.5rem"
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                sx={{ width: "50%" }}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Sign in"}
              </Button>
            </Stack>
          </Stack>
        </form>
        <Typography align="center">
          Don't have an account? <a href="/register">Sign up</a>
        </Typography>
      </FormStack>
    </PageContainer>
  );
};

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Container,
  Pagination,
  CircularProgress,
  Chip,
  Stack,
} from "@mui/material";

import { Typography } from "../Typography";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2/TextFieldV2";
import { AutocompleteField } from "../Forms/Fields/AutocompleteField";
import { Button } from "../Button/Button";
import { BidDialog } from "../Bidding/BidDialog";
import { useBidMutation } from "../Bidding/useBidMutation";
import { useUser } from "../../context/UserContext";

// Mock data for testing
const mockResponse = {
  meta: {
    totalResults: 5,
    resultsPerPage: 5,
    currentPage: 1,
    sortField: "created_at",
    sortOrder: "DESC",
  },
  data: [
    {
      id: "aef720e4-4cc6-4467-8f82-1ed73038ed2f",
      organizer_id: "203144ff-f61b-4474-92e0-514d13a23adb",
      title: "test",
      description: "rsdrseresrr",
      language: "english",
      genre: "pop",
      category: "vocalist",
      subcategory: "male",
      event_date: "2024-11-01T18:30:00.000Z",
      start_time: "00:00:00",
      end_time: "00:00:00",
      budget_min: "10000",
      budget_max: "454243",
      location: "bangalore",
      is_active: true,
      created_at: "2024-11-01T17:53:23.622Z",
      updated_at: "2024-11-01T17:53:23.623Z",
      event_status: "draft",
    },
  ],
};

const categoryOptions = [
  { label: "All", value: "" },
  { label: "Musician", value: "musician" },
  { label: "Band", value: "band" },
  { label: "DJ", value: "dj" },
];

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
}));

const LoadingWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "60vh",
});

const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  flexWrap: "wrap",
}));

const PaginationWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(4),
}));

const fetchEvents = async ({
  page = 1,
  per_page = 6,
  category,
  search,
  location,
}) => {
  try {
    const response = await axios.get("https://api.mochsha.in/api/v1/events", {
      params: {
        page,
        per_page,
        category,
        search,
        location,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    return mockResponse;
  }
};

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const DiscoverEvents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  const { galacticGrape } = theme.mochshaColorPalette;
  const { userDetails } = useUser();
  const [isBidDialogOpen, setIsBidDialogOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const { control, watch } = useForm({
    mode: "onBlur",
    defaultValues: {
      search: "",
      location: "",
      category: null,
    },
  });

  const { mutate } = useBidMutation({
    onSuccess: () => {
      setIsBidDialogOpen(false);
    },
    onError: ({ error, errorResponse }) => {
      console.error("Bid submission failed:", error);
    },
  });

  const handleBidNowClick = (event) => {
    setSelectedCard(event);
    setIsBidDialogOpen(true);
  };

  const handleBidSubmit = (data) => {
    mutate({
      event_id: selectedCard.id,
      provider_id: userDetails?.id,
      amount: data.amount,
      proposal: data.proposal,
    });
  };

  const search = watch("search");
  const location = watch("location");
  const category = watch("category");

  const formValues = useMemo(
    () => ({
      search,
      location,
      category,
    }),
    [search, location, category]
  );

  const debouncedFormValues = useDebounce(formValues, 500);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["events", "getAll", currentPage, debouncedFormValues],
    queryFn: () =>
      fetchEvents({
        page: currentPage,
        ...debouncedFormValues,
      }),
    retry: false,
    refetchOnWindowFocus: false,
    initialData: mockResponse,
  });

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [formValues]);

  if (isLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }

  if (isError) {
    return (
      <Typography variant="h6" color="error" textAlign="center">
        Error loading events. Please try again later.
      </Typography>
    );
  }

  const ensureArray = (value) => {
    if (!value) return [];
    if (typeof value === "string") return value.split(",");
    if (Array.isArray(value)) return value;
    return [];
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Stack gap="2rem">
        <Typography
          variant="body"
          color={galacticGrape[700]}
          fontWeight="semiBold"
        >
          Discover Events
        </Typography>

        <FilterContainer>
          <TextFieldV2
            name="search"
            control={control}
            label="Search"
            placeholder="Search events..."
            sx={{ minWidth: 200 }}
          />
        </FilterContainer>

        <Grid container spacing={3}>
          {(data?.data || []).map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {event.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {event.description}
                  </Typography>

                  <Stack spacing={2}>
                    <Box>
                      <Typography variant="subtitle2" gutterBottom>
                        Event Details:
                      </Typography>
                      <Typography variant="body2">
                        📅{" "}
                        {DateTime.fromISO(event.event_date)
                          .toLocal()
                          .toFormat("dd-MM-yyyy")}
                      </Typography>
                      <Typography variant="body2">
                        🕒
                        {DateTime.fromFormat(
                          event.start_time,
                          "H:mm:ss"
                        ).toFormat("h:mm a")}
                        -{" "}
                        {DateTime.fromFormat(
                          event.end_time,
                          "H:mm:ss"
                        ).toFormat("h:mm a")}
                      </Typography>
                      <Typography variant="body2">
                        {" "}
                        📍{event.location}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="subtitle2" gutterBottom>
                        Budget Range:
                      </Typography>
                      <Typography variant="body2">
                        ₹{event.budget_min} - ₹{event.budget_max}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="subtitle2" gutterBottom>
                        Genre:
                      </Typography>
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {ensureArray(event.genre).map((g, index) => (
                          <Chip
                            key={index}
                            label={g}
                            size="small"
                            sx={{ m: 0.5 }}
                          />
                        ))}
                      </Stack>
                    </Box>

                    <Box>
                      <Typography variant="subtitle2" gutterBottom>
                        Languages:
                      </Typography>
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {ensureArray(event.language).map((lang, index) => (
                          <Chip
                            key={index}
                            label={lang}
                            size="small"
                            variant="outlined"
                            sx={{ m: 0.5 }}
                          />
                        ))}
                      </Stack>
                    </Box>

                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button onClick={() => handleBidNowClick(event)}>
                        Bid Now
                      </Button>
                    </Box>
                  </Stack>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        {selectedCard && (
          <BidDialog
            open={isBidDialogOpen}
            onClose={() => setIsBidDialogOpen(false)}
            onSubmit={handleBidSubmit}
            budget={{
              min: selectedCard.budget_min,
              max: selectedCard.budget_max,
            }}
            initialValue={selectedCard}
          />
        )}

        <PaginationWrapper>
          <Pagination
            count={Math.ceil(
              (data?.meta?.totalResults || 0) /
                (data?.meta?.resultsPerPage || 5)
            )}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .Mui-selected": {
                backgroundColor: `${galacticGrape[100]} !important`,
                color: `${galacticGrape[800]} !important`,
              },
            }}
          />
        </PaginationWrapper>
      </Stack>
    </Container>
  );
};

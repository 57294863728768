import React from "react";
import { Box, Typography, Stack, Rating } from "@mui/material";
import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";

const ReviewContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  border: "1px solid #ccc",
  borderRadius: "8px",
  width: "50%",
  spacing: theme.spacing(4),
}));

const ReviewTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const ReviewSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ReviewQuestion = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const ReviewSection = ({ control, role }) => {
  return (
    <ReviewContainer>
      <ReviewTitle variant="bodyStrong">Review Your Experience</ReviewTitle>
      <ReviewSubtitle variant="bodyBase">
        {role === "client"
          ? "Please rate the following aspects of the performance:"
          : "Please rate the following aspects of the event:"}
      </ReviewSubtitle>
      <Stack spacing={4}>
        <Box>
          <ReviewQuestion variant="bodyBase">
            {role === "client"
              ? "Did the Musician arrive on time?"
              : "Was the client professional in their behaviour?"}
          </ReviewQuestion>
          <Controller
            name="arrivalTime"
            control={control}
            defaultValue={0} // Provide a default value
            render={({ field }) => (
              <Rating
                {...field}
                value={field.value || 0} // Ensure value is always defined
                onChange={(_, value) => field.onChange(value)}
                size="large"
                sx={{
                  "& .MuiRating-iconFilled": {
                    color: "black", // Change the color of filled stars to black
                  },
                  "& .MuiRating-iconHover": {
                    color: "black", // Change the color of hovered stars to black
                  },
                }}
              />
            )}
          />
        </Box>
        <Box>
          <ReviewQuestion variant="bodyBase">
            {role === "client"
              ? "Did the Musician meet your event requirements?"
              : "Did the client have all the necessary setup in place in time?"}
          </ReviewQuestion>
          <Controller
            name="eventRequirements"
            control={control}
            defaultValue={0} // Provide a default value
            render={({ field }) => (
              <Rating
                {...field}
                value={field.value || 0} // Ensure value is always defined
                onChange={(_, value) => field.onChange(value)}
                size="large"
                sx={{
                  "& .MuiRating-iconFilled": {
                    color: "black", // Change the color of filled stars to black
                  },
                  "& .MuiRating-iconHover": {
                    color: "black", // Change the color of hovered stars to black
                  },
                }}
              />
            )}
          />
        </Box>
      </Stack>
    </ReviewContainer>
  );
};

export default ReviewSection;

export const servicesContent = {
  title: "Our Services",
  mainDescription:
    "MOCHSHA- a digital music marketplace that connects event clients with musicians, sound engineers, light engineers, equipment vendors. Our platform streamlines collaborations, ensures transparent agreements, and simplifies payments – all tailored to the Indian music industry.",
  sections: [
    {
      id: "connect",
      title: "Connect with Professionals",
      content: "Find the perfect match for your event needs:",
      listItems: [
        {
          primary: "Musicians & Performers",
          secondary: "Solo artists, bands, and orchestras",
        },
        {
          primary: "Sound Engineers",
          secondary: "Expert audio technicians for perfect sound",
        },
        {
          primary: "Light Engineers",
          secondary: "Professional lighting specialists",
        },
        {
          primary: "Media Services",
          secondary: "Photography and Videography",
        },
        {
          primary: "Equipment Rentals",
          secondary: "Rentals for sound, lighting, and media equipment",
        },
      ],
    },
    {
      id: "platform-features",
      title: "Platform Features",
      content: "Experience seamless event planning with our features:",
      listItems: [
        {
          primary: "Easy Booking",
          secondary: "Simple process to connect and book professionals",
        },
        {
          primary: "Secure Payments",
          secondary: "Safe and transparent payment system",
        },
        {
          primary: "Indian Market Focus",
          secondary: "Services tailored to Indian music industry needs",
        },
      ],
    },
    {
      id: "how-it-works",
      title: "How It Works",
      content: "Three simple steps to organize your event:",
      listItems: [
        {
          primary: "1. Search & Connect",
          secondary: "Browse through verified professionals and vendors",
        },
        {
          primary: "2. Book & Agree",
          secondary: "Finalize details and sign digital agreements",
        },
        {
          primary: "3. Execute & Pay",
          secondary: "Complete the event and process secure payments",
        },
      ],
    },
  ],
};

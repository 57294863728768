import React from "react";
import { Typography } from "../Typography";
import { Stack, Box } from "@mui/material";
import { DatePickerFieldV2 } from "../Forms/Fields/DatePickerFieldV2";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import StandaloneSearch from "../../pages/Demo/StandaloneSearch";

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "2rem",
  },
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const FormBox = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const TimePickerStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "2rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
}));

const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    width: "100%",
    textAlign: "left",
    transformOrigin: "left",
    alignItems: "center",
    "&.MuiInputLabel-shrink": {
      textAlign: "left",
      transformOrigin: "left",
    },
  },
}));

export const EventDetails = ({ control, setValue }) => {
  return (
    <ResponsiveStack>
      <ContentStack gap="2rem">
        <Typography variant="titlePage" fontWeight="bold">
          Set the Scene!
        </Typography>
        <Typography variant="subHeading">Let's get specific.</Typography>
      </ContentStack>

      <FormBox>
        <Stack spacing="4rem">
          <StandaloneSearch
            control={control}
            name="location"
            onSearchFinish={(address) => setValue("location", address)}
            label="Search location"
            placeholder="Search location"
            required
          />

          <DatePickerFieldV2
            control={control}
            label="Select your event date(s)"
            name="event_date"
            labelContext="Choose the main date and add additional dates if needed."
            required
          />

          <Stack spacing="2rem">
            <label>Set Your Event Time & Duration</label>
            <TimePickerStack>
              <Controller
                control={control}
                name="start_time"
                render={({ field }) => (
                  <StyledTimePicker
                    label="Start Time"
                    value={field.value}
                    onChange={field.onChange}
                    slotProps={{ textField: { fullWidth: true } }}
                    required
                  />
                )}
              />
              <Typography>To</Typography>
              <Controller
                control={control}
                name="end_time"
                render={({ field }) => (
                  <StyledTimePicker
                    label="End Time"
                    value={field.value}
                    onChange={field.onChange}
                    slotProps={{ textField: { fullWidth: true } }}
                    required
                  />
                )}
              />
            </TimePickerStack>
          </Stack>
        </Stack>
      </FormBox>
    </ResponsiveStack>
  );
};

import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Avatar, Paper, Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

// Styled Components
const ReviewContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
}));

const ReviewHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ReviewerInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

const ReviewText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.text.secondary,
  lineHeight: 1.5,
}));

const ReviewsSection = ({ reviews }) => {
  return (
    <Box>
      {reviews.map((review) => (
        <ReviewContainer key={review.id} elevation={2}>
          {/* Header with Rating and Date */}
          <ReviewHeader>
            <Rating
              value={review.rating}
              readOnly
              precision={0.5}
              icon={<StarIcon fontSize="inherit" color="warning" />}
            />
            <Typography variant="body2" color="textSecondary">
              {new Date(review.date).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </Typography>
          </ReviewHeader>

          {/* Review Text */}
          <ReviewText>{review.comment}</ReviewText>

          {/* Reviewer Information */}
          <ReviewerInfo>
            <Avatar
              alt={review.author}
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                review.author
              )}&background=random`}
            />
            <Box>
              <Typography variant="subtitle1">{review.author}</Typography>
              <Typography variant="body2" color="textSecondary">
                Reviewer
              </Typography>
            </Box>
          </ReviewerInfo>
        </ReviewContainer>
      ))}
    </Box>
  );
};

export default ReviewsSection;

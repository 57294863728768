import React, { useState } from "react";
import { Button } from "../Button";
import { Stepper, Step, StepLabel, Stack } from "@mui/material";
import { Typography } from "../Typography";
import { BottomSheetDialog } from "../BottomSheetDialog";
import { useStepper } from "../../hooks/useStepper";
import { EventDetails } from "./EventDetails";
import { Budget } from "./Budget";
import { Review } from "./Review";
import { About } from "./About";
import { isDisabled } from "./utils";
import { useMutation } from "@tanstack/react-query";
import { rawAxios } from "../../axios";
import { toasts } from "../Toast/toasts";
import { useForm } from "react-hook-form";
import { useUser } from "../../context/UserContext";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

export const ClientOnBoardingDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  onSuccess,
}) => {
  const [formValues, setFormValues] = useState({});

  const { userDetails } = useUser();

  const navigate = useNavigate();

  const aboutResolver = async (values) => {
    const errors = {};
    const requiredFields = [
      "subcategory",
      "category",
      "description",
      "title",
      "language",
      "genre",
    ];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: Object.keys(errors).length > 0 ? errors : {},
    };
  };

  const {
    control: aboutControl,
    watch: aboutWatch,
    setValue: aboutSetValue,
    handleSubmit: aboutHandleSubmit,
    formState: { errors: aboutErrors },
    reset: aboutReset,
  } = useForm({
    mode: "onBlur",
    resolver: aboutResolver,
  });

  const eventDetailsResolver = async (values) => {
    const errors = {};
    const requiredFields = [
      "location",
      // "event_date", "event_time"
    ];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: errors,
    };
  };

  const {
    control: eventDetailsControl,
    watch: eventDetailsWatch,
    setValue: eventDetailsSetValue,
    formState: { errors: eventDetailsErrors },
    handleSubmit: eventDetailsHandleSubmit,
    reset: eventDetailsReset,
  } = useForm({
    mode: "onBlur",
    resolver: eventDetailsResolver,
  });

  const budgetResolver = async (values) => {
    const errors = {};
    const requiredFields = ["budget_range"];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: errors,
    };
  };

  const {
    control: budgetControl,
    watch: budgetWatch,
    setValue: budgetSetValue,
    formState: { errors: budgetErrors },
    handleSubmit: budgetHandleSubmit,
    reset: budgetReset,
  } = useForm({
    mode: "onBlur",
    resolver: budgetResolver,
    defaultValues: {
      budget_range: [1000, 5000],
    },
  });

  const step1 = (
    <About
      control={aboutControl}
      errors={aboutErrors}
      watch={aboutWatch}
      setValue={aboutSetValue}
    />
  );
  const step2 = (
    <>
      <EventDetails
        control={eventDetailsControl}
        errors={eventDetailsErrors}
        watch={eventDetailsWatch}
        setValue={eventDetailsSetValue}
      />
    </>
  );
  const step3 = (
    <Budget
      control={budgetControl}
      errors={budgetErrors}
      watch={budgetWatch}
      setValue={budgetSetValue}
    />
  );

  const step4 = <Review formValues={formValues} />;

  const stepperState = {
    stepArray: [step1, step2, step3, step4],
    horizontalStepperList: ["About", "Event Details", "Budget", "Review"],
  };

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } =
    useStepper(stepperState.stepArray);

  const handleStepSubmit = (stepIndex) => {
    const submitHandlers = {
      0: aboutHandleSubmit,
      1: eventDetailsHandleSubmit,
      2: budgetHandleSubmit,
    };

    const submitHandler = submitHandlers[stepIndex];
    if (submitHandler) {
      submitHandler((formValues) => {
        console.log(formValues);
        setFormValues((prev) => ({
          ...prev,
          ...formValues,
        }));
      })();
    }
  };

  const { mutate } = useMutation({
    mutationFn: () =>
      rawAxios.post("/api/v1/events", {
        ...formValues,
        start_time: DateTime.fromISO(formValues.start_time).toLocaleString(
          DateTime.TIME_SIMPLE
        ),
        end_time: DateTime.fromISO(formValues.end_time).toLocaleString(
          DateTime.TIME_SIMPLE
        ),
        organizer_id: userDetails?.id,
        event_status: "active",
        budget_min: formValues?.budget_range?.[0],
        budget_max: formValues?.budget_range?.[1],
      }),
    onSuccess: async () => {
      toasts.success({
        title: "Success",
        description: "Event Requirement Submitted Successfully",
      });
      onSuccess();
      goTo(0);
      setIsDialogOpen(false);
      setFormValues({});
      eventDetailsReset();
      budgetReset();
      aboutReset();
      navigate("/");
    },
    onError: (error) => {
      if (error.response) {
        console.log("Error Response:", error.response.data);
      } else {
        console.log("Error Message:", error.message);
      }
      toasts.error({
        title: "Something went wrong",
        description: "Please try again",
      });
    },
  });

  return (
    <Stack>
      <BottomSheetDialog
        title={
          <Typography variant="heading" fontWeight="bold">
            Event Job Posting
          </Typography>
        }
        open={isDialogOpen}
        onClose={() => {
          goTo(0);
          setIsDialogOpen(false);
        }}
        actions={
          <Stack
            direction="row"
            justifyContent={isFirstStep ? "flex-end" : "space-between"}
            width="100%"
          >
            {!isFirstStep && (
              <Button variant="secondary" autoFocus onClick={back}>
                Back
              </Button>
            )}
            <Button
              autoFocus
              disabled={isDisabled(
                currentStepIndex,
                aboutWatch,
                aboutErrors,
                eventDetailsWatch,
                eventDetailsErrors,
                budgetWatch,
                budgetErrors
              )}
              onClick={() => {
                handleStepSubmit(currentStepIndex);
                next();

                if (isLastStep) {
                  mutate();
                }
              }}
            >
              {isLastStep ? "Submit" : "Next"}
            </Button>
          </Stack>
        }
      >
        <Stack spacing="3rem">
          <Stepper activeStep={currentStepIndex}>
            {stepperState.horizontalStepperList.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {step}
        </Stack>
      </BottomSheetDialog>
    </Stack>
  );
};

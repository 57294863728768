import { Link, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OpenInWindow } from 'iconoir-react';

import { IconoirIcon } from '../IconoirIcon';
import { Typography } from '../Typography';
import { HoverBox } from './Toaster.styled';

export function ToastFooterLink({ href, linkText }) {
  const { mochshaColorPalette } = useTheme();

  return (
    <Link
      href={href}
      underline="none"
      target={href?.startsWith('https') ? '_blank' : '_self'}
      color={mochshaColorPalette.cosmicTangerine[800]}
    >
      <HoverBox>
        <Stack direction="row" gap="0.8rem">
          <Typography fontWeight="medium" variant="body">
            {linkText || href}
          </Typography>

          <IconoirIcon icon={OpenInWindow} width="2rem" height="2rem" />
        </Stack>
      </HoverBox>
    </Link>
  );
}

import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const AppBarLogoStyled = styled(Stack)(({ theme }) => {
  return {
    alignItems: "center",
    flexFlow: "row",
    cursor: "pointer",
    gap: "1.4rem",

    "& .MuiTypography-root": {
      color: theme.palette.primary.contrastText,
    },
  };
});

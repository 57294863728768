import { Box, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CheckCircle, NavArrowRight } from "iconoir-react";
import { useTheme } from "@mui/material/styles";
import _isFunction from "lodash/isFunction";
import { useForm } from "react-hook-form";

import { IconoirIcon } from "../IconoirIcon";
import { Typography } from "../Typography";
// import { SearchTextField } from "../Forms/Fields";
import { MenuItemStyled, MenuStyled } from "../Menu/Menu.styled";
import { MenuItem } from "../Menu/MenuItem";

export function SubMenu({
  label,
  icon,
  MenuProps,
  subMenuItems,
  searchInputPlaceholder,
  closeMenu,
  selectedValueLabel,
  ...rest
}) {
  const theme = useTheme();
  const { control, watch, setValue, reset } = useForm();

  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);

  const menuItemRef = useRef(null);

  useEffect(() => {
    if (!open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- need only open
  }, [open]);

  const filteredSubMenuItems = useMemo(
    () => {
      const searchValue = watch("search");

      if (searchValue && subMenuItems) {
        return subMenuItems.filter((subMenuItem) =>
          subMenuItem.label
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        );
      }

      return subMenuItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- need to watch search field
    [subMenuItems, watch("search")]
  );

  return (
    <MenuItemStyled
      ref={menuItemRef}
      autoFocus={false}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => {
        if (_isFunction(closeMenu)) {
          closeMenu();
        }
      }}
      sx={[
        open && {
          backgroundColor: theme.palette.primary.light,
          ".MuiTypography-root": {
            fontWeight: 500,
          },
        },
      ]}
      {...rest}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Stack gap="1.2rem" alignItems="center" flexDirection="row">
          {icon}

          {selectedValueLabel ? (
            <Stack gap="0.2rem">
              <Typography variant="bodySmall">
                {label}
              </Typography>

              <Typography variant="bodyTiny">
                {selectedValueLabel}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="bodySmall">{label}</Typography>
          )}
        </Stack>

        <IconoirIcon
          color={theme.mochshaColorPalette.galacticGrape[700]}
          width="2rem"
          height="2rem"
          icon={NavArrowRight}
        />
      </Stack>

      <MenuStyled
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }}
        disableRestoreFocus
        MenuListProps={{
          ...MenuProps?.MenuListProps,
        }}
        sx={{
          marginLeft: "-1rem",
          pointerEvents: "none",
          "& .MuiList-root": {
            pointerEvents: "auto",
          },
          "& .MuiPaper-root": {
            maxHeight: "32.5rem",
          },
        }}
        anchorEl={menuItemRef.current}
        open={open}
        onClose={close}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onMouseEnter={(event) => {
          event.stopPropagation();
        }}
        onMouseLeave={close}
      >
        {/* {subMenuItems?.length > 4 && (
          <MenuItem
            key="search_container"
            sx={{
              backgroundColor: theme.palette.common.white,
            }}
            autoFocus={false}
            onClick={(event) => {
              event.stopPropagation();
            }}
            disableTouchRipple
            disableRipple
            focusRipple={false}
          >
            <SearchTextField
              size="small"
              name="search"
              placeholder={searchInputPlaceholder || "placeholder"}
              control={control}
              setValue={setValue}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </MenuItem>
        )} */}
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "26.5rem",
          }}
        >
          {filteredSubMenuItems &&
            filteredSubMenuItems.map((menuItem, index) => {
              return (
                <MenuItem
                  closeMenu={closeMenu}
                  {...menuItem}
                  // eslint-disable-next-line react/no-array-index-key -- List is not altered
                  key={`${index}_container`}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "1.2rem",
                  }}
                >
                  {menuItem.selected && (
                    <IconoirIcon
                      // eslint-disable-next-line react/no-array-index-key -- List is not altered
                      key={`${index}_container`}
                      color={theme.mochshaColorPalette.galacticGrape[700]}
                      width="1.6rem"
                      height="1.6rem"
                      icon={CheckCircle}
                    />
                  )}
                </MenuItem>
              );
            })}
        </Box>
      </MenuStyled>
    </MenuItemStyled>
  );
}

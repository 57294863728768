export const termsContent = {
  title: "Terms of Use",
  lastUpdated: "November 2024",
  version: "1",
  intro:
    'Welcome to Mochsha\'s Terms of Use ("Terms"). Mochsha Ventures LLP ("Mochsha", "we", "us", "our") provides a web-based solution that connects the users of the Platform seeking specific services ("you", "User", "Customer") with musicians, sound engineers, equipment vendors, media service (such as photography and videography) ("Musicians"). By accessing our website or mobile application ("Platform") or by availing the Services offered through the Platform ("Services"), you represent and warrant that you have full legal capacity and authority to agree to and bind yourself to these Terms.',

  sections: [
    {
      id: "services",
      title: "1. SERVICES",
      content: [
        '(a) Platform and Payment Facilitation: The Services include access to our Platform, which allows you to schedule appointments with Musicians. We act as an intermediary to collect payments from you on behalf of the Musicians and transfer the payment to them for the services they provide ("Musician Services").',
        "(b) Musician Service: Please note that we do not offer Musicians Services ourselves, and we are not responsible for Musician quality, availability, conduct, suitability, absence or any of their acts or omissions. Musicians are solely responsible for their acts, omissions and Musician Services they offer. Musicians are and neither our employees, affiliate, agents, contractors, or partners and Musicians have no authority to bind or represent us in any manner.",
        "(c) Geographic Limitation: Please note that the Platform is intended for use only within the Republic of India.",
        "(d) Communications: We may send you text messages, emails, or WhatsApp notifications related to your bookings, your use of the Services, or promotional updates. While you can opt-out of these communications by contacting us at support@mochsha.in or adjusting your Platform settings, please note that opting out may limit our ability to deliver the Services effectively.",
      ],
    },
    {
      id: "account-creation",
      title: "2. ACCOUNT CREATION",
      content: [
        '(a) To access the Services on the Platform, you must create an account ("Account"), by providing certain details, such as your name, email, phone number, etc. Please note you must be at least 18 years old to create an Account. You can alternatively create an account by using your Google Account.',
        "(b) You agree that you will provide accurate, current, and true information for your Account and also agree to promptly update any changes to your details on the Platform to keep your information accurate and up to date.",
        "(c) You are responsible for maintaining the security and confidentiality of your Account. You must notify us immediately if you suspect any unauthorized access or security breaches involving your Account. You are fully liable for all activities that occur under your Account, even if those activities are performed by someone other than you. We are not responsible for any unauthorized access or actions involving your Account.",
        "(d) By creating an Account, you agree to receive communications from us regarding payment requests, updates on the Services, promotional offers from us and our third-party partners, and other matters related to the Services.",
      ],
    },
    {
      id: "user-content",
      title: "3. USER CONTENT",
      content: [
        '(a) The Platform may include interactive features that allow you to post, upload, publish, display, transmit, or submit content such as comments, reviews, suggestions, feedback, ideas, etc. ("Content").',
        "(b) For quality control, we encourage reviews from both users and Musicians. You agree not to post false, misleading, or inaccurate reviews about Musicians. We may use your reviews to evaluate whether users and Musicians are appropriate for the Platform. We reserve the right to suspend or terminate your registration if, in our sole discretion, we determine you are an inappropriate user. The review you provide will be public information and will be visible to other users of the Platform.",
        "(c) By submitting Content, you grant us a non-exclusive, worldwide, transferable, sublicensable, royalty-free license to use, publish, display, store, modify, adapt, and distribute your Content in connection with the Services. This includes using your Content for advertising, promotional purposes, and legal requirements, such as evidence in court if necessary. You waive any moral rights or similar rights you may have in your Content, to the extent permissible under applicable law.",
        "(d) We reserve the right to remove any Content that we deem, in our sole discretion, if your Content violate these Terms or otherwise is inappropriate.",
      ],
    },
    {
      id: "consent-to-use-data",
      title: "4. CONSENT TO USE DATA",
      content: [
        "You agree that we may, in accordance with our Privacy Policy, collect and use your personal data. In addition to the consent provided in the Privacy Policy, you consent to us sharing your data with our affiliates or third-party service providers. We may use your data to improve our Services, analyse usage trends, and generate statistics.",
      ],
    },
    {
      id: "bookings",
      title: "5. BOOKINGS",
      content: [
        "(a) Placing Orders: The Platform allows you to request Musician Services. To make a booking, follow the instructions on the Platform and provide the necessary details. We will make reasonable efforts to connect you with a Musician available as per details you have provided to us. We are in no manner responsible if no Musician is willing to connect with you or render you the Musician Services.",
        "(b) Booking Confirmation: Once you place a request we will provide confirmation of the booking via SMS, email or a push notification. Upon confirmation, you will be required to make the payment in accordance with these Terms or as indicated on the Platform.",
      ],
    },
    {
      id: "refunds",
      title: "6. CANCELLATIONS AND REFUNDS",
      content: [
        "(a) General Principle: Refunds are rare and typically not offered unless there are exceptional circumstances, such as a breach of the agreed-upon terms by a service provider.",
        "(b) Refund Eligibility: Refund requests will be reviewed individually under the following circumstances:",
        "    (i) Service Provider Non-Performance: If the service provider fails to show up or deliver the service, the case will be reviewed, and a refund or alternative resolution may be considered.",
        "    (ii) Disputes: Disputes about the quality or scope of services delivered will be resolved through Mochsha's dispute resolution process. Refunds, if any, will be determined as part of this process.",
        "(c) Non-Refundable Cases:",
        "    (i) Client Cancellations: Refunds are generally not provided for cancellations initiated by clients, regardless of the timing.",
        "    (ii) Force Majeure: Events disrupted due to unforeseen circumstances (e.g., natural disasters, emergencies) are not eligible for refunds.",
        "    (iii) Platform Fees: Mochsha's platform fees, if charged, are always non-refundable.",
        "    (iv) Third-Party Services: Refunds for services booked through third-party vendors depend on their individual policies.",
        "(d) Processing Time: For approved refunds, the processing time is 5-7 business days, excluding any deductions for TDS, GST, or other fees.",
        "(e) Cancellation by Musician:",
        "    (i) If Musician cancels the Performance, then we will provide either full refund of the total advance amount (excluding the platform fee) or will arrange an alternative Musician for the schedule Performance.",
        "    (ii) Refunds, if applicable, will be processed using the same payment method used by the User at the time of booking.",
      ],
    },
    {
      id: "pricing-fees-payment",
      title: "7. PRICING, FEES, AND PAYMENT TERMS",
      content: [
        "(a) We reserve the right to charge you for the Musician Services you may avail, from time to time, on or through the Platform.",
        "(b) Charges and Fees:",
        '    (i) Musician Charges: You are required to pay the Musician the amount specified at the time of the booking, along with any additional charges for extra services or out of pocket expenses incurred by the Musicians ("Charges")',
        '    (ii) Convenience Fee / Platform Fee: in addition to the Charges, we may charge you a convenience fee for facilitating the booking and processing payments to the Musician ("Fees").',
        "    (iii) Payment Methods: Payments will be made in three installments - Advance Payment, Performance Day Payment, and Final Payment.",
        "    (iv) We have the right to modify and otherwise restrict the modes of payment available to you.",
        "    (v) The Charges and Fees may be payable at the time of making a booking, or upon the completion of the Musician Service.",
        "    (vi) All Charges and Fees are exclusive of applicable taxes.",
        '(c) Payment Processors: We may use a third-party payment processor ("Payment Processor") to bill you through your selected mode of payment.',
        '(d) Tipping is "voluntary". You understand and agree that while you are free to provide additional payment as a tip to the Musician who provides you with Musician Services, you are under no obligation to do so.',
      ],
    },
    {
      id: "your-conduct",
      title: "8. YOUR CONDUCT",
      content: [
        "(a) Zero Tolerance for Discrimination: We uphold a strict zero tolerance policy regarding discrimination against Musicians on the basis of race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age, linguistic affiliation or any other characteristic protected under applicable law.",
        "(b) Respect and Safety: We expect you to treat Musicians with courtesy and respect, ensuring a safe, clean, and appropriate environment for the performance of Musician Services.",
        "(c) Refusal of Musician Service: Musicians are entitled to refuse to perform if the location provided is not safe, clean, or appropriate, or if you behave in a discourteous, disrespectful, abusive, discriminatory or otherwise inappropriate manner.",
        "(d) Disclosure of Relevant Information: You agree that you will disclose any and all information that may have a bearing on the ability of the Musician to perform the Musician Services or impact the Musician's health, safety, or well-being.",
        "(e) Misconduct: If a Musician behaves in a discourteous, disrespectful, abusive, or unlawful manner, or violates the terms of the law, you are required to report the incident to us.",
      ],
    },
    {
      id: "third-party-services",
      title: "9. THIRD PARTY SERVICES",
      content: [
        'The Platform may contain services, content, documents, and information owned by or licensed to third parties ("Third Party Services") and may include links to such services. You acknowledge that Third Party Services are solely the responsibility of the third parties that create or provide them. Your use of these services is at your own risk, and we make no warranties or representations regarding their accuracy, completeness, or reliability. Any use of Third Party Services is governed by the terms, conditions, and privacy policies of the respective third parties. All intellectual property rights in Third Party Services belong to the respective third parties.',
      ],
    },
    {
      id: "obligations-responsibilities",
      title: "10. OBLIGATIONS AND RESPONSIBILITIES",
      content: [
        "(a) You represent and warrant that all information provided to us is accurate, truthful, and complete at the time of agreeing to these Terms and will remain so during your use of the Services and/or Musician Services.",
        "(b) You represent and warrant that, we assume no responsibility or liability for any loss or damage you may suffer if any information, documentation, material, or data provided by you to access the Services and/or Musician's Services is inaccurate, incomplete, or misleading, or if you fail to disclose any material fact.",
        "(c) You agree to fully cooperate with us in defending any legal proceedings that may arise due to your breach of obligations under these Terms.",
        "(d) Regarding the Content, you represent and warrant that: (i) you own all intellectual property rights to provide such content; (ii) you are solely responsible for all activities occurring on your Account; (iii) the Content does not violate any obligations; (iv) the Content does not infringe any intellectual property right or privacy; (v) the Content does not contain harmful files; and (vi) the Content meets all specified requirements.",
        "(e) You agree not to: (i) transmit harmful material; (ii) use automated devices to monitor the Platform; (iii) engage in systematic retrieval of content; (iv) use the Platform unlawfully; (v) decompile or reverse engineer the Platform; (vi) violate applicable laws; (vii) interfere with the Platform; (viii) gain unauthorized access; or (ix) solicit Musicians outside the Platform.",
      ],
    },
    {
      id: "intellectual-property",
      title: "11. INTELLECTUAL PROPERTY RIGHTS",
      content: [
        "(a) All rights, titles, and interest in, and to the Platform, including all related intellectual property rights, are owned by or otherwise licensed to us. Subject to your compliance with these Terms, we grant you a non-exclusive, non-transferable, non-sub licensable, revocable, and limited licence to use the Platform as per these Terms and our written instructions issued from time to time.",
        '(b) We may from time to time, ask you to provide suggestions and feedbacks, including bug reports, relating to the Platform ("Feedback"). We may freely use, copy, disclose, publish, display, distribute, and exploit the Feedback provided by you without any payment, royalty, acknowledgement, prior consent, or any other restriction arising out of your intellectual property rights in the Feedback.',
      ],
    },
    {
      id: "licensing-compliance",
      title: "12. LICENSING AND COMPLIANCE FOR MUSIC PERFORMANCE",
      content: [
        "(a) Responsibility for Licensing: The Customer acknowledges that it is solely responsible for obtaining all necessary licenses, permissions, and approvals required by law to play, perform, or broadcast music in its premises.",
        "(b) Compliance with Legal Requirements: The Customer agrees to comply with all relevant laws, regulations, and requirements for music licensing, as set forth by copyright and intellectual property law.",
        "(c) Platform Disclaimer: Our Platform solely facilitates the connection between Customers and Musicians and does not assume responsibility for securing or verifying your compliance with licensing requirements.",
        "(d) Right to Terminate: If we become aware of any breach of this licensing obligation by you, we reserve the right to immediately suspend or terminate your access to the Platform.",
      ],
    },
    {
      id: "term-termination",
      title: "13. TERM AND TERMINATION",
      content: [
        "(a) These Terms shall remain in effect unless terminated in accordance with the terms outlined herein.",
        "(b) We reserve the right to suspend or terminate your access to the Platform or your account for any reason, including but not limited to: (i) Violation of these Terms; (ii) Engaging in fraudulent behavior; (iii) Providing inaccurate information; (iv) Repeated negative feedback; (v) with 30 day notice; or (vi) Immediately, for any legitimate reason.",
        "(c) If your account is suspended or terminated, you will receive written notice of the action.",
        "(d) You may terminate your Account, at any time, for any reason by sending a notice to us.",
        "(e) Upon termination of your Account or these Terms: (i) your Account will expire; and (ii) these terms will terminate, except for those clauses that are expressly intended to survive termination.",
      ],
    },
    {
      id: "disclaimers-warranties",
      title: "14. DISCLAIMERS AND WARRANTIES",
      content: [
        "(a) The Platform acts solely as a marketplace intermediary connecting you with the Musicians. We do not endorse, guarantee, or take responsibility for any direct engagements.",
        '(b) The Services are provided "as is" without any warranty of any kind.',
        "(c) You acknowledge that we will in no event be held liable for any obligations that have not been explicitly stated in these Terms.",
        "(d) You agree and acknowledge that seeking or receiving services from any Musician independently is solely at your own risk.",
        "(e) We make no guarantee or warranty regarding the reliability, quality, or suitability of the Musicians.",
        "(f) You hereby accept full responsibility for any consequences that may arise from your use of the Platform.",
        "(g) To the fullest extent permissible by law, we, our affiliates, and our related parties, each disclaim all liability for any loss or damage.",
        "(h) In no event shall we be liable for any direct, special, indirect, incidental, consequential punitive, or exemplary damages.",
        "(i) To the maximum extent permitted by law, our liability is limited to the amount of fee we receive from you in respect of a particular booking.",
        "(j) By using the Platform, you acknowledge and accept the inherent risks involved in providing services to you by the Musician.",
      ],
    },
    {
      id: "reviews",
      title: "15. REVIEWS",
      content: [
        "If you believe that a review is inaccurate or violates the guidelines set forth, you have the right to dispute it. To initiate a dispute, you must submit a formal request through the Platform's designated feedback form, providing details about the review in question and your reasoning for the dispute. Platform will investigate the claim and may reach out for additional information. The final decision regarding the review's status will be at the sole discretion of Platform.",
      ],
    },
    {
      id: "indemnity",
      title: "16. INDEMNITY",
      content: [
        "You shall indemnify, defend at our option, and hold us, our affiliates, and our officers, employees, directors, agents, and representatives, harmless from and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages, and costs (including, without limitation, all damages, liabilities, settlements, and attorneys' fees), due to or arising out of or related to your failure to obtain necessary licenses or permission to lay or perform music in its premises, your access to the Platform, use of the Services, Musician Service, violation of these Terms, or any violation of these Terms by any third party who may use your Account.",
      ],
    },
    {
      id: "jurisdiction",
      title: "17. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION",
      content: [
        "(a) If you wish to raise a dispute, claim or conflict, which arises out of or in connection with these Terms, you may make a compliant or raise a dispute, through contacting us. In the event the dispute or complaint remains unresolved, the Parties shall first attempt to resolve the dispute amicable through discussions in good faith.",
        "(b) These Terms shall be governed by, construed and enforced in accordance with the laws of Republic of India and the courts at Bengaluru, Karnataka shall have exclusive jurisdiction.",
        "(c) In the event of any dispute arising out of or related to these Terms or the use of the Platform, the parties agree to first attempt to resolve the dispute informally through good faith negotiations.",
      ],
    },
    {
      id: "grievance-redressal",
      title: "18. GRIEVANCE REDRESSAL",
      content: [
        "You may contact our designated Grievance Redressal Officer with any complaints or queries relating to the Services or these Terms.",
      ],
    },
    {
      id: "miscellaneous",
      title: "19. MISCELLANEOUS",
      content: [
        "(a) Changes to Terms: The Terms are subject to revisions at any time, as determined by us, and all changes are effective immediately upon being posted on the Platform.",
        "(b) Modification to the Services: We reserve the right at any time to add, modify, or discontinue, temporarily or permanently, the Services (or any part thereof), with or without cause.",
        "(c) Severability: If any provision of these Terms is determined to be unlawful or unenforceable, the other provisions will continue in effect.",
        "(d) Assignment: You shall not license, sell, transfer, or assign your rights, obligations, or covenants under these Terms without our prior written consent.",
        "(e) Notices: All notices, requests, demands, and determinations for us under these Terms shall be sent to us.",
        "(f) Third Party Rights: No third party shall have any rights to enforce any terms contained herein.",
        "(g) Force Majeure: We shall have no liability to you if we are prevented from or delayed in performing our obligations due to circumstances beyond our reasonable control.",
      ],
    },
  ],
};
import React from "react";
import { Stack } from "@mui/material";
import { DiscoverProfile } from "../../components/discover/DiscoverProfile";
import { DiscoverEvents } from "../../components/discover/DiscoverEvents";
import { useUser } from "../../context/UserContext";

function Discover() {
  const { userDetails } = useUser() || {};
  const isClient = userDetails?.profile?.user_role === "client";

  return <Stack>{isClient ? <DiscoverProfile /> : <DiscoverEvents />}</Stack>;
}

export default Discover;

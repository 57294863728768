import { useController } from "react-hook-form";
import { AutocompleteInput } from "../../Inputs/AutocompleteInput";

export function AutocompleteField({
  name,
  control,
  label,
  placeholder,
  size,
  helperText,
  ...rest
}) {
  const { field } = useController({ name, control });

  return (
    <AutocompleteInput
      helperText={helperText}
      label={label}
      size={size}
      placeholder={placeholder ?? ""}
      {...rest}
      {...field}
    />
  );
}

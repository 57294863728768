import { Stack, Box, styled } from "@mui/material";

export const ResponsiveStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "1rem",
  },
}));

export const MainContentStack = styled(Stack)(({ theme }) => ({
  width: "70%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const SideContentStack = styled(Stack)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ContentStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "3rem",
  },
}));

export const FormBox = styled(Box)(({ theme }) => ({
  width: "100%",
}));

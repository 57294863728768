import React, { useState } from "react";
import { Stack, Stepper, Step, StepLabel } from "@mui/material";
import { Button } from "../../components/Button";
import { Typography } from "../../components/Typography";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SuccessDialog } from "../../components/SuccessDialog";
import { BottomSheetDialog } from "../../components/BottomSheetDialog";
import { useStepper } from "../../hooks/useStepper";
import { Header } from "../../components/Bidding/Header";
import { JobDetails } from "../../components/Bidding/JobDetails";
import { BidSectionCard } from "../../components/Bidding/BidSectionCard";
import { VenueOwnerInfo } from "../../components/Bidding/VenueOwnerInfo";
import { BidForm } from "../../components/Bidding/BidForm";
import {
  ResponsiveStack,
  MainContentStack,
  SideContentStack,
  FormBox,
  ContentStack,
} from "./Bidding.styled";
import { useMutation } from "@tanstack/react-query";
import { rawAxios } from "../../axios";
import { useUser } from "../../context/UserContext";

export const mockData = {
  header: {
    avatar: "/path-to-avatar-image.jpg",
    name: "Hashir33",
    rating: 3.6,
    role: "Venue Owner",
  },
  jobDetails: {
    title: "Need Vocalist for Corporate Party",
    location: "Gilly's, Koramangala, Bangalore, KA",
    date: "June 24th, June 25th",
    description:
      "We are seeking a talented vocalist to perform at our upcoming corporate party. The event will be held at abc hall on 06/05/24 and 07/05/24, and we expect an audience of approximately 250. The ideal candidate will have experience performing at corporate or private events, with a repertoire that suits a diverse audience.",
    categories: ["Live Music", "Vocalist"],
    budget: {
      min: 5000,
      max: 10000,
    },
  },
  bidSection: {
    budget: {
      min: 5000,
      max: 10000,
    },
    venue: "Gilly's, Koramangala, Bangalore, KA",
    date: "June 24th, June 25th",
    time: "09:30 AM - 05:00 PM",
  },
  venueOwner: {
    avatar: "/path-to-avatar-image.jpg",
    name: "Hashir33",
    rating: 3.6,
    role: "Venue Owner",
    location: "Bangalore, KA",
    portfolioLink: "https://www.google.com",
    aboutMe:
      "Skilled acoustic guitarist with 4 years of experience. Specializing in blues. Available for live performances, recording sessions, and collaborations.",
  },
};

export default function BiddingPage() {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const { userDetails } = useUser();
  const [open, setOpen] = useState(false);
  const [bidConfirmation, setBidConfirmation] = useState(false);
  const [formValues, setFormValues] = useState({});

  const bidFormResolver = async (values) => {
    const errors = {};
    if (!values.amount || values.amount < 5000 || values.amount > 10000) {
      errors.amount = {
        type: "manual",
        message: "Bid amount must be between ₹5,000 and ₹10,000",
      };
    }
    if (!values.proposal || values.proposal.trim().length === 0) {
      errors.description = {
        type: "manual",
        message: "Description is required",
      };
    }
    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: errors,
    };
  };

  const {
    control,
    formState: { errors, isValid },
    trigger,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: bidFormResolver,
    defaultValues: {
      amount: 7500,
      proposal: "",
      event_id: "153803e0-4022-437d-8366-610c44854c43",
      provider_id: userDetails.id,
    },
  });

  const step1 = (
    <ResponsiveStack spacing={4}>
      <ContentStack>
        <MainContentStack border="1px solid #E0E0E0" borderRadius="1px">
          <Header {...mockData.header} />
          <JobDetails {...mockData.jobDetails} />
        </MainContentStack>
        <SideContentStack
          spacing="1rem"
          border="1px solid #E0E0E0"
          borderRadius="1px"
        >
          <BidSectionCard {...mockData.bidSection} onBidNow={() => next()} />
        </SideContentStack>
      </ContentStack>
      {!bidConfirmation && <VenueOwnerInfo {...mockData.venueOwner} />}
    </ResponsiveStack>
  );

  const step2 = (
    <Stack spacing="5rem">
      <Stack border="1px solid #E0E0E0" borderRadius="1px">
        <Header {...mockData.header} />
        <JobDetails {...mockData.jobDetails} bidConfirmation />
      </Stack>
      <FormBox>
        <BidForm
          control={control}
          errors={errors}
          budget={mockData.bidSection.budget}
        />
      </FormBox>
    </Stack>
  );

  const stepperState = {
    stepArray: [step1, step2],
    horizontalStepperList: ["Job Details", "Submit Bid"],
  };

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } =
    useStepper(stepperState.stepArray);

  const isSubmitDisabled = isLastStep && !isValid;

  const mutation = useMutation({
    mutationFn: (data) => rawAxios.post("/api/v1/bids", data),
    onSuccess: () => {
      setOpen(true);
      reset();
      setIsDialogOpen(false);
      navigate("/");
    },
    onError: (error) => {
      console.error("Error submitting bid:", error);
    },
  });

  const handleStepSubmit = (stepIndex) => {
    if (stepIndex === 1) {
      handleSubmit((values) => {
        const updatedFormValues = { ...formValues, ...values };
        setFormValues(updatedFormValues);
        console.log("Form values on submit:", updatedFormValues);
        mutation.mutate(updatedFormValues);
      })();
    }
  };

  return (
    <Stack>
      <BottomSheetDialog
        title={
          <Typography variant="heading" fontWeight="bold">
            Bid on Job
          </Typography>
        }
        open={isDialogOpen}
        onClose={() => {
          goTo(0);
          setBidConfirmation(false);
          setIsDialogOpen(false);
        }}
        actions={
          <Stack
            direction="row"
            justifyContent={isFirstStep ? "flex-end" : "space-between"}
            width="100%"
          >
            {!isFirstStep && (
              <Button
                variant="secondary"
                autoFocus
                onClick={() => {
                  back();
                  setBidConfirmation(false);
                }}
              >
                Back
              </Button>
            )}
            {isLastStep && (
              <Button
                autoFocus
                disabled={isSubmitDisabled}
                onClick={async () => {
                  if (isLastStep) {
                    const isValid = await trigger();
                    if (isValid) {
                      handleStepSubmit(currentStepIndex);
                      setOpen(true);
                    }
                  } else {
                    next();
                  }
                }}
              >
                {isLastStep && "Submit Bid"}
              </Button>
            )}
          </Stack>
        }
      >
        <Stack spacing="3rem">
          <Stepper activeStep={currentStepIndex}>
            {stepperState.horizontalStepperList.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {step}
        </Stack>
      </BottomSheetDialog>
      <Button onClick={() => setIsDialogOpen(true)}>Open Bidding Dialog</Button>
      <SuccessDialog
        open={open}
        title="Bid Submitted!"
        description="Your bid has been submitted. Please wait for the venue owner to accept it."
        actions={
          <Stack direction="row">
            <Button
              size="medium"
              variant="primary"
              onClick={() => {
                reset();
                setOpen(false);
                setIsDialogOpen(false);
                navigate("/");
              }}
              fullWidth
            >
              Got it
            </Button>
          </Stack>
        }
      />
    </Stack>
  );
}

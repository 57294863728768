import { Box, Stack } from "@mui/material";
import { Typography } from "../Typography/Typography";
import { styled } from "@mui/material/styles";
import heroImage from "../../assets/samples/heroSection.jpg";
import { AutocompleteField } from "../Forms/Fields/AutocompleteField";

export const HeroContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "40vh",
  backgroundImage: `url(${heroImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  textAlign: "center",
  color: theme.palette.common.white,
  position: "relative",
  padding: theme.spacing(4),
}));

export const Overlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.6)", // Darker overlay for better contrast
  zIndex: 1,
}));

export const Content = styled(Stack)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  display: "flex",
  gap: "2rem",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

export const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3.5rem",
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

export const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.8rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.4rem",
  },
}));

export const SearchBar = styled(AutocompleteField)(({ theme }) => ({
  width: "100%",
  maxWidth: "500px",
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },

  [theme.breakpoints.down("sm")]: {
    paddingX: "10rem",
  },
}));

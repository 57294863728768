export const fontWeightStyles = {
  regular: { fontWeight: 400 },
  medium: { fontWeight: 500 },
  semiBold: { fontWeight: 600 },
  bold: { fontWeight: 700 },
};

export const typographyOptions = {
  titleHero: {
    fontSize: "4rem",
    lineHeight: "4.8rem",
    fontFamily: "'DM Serif Display', serif",
  },
  titlePage: {
    fontSize: "3.2rem",
    lineHeight: "3.84rem",
    fontFamily: "'DM Serif Display', serif",
  },
  subtitle: {
    fontSize: "2.4rem",
    lineHeight: "2.88rem",
    fontFamily: "'DM Serif Display', serif",
  },
  heading: {
    fontSize: "2rem",
    lineHeight: "2.4rem",
    fontFamily: "'DM Serif Display', serif",
  },
  subHeading: {
    fontSize: "1.8rem",
    lineHeight: "2.16rem",
    fontFamily: "'DM Serif Display', serif",
  },
  body: {
    fontSize: "1.6rem",
    lineHeight: "1.92rem",
    fontFamily: "'Figtree', sans-serif",
  },
  small: {
    fontSize: "1.4rem",
    lineHeight: "1.68rem",
    fontFamily: "'Figtree', sans-serif",
  },
  code: {
    fontSize: "1.5rem",
    lineHeight: "1.8rem",
    fontFamily: "'Figtree', sans-serif",
  },
  tiny: {
    fontSize: "1.3rem",
    lineHeight: "1.56rem",
    fontFamily: "'Figtree', sans-serif",
  },
  micro: {
    fontSize: "0.875rem",
    lineHeight: "1rem",
    fontFamily: "'Figtree', sans-serif",
  },
};

export const typographyFontStyleOptions = {
  fontSize: 14,
  htmlFontSize: 10,
  fontFamily: "'Figtree', sans-serif", // Default font family
};

export const typographyThemeOptions = {
  ...typographyFontStyleOptions,
  ...typographyOptions,
};

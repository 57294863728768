import { Stack } from "@mui/material";

import { Typography } from "../Typography";
import { UserAvatar } from "../Avatars";
export function UserDetails({
  fullName,
  email,
  avatarUrl,
  organizationKey,
  ...props
}) {
  return (
    <Stack direction="row" gap="0.8rem" width="100%" alignItems="center">
      <UserAvatar {...props} src={avatarUrl} />
      <Stack gap="0.8rem">
        <Stack gap="0.2rem" width="100%" alignItems="start">
          <Typography variant="bodySmall" fontWeight="medium" lineHeight="2rem">
            {fullName}
          </Typography>
          <Typography variant="bodyTiny" fontWeight="regular">
            {email}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

import _isFunction from 'lodash/isFunction';
import { MenuItemStyled } from './Menu.styled';
import { MenuItemContentRenderer } from './MenuItemContentRenderer';

export function MenuItem({
  disabled,
  onClick,
  closeMenu,
  children,
  ...rest
}) {
  return (
    <MenuItemStyled
      disabled={disabled}
      onClick={(e) => {
        if (_isFunction(closeMenu)) {
          closeMenu();
        }

        if (_isFunction(onClick)) {
          onClick(e);
        }
      }}
      {...rest}
    >
      <MenuItemContentRenderer {...rest} />

      {children}
    </MenuItemStyled>
  );
}

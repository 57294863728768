import { Box } from "@mui/material";
import { UndoAction, Xmark } from "iconoir-react";
import { IconButton } from "@mui/material";
import { Button } from "../../../Button";
import { AvatarStyled, ImageContainer } from "./ImageUploadInput.styled";

export function ImagePreview({
  file,
  onImageRemoveHandler,
  onImageReuploadHandler,
}) {
  return (
    <ImageContainer>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            right: "0.8rem",
            top: "0.8rem",
            zIndex: 1,
          }}
        >
          <IconButton onClick={onImageRemoveHandler} size="small" sx={{ width: "2.4rem", height: "2.4rem" }}>
            <Xmark />
          </IconButton>
        </Box>

        <AvatarStyled
          src={typeof file === "string" ? file : URL.createObjectURL(file)}
          variant="rounded"
          alt="Preview"
        />
      </Box>

      <Button
        variant="secondary"
        onClick={onImageReuploadHandler}
        fullWidth
        endIcon={<UndoAction width="2.4rem" height="2.4rem" />}
      >
        {"Reupload"}
      </Button>
    </ImageContainer>
  );
}

import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import otpIcon from "../../assets/icons/tick-inside-circle.png";
import axios from "axios";
import {
  Stack,
  Typography,
  TextField,
  Box,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "../Button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.mochshaColorPalette.mistyMint[900],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10rem 30rem",
  height: "65vh",
  [theme.breakpoints.down("lg")]: {
    padding: "8rem 20rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "6rem 10rem",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "5rem",
    paddingRight: "5rem",
  },
}));

const ContentCard = styled(Stack)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.mochshaColorPalette.galacticGrape[200],
  padding: theme.spacing(5),
  borderRadius: "8px",
  boxShadow: `0px 4px 20px ${theme.mochshaColorPalette.galacticGrape[500]}`,
  gap: "4rem",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
    gap: "2rem",
  },
}));

const OtpIcon = styled(Box)(({ theme }) => ({
  width: "4rem",
  height: "4rem",
  [theme.breakpoints.down("sm")]: {
    width: "3rem",
    height: "3rem",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.mochshaColorPalette.galacticGrape[900],
  fontWeight: "bold",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.mochshaColorPalette.galacticGrape[700],
  textAlign: "center",
}));

const OtpInput = styled(TextField)(({ theme }) => ({
  width: "4rem",
  backgroundColor: theme.mochshaColorPalette.mochshaGreen[400],
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.mochshaColorPalette.galacticGrape[600],
    },
    "&:hover fieldset": {
      borderColor: theme.mochshaColorPalette.galacticGrape[800],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.mochshaColorPalette.galacticGrape[700],
    },
  },
  "& input": {
    textAlign: "center",
    fontSize: "1.5rem",
    color: theme.mochshaColorPalette.galacticGrape[800],
  },
  [theme.breakpoints.down("sm")]: {
    width: "5rem",
    "& input": {
      fontSize: "1.2rem",
    },
  },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.mochshaColorPalette.eclipseCherry[700],
  textAlign: "center",
}));

const ResendLink = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: theme.mochshaColorPalette.galacticGrape[800],
  "&:hover": {
    color: theme.mochshaColorPalette.galacticGrape[900],
    textDecoration: "underline",
  },
}));

const SuccessText = styled(Typography)(({ theme }) => ({
  color: theme.mochshaColorPalette.mintGreen[700],
  textAlign: "center",
}));

const otpSchema = z.object({
  otp: z.array(z.string().length(1).regex(/^\d$/)).length(5),
});

export const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || "example@gmail.com";
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const OTP_LENGTH = 5;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: zodResolver(otpSchema),
    defaultValues: {
      otp: Array(OTP_LENGTH).fill(""),
    },
  });

  const inputRefs = useRef([]);
  const [resendMessage, setResendMessage] = useState("");
  const [error, setError] = useState("");

  const otp = watch("otp");

  const handleChange = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setValue("otp", newOtp);

      if (value !== "" && index < OTP_LENGTH - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "ArrowRight" && index < OTP_LENGTH - 1) {
      inputRefs.current[index + 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    const maskedLocalPart =
      localPart.slice(0, 3) + "*".repeat(localPart.length - 3);
    return `${maskedLocalPart}@${domain}`;
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://api.mochsha.in/api/v1/users/verify-otp",
        {
          email: email,
          otp: data.otp.join(""),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response && response.data) {
        console.log("Cookies received:", document.cookie);

        if (response.status === 200) {
          navigate("/");
        } else {
          setError(response.data.message);
        }
      } else {
        setError("Unexpected response format");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || "An error occurred");
      } else if (error.request) {
        setError(
          "No response from server. Please check your network connection."
        );
      } else {
        setError("An unexpected error occurred: " + error.message);
      }
    }
  };

  const handleResend = async () => {
    try {
      const response = await fetch(
        "http://localhost:5000/api/v1/users/resend-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setResendMessage("OTP has been resent to your email.");
      } else {
        console.log(data.message);
        setError(data.message);
      }
    } catch (error) {
      setError("An error occurred while resending OTP");
    }
  };

  return (
    <PageContainer>
      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <ContentCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing={isSmallScreen ? 2 : 4}
              alignItems="center"
              justifyContent="center"
            >
              <OtpIcon component="img" src={otpIcon} alt="OTP Icon" />
              <Title variant={isSmallScreen ? "h5" : "h4"}>Verify OTP</Title>
              <Subtitle variant={isSmallScreen ? "body2" : "body1"}>
                An OTP has been sent to {maskEmail(email)}
              </Subtitle>
              <Stack direction="row" spacing="1rem" justifyContent="center">
                {otp.map((digit, index) => (
                  <OtpInput
                    key={index}
                    type="text"
                    {...register(`otp.${index}`)}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </Stack>
              {errors.otp && (
                <ErrorText variant={isSmallScreen ? "body2" : "body1"}>
                  Please fill all OTP fields with valid digits.
                </ErrorText>
              )}
              {error && (
                <ErrorText variant={isSmallScreen ? "body2" : "body1"}>
                  {error}
                </ErrorText>
              )}
              <Subtitle variant={isSmallScreen ? "body2" : "body1"}>
                Didn't receive the OTP?{" "}
                <ResendLink
                  component="span"
                  variant={isSmallScreen ? "body2" : "body1"}
                  onClick={handleResend}
                >
                  Resend OTP
                </ResendLink>
              </Subtitle>
              {resendMessage && (
                <SuccessText variant={isSmallScreen ? "body2" : "body1"}>
                  {resendMessage}
                </SuccessText>
              )}
            </Stack>

            <Stack
              direction="row"
              justifyContent={isSmallScreen ? "center" : "flex-end"}
              mt={isSmallScreen ? 2 : 4}
            >
              <Button type="submit" disabled={!otp.every((digit) => digit !== "")}>Verify</Button>
            </Stack>
          </form>
        </ContentCard>
      </Stack>
    </PageContainer>
  );
};

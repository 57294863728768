export const aboutUsContent = {
  tagline: "The Digital Marketplace For Live Music",

  brandStory: {
    title: "MOCHSHA: A Symphony of Purpose and Passion",
    paragraphs: [
      "In the heart of Bangalore, a new harmony is born. MOCHSHA, a vibrant marketplace for musicians, emerged to tackle two pressing issues: the lack of transparency in live gig bookings and the frustrating delays in payment post-performance. Founders recognized that the middlemen and unclear communication often clouded the requirements from clients, leaving musicians in the dark.",

      "The journey of MOCHSHA began with its founder-Prasad's personal story. Following a career of more than a decade in the automotive industry, the founder sought a new direction—one that fused a passion for music with cutting-edge technology. Inspired by a transformative experience while gigging in Detroit, where the founder felt welcomed and valued as an unknown drummer, the vision for MOCHSHA started to take shape.",

      "MOCHSHA's uniqueness lies in its musically and technically grounded leadership. The founder's deep understanding of both realms ensures that decisions are made with empathy and insight. The platform's digitized approach to show listings, user profiles, and payments aims to be a beacon of transparency and ethics in an often poorly structured industry.",

      "At its core, MOCHSHA strives to evoke trust and a sense of community among its users. By being a reliable partner in every transaction and fostering a cohesive network of musicians, venue owners, and event organizers, MOCHSHA not only aims to enrich the cultural fabric of India but also to create a profitable, sustainable business that uplifts all its stakeholders.",

      "MOCHSHA is more than just a platform; it's a movement towards a more transparent, ethical, and vibrant live music ecosystem in India. Welcome to MOCHSHA—where music finds its true stage, and every performance is a step towards a harmonious future.",
    ],
  },

  values: [
    {
      title: "Empowerment",
      description:
        "Enabling musicians to thrive by providing them with opportunities and resources to showcase their talents.",
    },
    {
      title: "Community",
      description:
        "Building a supportive, interconnected network of artists, venue owners, event organizers, and audiences.",
    },
    {
      title: "Diversity",
      description: "Celebrating the rich and varied music throughout India.",
    },
    {
      title: "Excellence",
      description:
        "Committing to high standards in every aspect of the platform, from artist selection to user experience.",
    },
    {
      title: "Innovation",
      description:
        "Continuously improving and adapting to meet the evolving needs of the music industry.",
    },
    {
      title: "Passion",
      description: "Fostering a deep love for music and live performances.",
    },
    {
      title: "Integrity",
      description:
        "Upholding transparency, honesty, and fairness in all interactions.",
    },
  ],

  mission: {
    title: "Mission",
    description:
      "To empower musicians and create a thriving ecosystem by providing a vibrant marketplace that connects them with live gig opportunities, engaging venue owners, event organizers, and audiences, while enriching the diverse cultural fabric of India through live music experiences.",
  },

  vision: {
    title: "Vision",
    description:
      "To be India's premier platform for live gig hiring, revolutionizing the music industry by making live performances accessible, and celebrating the diverse musical heritage of the nation.",
  },
};

export default aboutUsContent;

import { styled } from "@mui/material/styles";
import { AppBar, Toolbar } from "@mui/material";

export const AppBarStyled = styled(AppBar)(({ theme }) => {
  const { galacticGrape } = theme.mochshaColorPalette;

  return {
    background: galacticGrape[200],
    color: galacticGrape[900],
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: `1px solid ${galacticGrape[400]}`,
  };
});

export const AppBarToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== "secondaryColor",
})(({ theme, secondaryColor }) => {
  return {
    background: secondaryColor ? theme.mochshaColorPalette.galacticGrape[200] : theme.mochshaColorPalette.mochshaGreen[400],
  };
});

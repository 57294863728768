import React, { useLayoutEffect } from "react";
import { Login } from "../../components/Onboarding/login";
import { useTheme } from "@mui/material";
import { Footer } from "../../components/footer/FooterSection";

const LoginPage = () => {
  const theme = useTheme();
  useLayoutEffect(() => {
    document.body.style.backgroundColor =
      theme.mochshaColorPalette.mistyMint[900];
  });
  return (
    <div className="onboarding-page">
      <Login />
      <Footer />
    </div>
  );
};

export default LoginPage;

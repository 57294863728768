import React from "react";
import { Box, Stack, Typography, Avatar, Paper, Link } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

export function VenueOwnerInfo({
  avatar,
  name,
  rating,
  role,
  location,
  portfolioLink,
  aboutMe,
}) {
  const theme = useTheme();
  const { galacticGrape, mochshaGreen } = theme.mochshaColorPalette;

  const VenueOwnerInfoWrapper = styled(Box)(({ theme }) => ({
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));

  return (
    <VenueOwnerInfoWrapper width="50%">
      <Stack spacing="1rem" padding="1rem">
        <Typography
          variant="heading"
          color={galacticGrape[900]}
          fontWeight="semibold"
        >
          More about {name}
        </Typography>
        <Stack direction="row" spacing="1rem" alignItems="center">
          <Avatar alt={name} src={avatar} />
          <Stack spacing="0.2rem">
            <Typography variant="body" color="#1E1E1E">
              {name} • {rating} ⭐️
            </Typography>
            <Typography variant="subText" color="#1E1E1E">
              {role}
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          <Typography
            variant="bodyStrong"
            color={galacticGrape[900]}
            fontWeight="semiBold"
          >
            About Me
          </Typography>
          <Typography variant="bodySmall" color="textSecondary">
            {aboutMe}
          </Typography>
        </Stack>
        <Stack direction="row" spacing="2rem">
          <Paper>
            <Stack padding="1rem">
              <Typography variant="bodyStrong" color={galacticGrape[900]}>
                Location
              </Typography>
              <Typography variant="bodySmall" color={galacticGrape[700]}>
                {location}
              </Typography>
            </Stack>
          </Paper>
          <Box
            border="1px solid #E0E0E0"
            padding="1rem"
            backgroundColor={mochshaGreen[400]}
            borderRadius="5px"
          >
            <Typography variant="bodyStrong" color={galacticGrape[900]}>
              Sample
            </Typography>
            <Link
              href={portfolioLink}
              target="_blank"
              color={galacticGrape[700]}
              variant="bodySmall"
            >
              portofolio link
            </Link>
          </Box>
        </Stack>
      </Stack>
    </VenueOwnerInfoWrapper>
  );
}

import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Paper, Typography, Container, Stack } from "@mui/material";
import { motion } from "framer-motion";
import ReviewsSection from "./Reviews";
import { DashboardSection } from "./Dashboard";
import ProfileSection from "./ProfileSection";
import StandaloneSearch from "./StandaloneSearch";

// Styled components
const PageWrapper = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: theme.palette.grey[100],
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const ContentWrapper = styled(Container)(({ theme }) => ({
  maxWidth: 1200,
  margin: "0 auto",
  height: "100%",
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  backgroundColor: "#fff",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(2),
  },
}));

const defaultCenter = {
  lat: 12.9716,
  lng: 77.5946,
};

const ProfilePage = () => {
  const reviews = [
    {
      id: 1,
      rating: 5,
      comment: "Excellent work!",
      author: "John Doe",
      date: "2024-03-15",
    },
    {
      id: 2,
      rating: 4,
      comment: "Great communication.",
      author: "Jane Smith",
      date: "2024-03-10",
    },
    {
      id: 3,
      rating: 5,
      comment: "Very professional.",
      author: "Mike Johnson",
      date: "2024-03-05",
    },
  ];

  const dashboard = {
    activeProjects: 8,
    completedProjects: 7,
    teamMembers: 12,
  };

  const profileData = {
    profile_id: "3c44d8c7-d691-46e3-bdf3-c500582495b8",
    user_id: "782122f9-cada-4f02-bf0b-c6a335a56bbe",
    first_name: "Vimal",
    last_name: "Kumar",
    display_name: "Test",
    profile_picture: "/uploads/images/1732349778126-aceInfra_updated.jpeg",
    location: "Bangalore North",
    social_links: [{ link: "facebook" }],
    phone_number: "9945913097",
    gst_info: "12345678910AAAA",
    bio: "Experienced professional with a passion for innovation and technology.",
    is_active: true,
    user_role: "client",
    createdAt: "2024-11-19T15:42:49.323Z",
    updatedAt: "2024-11-23T08:16:18.183Z",
  };

  // Animation variants
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <Grid container spacing={3}>
          {/* Left Column - Profile */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <motion.div {...fadeInUp} style={{ height: "100%" }}>
              <Section
                sx={{
                  height: "100%",
                  minHeight: { xs: "400px", md: "600px" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SectionTitle>Profile</SectionTitle>
                <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                  <ProfileSection profile={profileData} />
                </Box>
              </Section>
            </motion.div>
          </Grid>

          {/* Right Column - Reviews */}
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <motion.div
              {...fadeInUp}
              transition={{ delay: 0.2 }}
              style={{ height: "100%" }}
            >
              <Section
                sx={{
                  height: "100%",
                  minHeight: { xs: "400px", md: "600px" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SectionTitle>Recent Reviews</SectionTitle>
                <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                  <ReviewsSection reviews={reviews} />
                </Box>
              </Section>
            </motion.div>
          </Grid>

          {/* Map Section */}
          <Grid item xs={12}>
            <motion.div {...fadeInUp} transition={{ delay: 0.3 }}>
              <StandaloneSearch />
            </motion.div>
          </Grid>

          {/* Bottom Row - Dashboard */}
          <Grid item xs={12}>
            <motion.div {...fadeInUp} transition={{ delay: 0.4 }}>
              <Section
                sx={{
                  minHeight: { xs: "250px", md: "300px" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SectionTitle>Dashboard</SectionTitle>
                <Box sx={{ flexGrow: 1 }}>
                  <DashboardSection dashboard={dashboard} />
                </Box>
              </Section>
            </motion.div>
          </Grid>
        </Grid>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ProfilePage;

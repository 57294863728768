import React, { useLayoutEffect } from "react";
import { Register } from "../../components/Onboarding/Register";
import { useTheme } from "@mui/material";
import { Footer } from "../../components/footer/FooterSection";

const RegisterPage = () => {
  const theme = useTheme();
  useLayoutEffect(() => {
    document.body.style.backgroundColor =
      theme.mochshaColorPalette.mistyMint[900];
  });
  return (
    <>
      <Register />
      <Footer />
    </>
  );
};

export default RegisterPage;

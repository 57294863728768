import React from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Typography,
  Paper,
  Chip,
  Stack,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";

// Styled Components
const ProfileContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const ProfileAvatar = styled(motion(Avatar))(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[3],
}));

const InfoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "1rem",
}));

const StatusBadge = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontWeight: "bold",
  fontSize: "0.85rem",
  color: theme.palette.common.white,
}));

const SocialLinksContainer = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  gap: theme.spacing(1),
}));

// Profile Component
const ProfileComponent = ({ profile }) => {
  const {
    profile_picture,
    display_name,
    first_name,
    last_name,
    location,
    phone_number,
    gst_info,
    social_links,
    is_active,
    user_role,
  } = profile;

  const socialIcons = {
    facebook: <FacebookIcon />,
    // Add other platforms here (e.g., Instagram, LinkedIn, Twitter)
  };

  return (
    <ProfileContainer elevation={3}>
      {/* Profile Picture */}
      <ProfileAvatar
        src={profile_picture || "/default-avatar.png"}
        alt={`${first_name} ${last_name}`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      />

      {/* Name and Role */}
      <Typography variant="h5" fontWeight="bold">
        {first_name} {last_name} ({display_name || "User"})
      </Typography>
      <InfoText>{user_role?.toUpperCase()}</InfoText>

      {/* Location */}
      <InfoText>
        <BusinessIcon
          fontSize="small"
          style={{ verticalAlign: "middle", marginRight: 4 }}
        />
        {location || "Location not provided"}
      </InfoText>

      {/* Status */}
      <StatusBadge
        label={is_active ? "Active" : "Inactive"}
        color={is_active ? "success" : "default"}
      />

      {/* Contact Information */}
      <Box mt={2} width="100%">
        <InfoText>
          <PhoneIcon
            fontSize="small"
            style={{ verticalAlign: "middle", marginRight: 4 }}
          />
          {phone_number || "Phone not provided"}
        </InfoText>
        <InfoText>
          <BusinessIcon
            fontSize="small"
            style={{ verticalAlign: "middle", marginRight: 4 }}
          />
          GST: {gst_info || "Not provided"}
        </InfoText>
      </Box>

      {/* Social Links */}
      {social_links && social_links.length > 0 && (
        <SocialLinksContainer direction="row" justifyContent="center">
          {social_links.map((link, index) => (
            <IconButton
              key={index}
              component="a"
              href={`https://www.${link.link}.com`}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              {socialIcons[link.link] || null}
            </IconButton>
          ))}
        </SocialLinksContainer>
      )}
    </ProfileContainer>
  );
};

export default ProfileComponent;

const initialState = {
  jobPostData: {
    title: "",
    description: "",
    category: "",
    budget: {
      min: "",
      max: "",
    },
    event_dates: [],
    event_time_from: "",
    event_time_to: "",
  },
};

const jobPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_JOB_POST":
      if (action.payload.name.startsWith("budget.")) {
        const budgetKey = action.payload.name.split(".")[1];
        return {
          ...state,
          jobPostData: {
            ...state.jobPostData,
            budget: {
              ...state.jobPostData.budget,
              [budgetKey]: action.payload.value,
            },
          },
        };
      }
      return {
        ...state,
        jobPostData: {
          ...state.jobPostData,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default jobPostReducer;

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  styled,
} from "@mui/material";
import { Typography } from "../Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateTime } from "luxon";

const ResponsiveContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "2rem",
  },
}));

const TitleSection = styled(Stack)(({ theme }) => ({
  width: "30%",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    gap: "1rem",
  },
}));

const ContentSection = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const Review = ({ formValues }) => {
  return (
    <ResponsiveContainer>
      <TitleSection>
        <Typography variant="titlePage" fontWeight="bold">
          Review Your Event
        </Typography>
        <Typography variant="subHeading">
          Let's ensure everything is in place before publishing.
        </Typography>
      </TitleSection>

      <ContentSection>
        <Stack spacing="0rem">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold" variant="heading">
                About
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="1rem">
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Title
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.title}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Description
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.description}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Category & Subcategory
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.category} / {formValues.subcategory}
                  </Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography fontWeight="bold" variant="heading">
                Event Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="1rem">
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Event Venue
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.location}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Event Date
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues?.event_date?.c?.day || "N/A"} /
                    {formValues?.event_date?.c?.month || "N/A"} /
                    {formValues?.event_date?.c?.year || "N/A"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Event Time
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    {formValues.start_time
                      ? DateTime.fromISO(formValues.start_time).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )
                      : "N/A"}{" "}
                    to{" "}
                    {formValues.end_time
                      ? DateTime.fromISO(formValues.end_time).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )
                      : "N/A"}
                  </Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography fontWeight="bold" variant="heading">
                Budget
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing="1rem">
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Budget From
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    ₹{formValues?.budget_range?.[0]}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="subHeading">
                    Budget To
                  </Typography>
                  <Typography fontWeight="regular" variant="bodyBase">
                    ₹{formValues?.budget_range?.[1]}
                  </Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </ContentSection>
    </ResponsiveContainer>
  );
};

import { Stack } from "@mui/material";

import { AppBarLogoSkeleton } from "./AppBarLogoSkeleton";
import { AppBarLogoStyled } from "./AppBarLogo.styled";
import mochshaLogo from "../../../assets/logo/DarkLogoTransparent.png";
import { Typography } from "../../Typography";

export function AppBarLogo({
  headerTitle,
  headerSubtitle,
  headerLogo = mochshaLogo,
  onLogoClick,
  isPanelOpen,
  loading,
}) {
  if (loading) {
    return <AppBarLogoSkeleton />;
  }

  return (
    <AppBarLogoStyled
      justifyContent={isPanelOpen ? "initial" : "center"}
      onClick={onLogoClick}
    >
      <img
        src={headerLogo}
        alt={headerTitle}
        width={138}
        height={27}
        style={{ overflow: "hidden" }}
      />

      <Stack justifyContent="center">
        <Typography variant="subHeader1" fontWeight="bold">
          {headerTitle}
        </Typography>

        {headerSubtitle && (
          <Typography variant="subText" color="text.secondary">
            {headerSubtitle}
          </Typography>
        )}
      </Stack>
    </AppBarLogoStyled>
  );
}

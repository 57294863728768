import vocalistImage from "./cardMedia/vocalist.jpg";
import guitaristImage from "./cardMedia/guitarist.jpg";
import soundEngineerImage from "./cardMedia/soundEngineer.jpg";
import managerImage from "./cardMedia/manager.jpg";
import bandImage from "./cardMedia/band.jpg";
import media from "./cardMedia/Media.jpg";

// const categoryImages = {
//   Vocalist: vocalistImage,
//   Guitarist: guitaristImage,
//   "Sound Engineer": soundEngineerImage,
//   Manager: managerImage,
//   Band: bandImage,
//   Media: media,
// };

const defaultImages = [
  "https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3", // Concert crowd with lights
  "https://images.unsplash.com/photo-1540039155733-5bb30b53aa14", // Concert crowd hands up
  "https://images.unsplash.com/photo-1501281668745-f7f57925c3b4", // Stadium concert at night
  "https://images.unsplash.com/photo-1501612780327-45045538702b", // Live concert stage
  "https://images.unsplash.com/photo-1429962714451-bb934ecdc4ec", // Band performing live
  "https://images.unsplash.com/photo-1524368535928-5b5e00ddc76b", // Concert crowd silhouette
  "https://images.unsplash.com/photo-1516450360452-9312f5e86fc7", // Arena concert lights
  "https://images.unsplash.com/photo-1459749411175-04bf5292ceea", // Concert stage lights
  "https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3", // Rock concert performance
  "https://images.unsplash.com/photo-1483393458019-411bc6bd104e", // Concert light show
  "https://images.unsplash.com/photo-1524650359799-842906ca1c06", // Live band on stage
  "https://images.unsplash.com/photo-1492684223066-81342ee5ff30", // Crowd at music festival
  "https://images.unsplash.com/photo-1506157786151-b8491531f063", // Concert microphone closeup
  "https://images.unsplash.com/photo-1510731491248-6322e37f0e56", // Concert crowd sunset
  "https://images.unsplash.com/photo-1510915361894-db8b60106cb1", // Festival crowd aerial view
  "https://images.unsplash.com/photo-1511735111819-9a3f7709049c", // Indoor concert venue
  "https://images.unsplash.com/photo-1514525253161-7a46d19cd819", // Concert stage pyrotechnics
  "https://images.unsplash.com/photo-1576328077645-2dd68934d2b7", // Stadium concert crowd
  "https://images.unsplash.com/photo-1524368535928-5b5e00ddc76b", // Concert light beams
  "https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee", // Live performance wide shot
];

// Function to get random image
const getRandomDefaultImage = () => {
  const randomIndex = Math.floor(Math.random() * defaultImages.length);
  return defaultImages[randomIndex];
};

const categoryImages = {
  Vocalist:
    "https://images.unsplash.com/photo-1516280440614-37939bbacd81?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
  Guitarist:
    "https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
  SoundEngineer:
    "https://images.unsplash.com/photo-1590069261209-f8e9b8642343?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1176&q=80",
  Manager:
    "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
  Band: "https://images.unsplash.com/photo-1429962714451-bb934ecdc4ec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
  Media:
    "https://images.unsplash.com/photo-1495106245177-55dc6f43e83f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",

  // Instead of a static default, use a getter
  get default() {
    return getRandomDefaultImage();
  },
};

export default categoryImages;

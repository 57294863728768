import _ from "lodash";

export const languageOptions = [
  {
    value: "english",
    label: "English",
  },
  {
    value: "kannada",
    label: "Kannada",
  },
  {
    value: "malayalam",
    label: "Malayalam",
  },
  {
    value: "tamil",
    label: "Tamil",
  },
  {
    value: "telugu",
    label: "Telugu",
  },
  {
    value: "hindi",
    label: "Hindi",
  },
  {
    value: "marathi",
    label: "Marathi",
  },
];

export const skillOptions = [
  {
    value: "vocalist",
    label: "Vocalist",
  },
  {
    value: "guitar",
    label: "Guitar",
  },
  {
    value: "piano",
    label: "Piano",
  },
  {
    value: "drums",
    label: "Drums",
  },
  {
    value: "bass",
    label: "Bass",
  },
  {
    value: "violin",
    label: "Violin",
  },
  {
    value: "saxophone",
    label: "Saxophone",
  },
  {
    value: "flute",
    label: "Flute",
  },
  {
    value: "tabla",
    label: "Tabla",
  },
  {
    value: "dhol",
    label: "Dhol",
  },
  {
    value: "mrudangam",
    label: "Mrudangam",
  },
  {
    value: "sitar",
    label: "Sitar",
  },
  {
    value: "synthesizer",
    label: "Synthesizer",
  },
  {
    value: "nadaswaram",
    label: "Nadaswaram",
  },
];

export const experienceOptions = [
  {
    value: "0-1 years",
    label: "0-1 years",
  },
  {
    value: "1-3 years",
    label: "1-3 years",
  },
  {
    value: "3-5 years",
    label: "3-5 years",
  },
  {
    value: "5+ years",
    label: "5+ years",
  },
];

export const isDisabled = (
  currentStepIndex,
  proprofileWatch,
  proprofileErrors,
  socialsWatch,
  socialsErrors
) => {
  const validationLogicForAllFields = (watchFn, expectedLength, errors) => {
    const isEmptyOrNil = (value) => _.isNil(value) || _.isEmpty(value);

    return (
      Object.keys(watchFn()).length !== expectedLength ||
      Object.keys(errors).length > 0 ||
      !Object.values(watchFn()).every(
        (value) => value !== null && value !== undefined && value !== ""
      ) ||
      watchFn().skills?.some((item) => _.some(item, isEmptyOrNil))
      // watchFn().socialLinks?.some((item) => _.some(item, isEmptyOrNil))
    );
  };

  const validationLogicForSocials = (watchFn, errors) => {
    const isEmptyOrNil = (value) => _.isNil(value) || _.isEmpty(value);

    return watchFn().socialLinks?.some((item) => _.some(item, isEmptyOrNil));
  };

  if (currentStepIndex.toString() === "0") {
    return validationLogicForAllFields(proprofileWatch, 4, proprofileErrors);
  }
  if (currentStepIndex.toString() === "1") {
    return validationLogicForSocials(socialsWatch, socialsErrors);
  }

  return false;
};

import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import mochshaLogo from "../../assets/logo/DarkLogoTransparent.png";
import { useUser } from "../../context/UserContext";

export const RazorpayPayment = (selectedBid) => {
  const { userDetails } = useUser();

  const [orderDataDetails, setOrderDataDetails] = useState(null);

  // Create payment mutation
  const createPaymentMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        `https://api.mochsha.in/api/v1/payments/${selectedBid.id}`,
        {
          billed_amount: Number(selectedBid.amount),
          payment_status: "in_progress",
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    },
  });

  // Verify payment mutation
  const verifyPaymentMutation = useMutation({
    mutationFn: async ({ transactionId, paymentId }) => {
      const response = await axios.put(
        `https://api.mochsha.in/api/v1/payments/${transactionId}`,
        {
          external_payment_id: paymentId,
          payment_status: "success",
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    },
  });

  // Accept bid mutation
  const acceptBidMutation = useMutation({
    mutationFn: async (bidId) => {
      const response = await axios.post(
        `https://api.mochsha.in/api/v1/bids/${bidId}/accept`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    },
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async () => {
    try {
      // Create payment
      const orderData = await createPaymentMutation.mutateAsync();

      setOrderDataDetails(orderData);

      console.log(
        selectedBid.amount,
        selectedBid,
        `${userDetails?.profile?.first_name} ${userDetails?.profile?.last_name}`,
        userDetails?.email,
        userDetails?.profile?.phone_number
      );

      const options = {
        key: "rzp_test_JOC0wRKpLH1cVW",
        amount: selectedBid.amount * 100, // Convert to paise
        currency: "INR",
        name: "Mochsha",
        description: "Payment for your booking",
        image: mochshaLogo, // Ensure this is a valid URL or base64 string // Fetch this from Razorpay Orders API
        handler: function (response) {
          console.log(response, "response");
          verifyPayment(response, orderData); // Ensure verifyPayment is defined
        },
        prefill: {
          name: `${userDetails?.profile?.first_name || ""} ${
            userDetails?.profile?.last_name || ""
          }`,
          email: userDetails?.email || "example@example.com",
          contact: userDetails?.profile?.phone_number || "0000000000",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Payment failed:", error);
      alert("Payment failed. Please try again.");
    }
  };

  const verifyPayment = async (response, orderData) => {
    try {
      // Verify payment
      await verifyPaymentMutation.mutateAsync({
        transactionId: orderData.transaction_id,
        paymentId: response.razorpay_payment_id,
      });

      // Accept bid
      await acceptBidMutation.mutateAsync(selectedBid.id);

      alert("Payment successful and bid accepted!");
      // Add navigation or UI update logic here
    } catch (error) {
      console.error("Verification failed:", error);
      if (error.response?.data?.message) {
        alert(error.response.data.message);
      } else {
        alert("Payment verification failed");
      }
    }
  };

  return handlePayment;
};

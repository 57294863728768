import React from "react";
import MusicianProfile from "../../components/MusicianProfile/MusicianProfile";

const OnboardingPage = () => {
  return (
    <div className="musicianprofile">
      <MusicianProfile />
    </div>
  );
};

export default OnboardingPage;

import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Typography } from "../Typography";

export function ToastBody({ title, description }) {
  const { mochshaColorPalette } = useTheme();

  return (
    <Stack gap="0.4rem">
      <Typography
        color={mochshaColorPalette.galacticGrape[900]}
        fontWeight="medium"
        variant="subHeader2"
      >
        {title}
      </Typography>

      {description && (
        <Typography
          align="left"
          color={mochshaColorPalette.galacticGrape[700]}
          variant="body"
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
}

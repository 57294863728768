import { styled, Avatar } from "@mui/material";
import { Typography } from "../../Typography";
import { avatarsPalette } from "./UserAvatar";

export const StyledAvatar = styled(Avatar)(
  ({ color = "easternBlue", size = "medium", theme }) => {
    const { bone, easternBlue, edgeWater, tropicalBlue, lightPink } =
      avatarsPalette;

    const colorStyles = {
      bone,
      easternBlue,
      edgeWater,
      lightPink,
      tropicalBlue,
    };

    const sizeStyles = {
      xsmall: {
        inlineSize: "2rem",
        blockSize: "2rem",
      },
      small: {
        inlineSize: "3rem",
        blockSize: "3rem",
      },
      medium: {
        inlineSize: "4rem",
        blockSize: "4rem",
      },
      large: {
        inlineSize: "7rem",
        blockSize: "7rem",
      },
    };

    return {
      ...colorStyles[color],
      ...sizeStyles[size],
      textTransform: "uppercase",
    };
  }
);

export const TypographyStyled = styled(Typography)(({ size = "medium" }) => {
  const fonts = {
    xsmall: {
      fontSize: "1.2rem",
    },
    small: {
      fontSize: "1.2rem",
    },
    medium: {
      fontSize: "1.6rem",
    },
    large: {
      fontSize: "2.4rem",
    },
  };

  return { ...fonts[size] };
});

import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PerformanceCompletedCheckbox = ({ control }) => {
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="center">
        <Controller
          name="performanceCompleted"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <Checkbox
              {...field}
              value="yes"
              checked={field.value === "yes"}
              onChange={(e) =>
                field.onChange(e.target.value === "yes" ? "yes" : null)
              }
              sx={{
                "&.Mui-checked": {
                  color: theme.mochshaColorPalette.deepCharcoal[900],
                },
              }}
            />
          )}
        />
        <Typography variant="bodyBase">Yes, It went well</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Controller
          name="performanceCompleted"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <Checkbox
              {...field}
              value="no"
              checked={field.value === "no"}
              onChange={(e) =>
                field.onChange(e.target.value === "no" ? "no" : null)
              }
              sx={{
                "&.Mui-checked": {
                  color: theme.mochshaColorPalette.deepCharcoal[900],
                },
              }}
            />
          )}
        />
        <Typography variant="bodyBase">No, there were issues</Typography>
      </Stack>
    </Stack>
  );
};

export default PerformanceCompletedCheckbox;

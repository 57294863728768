import { TypographyStyled } from "./Typography.styled";

export const Typography = ({
  variant = "subHeader1",
  fontWeight = "regular",
  ...props
}) => {
  return (
    <TypographyStyled variant={variant} fontWeight={fontWeight} {...props} />
  );
};

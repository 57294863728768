import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Paper,
  Accordion,
  List,
  ListItem,
  ListItemText,
  Fab,
  useTheme,
  useMediaQuery,
  Drawer,
  IconButton,
  List as MobileList,
  ListItemButton,
  ListItemText as MobileListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "../../components/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import { termsContent } from "./TermsOfUseContent";

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "&:before": {
    display: "none",
  },
}));

const ContentSection = styled("div")({
  scrollMarginTop: "100px",
});

const StyledFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(4),
  right: theme.spacing(4),
}));

const TableOfContents = styled(Paper)(({ theme }) => ({
  position: "fixed",
  top: theme.spacing(8),
  right: theme.spacing(2),
  width: "280px",
  padding: theme.spacing(2),
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

// Create sections array from content
const sections = termsContent.sections.map((section) => ({
  id: section.id,
  label: section.title,
}));

const TermsOfUse = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [expandedSection, setExpandedSection] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAccordionChange = (sectionId) => (event, isExpanded) => {
    setExpandedSection(isExpanded ? sectionId : false);
  };

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSectionClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setMobileMenuOpen(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ pb: 8, pt: 4 }}>
      {/* Mobile Navigation */}
      {isMobile && (
        <>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMobileMenuOpen(true)}
            sx={{ mb: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
          >
            <MobileList sx={{ width: 250 }}>
              {sections.map((section, index) => (
                <ListItemButton
                  key={section.id}
                  onClick={() => handleSectionClick(section.id)}
                >
                  <MobileListItemText
                    primary={`${section.label}`}
                  />
                </ListItemButton>
              ))}
            </MobileList>
          </Drawer>
        </>
      )}

      {/* Desktop Navigation */}
      {!isMobile && (
        <TableOfContents elevation={1}>
          <Typography variant="h6" gutterBottom>
            Contents
          </Typography>
          <List>
            {sections.map((section) => (
              <ListItem
                key={section.id}
                button
                onClick={() => handleSectionClick(section.id)}
              >
                <ListItemText primary={section.label} />
              </ListItem>
            ))}
          </List>
        </TableOfContents>
      )}

      {/* Main Content */}
      <Box sx={{ maxWidth: isMobile ? "100%" : "calc(100% - 300px)" }}>
        {/* Main Title */}
        <StyledPaper elevation={0}>
          <Typography variant="h4" component="h1" gutterBottom>
            {termsContent.title}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Last Updated: {termsContent.lastUpdated}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Version: {termsContent.version}
          </Typography>
          <Typography variant="body1" paragraph>
            {termsContent.intro}
          </Typography>
        </StyledPaper>

        {/* Render sections dynamically */}
        {termsContent.sections.map((section, index) => (
          <ContentSection key={section.id} id={section.id}>
            <Typography variant="h5">{section.title}</Typography>

            {Array.isArray(section.content) ? (
              section.content.map((paragraph, index) => (
                <Typography key={index} variant="body1" paragraph>
                  {paragraph}
                </Typography>
              ))
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  {section.content}
                </Typography>
                {section.listItems && (
                  <List>
                    {section.listItems.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={item.primary}
                          secondary={item.secondary}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            )}
          </ContentSection>
        ))}

        {/* Back to Top Button */}
        {showBackToTop && (
          <StyledFab
            size="small"
            onClick={handleBackToTop}
            aria-label="back to top"
          >
            <KeyboardArrowUpIcon />
          </StyledFab>
        )}
      </Box>
    </Container>
  );
};

export default TermsOfUse;

// frontend/src/store/store.js
import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import musicianReducer from "./musicianReducer";
import jobPostReducer from "./jobPostReducer";

const rootReducer = combineReducers({
  musician: musicianReducer,
  jobPost: jobPostReducer,
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;

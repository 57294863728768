import { IconButton, iconButtonClasses, styled } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { Box } from "@mui/system";

export const ToasterStyled = styled(ToastContainer)(({ theme }) => {
  const toastBody = {
    "& .Toastify__toast-container": {
      padding: "0px",
    },

    "& .Toastify__toast": {
      padding: "0px",
      inlineSize: "38rem",
      marginBlockEnd: "2rem",

      borderRadius: "8px",
      border: `0.2rem solid ${theme.mochshaColorPalette.galacticGrape[400]}`,
      boxShadow: `0px 0px 12px 8px rgba(109, 114, 120, 0.05)`,
    },

    "& .Toastify__toast-body": {
      padding: "0px",
    },
  };

  const toastSuccess = {
    "& .Toastify__toast-theme--light.Toastify__toast--success": {
      "& .Toastify__toast-body": {
        borderInlineStart: `0.6rem solid ${theme.mochshaColorPalette.auroraLime[700]}`,
      },
    },
  };

  const toastError = {
    "& .Toastify__toast-theme--light.Toastify__toast--error": {
      "& .Toastify__toast-body": {
        borderInlineStart: `0.6rem solid ${theme.mochshaColorPalette.eclipseCherry[700]}`,
      },
    },
  };

  const toastWarning = {
    "& .Toastify__toast-theme--light.Toastify__toast--warning": {
      "& .Toastify__toast-body": {
        borderInlineStart: `0.6rem solid ${theme.mochshaColorPalette.solarSquash[800]}`,
      },
    },
  };

  const toastInfo = {
    "& .Toastify__toast-theme--light.Toastify__toast--info": {
      "& .Toastify__toast-body": {
        borderInlineStart: `0.6rem solid ${theme.mochshaColorPalette.galacticGrape[500]}`,
      },
    },
  };

  return {
    ...toastBody,
    ...toastSuccess,
    ...toastError,
    ...toastWarning,
    ...toastInfo,
  };
});

export const CloseButton = styled(IconButton)(({ theme }) => ({
  paddingBlock: 0,

  [`&.${iconButtonClasses.root}`]: {
    color: theme.mochshaColorPalette.galacticGrape[700],

    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",

      "& svg": {
        color: theme.mochshaColorPalette.galacticGrape[900],
      },
    },
  },
}));

export const HoverBox = styled(Box)(({ theme }) => ({
  color: theme.mochshaColorPalette.galacticGrape[700],

  "&:hover": {
    color: theme.mochshaColorPalette.cosmicTangerine[900],
  },
}));

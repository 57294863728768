export function EmployeeRoundSmall(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 44 44"
      {...props}
    >
      <circle cx={22} cy={22} r={22} fill="#F5F5F5" />
      <g opacity={0.2}>
        <mask
          id="employeeCircularSmall_svg__a"
          width={44}
          height={44}
          x={0}
          y={0}
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
        >
          <circle cx={22} cy={22} r={22} fill="#F5F5F5" />
        </mask>
        <g fill="#000" mask="url(#employeeCircularSmall_svg__a)">
          <path d="M22 27.333A6.667 6.667 0 1 0 22 14a6.667 6.667 0 0 0 0 13.334ZM32 44a1.667 1.667 0 0 0 1.666-1.666 11.667 11.667 0 1 0-23.333 0A1.667 1.667 0 0 0 12 44h20Z" />
        </g>
      </g>
    </svg>
  );
}

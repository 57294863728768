import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomCard,
  CustomCardMedia,
  CustomCardActions,
  DateOverlay,
  AvatarContainer,
  UserInfo,
  CardBody,
  CardInfo,
} from "./Card.styled";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
import { Avatar, Stack, Chip, Tooltip } from "@mui/material";
import sampleImage from "../../assets/samples/card.jpg";
import categoryImages from "../../assets/categoryImages";
import { useUser } from "../../context/UserContext";

const Card = ({
  event_date,
  description,
  onSaveClick,
  onBidNowClick,
  organizer_name,
  organizer_avatar,
  title,
  budget_min,
  budget_max,
  category,
  id,
  language,
  genre,
  location,
  event_status,
  provider_data,
}) => {
  const navigate = useNavigate();
  const { role } = useUser();

  const handleLearnMoreClick = () => {
    if (role === "client") {
      navigate(`/provider/${id}`);
    } else {
      navigate(`/discover/${id}`);
    }
  };

  const handleBidNowClick = () => {
    if (onBidNowClick) onBidNowClick();
  };

  const categoryImage = categoryImages[category] || categoryImages.default;

  // Format date
  const formattedDate = event_date
    ? new Date(event_date).toLocaleDateString()
    : "";

  const renderProviderContent = () => {
    if (role === "client" && provider_data) {
      return (
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="body">Languages</Typography>
            <Tooltip
              title={
                provider_data.languages?.length > 3 ? (
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {provider_data.languages.map((lang, index) => (
                      <Stack key={index} padding="0.5rem">
                        <Chip label={lang} size="small" />
                      </Stack>
                    ))}
                  </Stack>
                ) : null
              }
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "white",
                    "& .MuiTooltip-arrow": {
                      color: "white",
                    },
                    boxShadow: 1,
                    padding: "1.2rem",
                    flexWrap: "wrap",
                    gap: "2rem",
                  },
                },
              }}
            >
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {provider_data.languages?.slice(0, 3).map((lang, index) => (
                  <Chip key={index} label={lang} size="small" />
                ))}
                {provider_data.languages?.length > 3 && (
                  <Chip
                    label={`+${provider_data.languages.length - 3}`}
                    size="small"
                  />
                )}
              </Stack>
            </Tooltip>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body">Experience</Typography>
            <Tooltip title={provider_data.bio} arrow placement="top">
              <Stack
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  variant="small"
                  fontWeight="bold"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {provider_data.bio}
                </Typography>
              </Stack>
            </Tooltip>
          </Stack>

          <Tooltip
            title={provider_data.profile?.location}
            arrow
            placement="top"
          >
            <Stack
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {provider_data.profile?.location}
            </Stack>
          </Tooltip>

          {provider_data.social_links ? (
            <Stack spacing={1}>
              <Typography variant="body">Social Links</Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {provider_data.social_links.map((link, index) => (
                  <Chip
                    key={index}
                    label={`Link ${index + 1}`}
                    component="a"
                    href={link}
                    target="_blank"
                    clickable
                    size="small"
                  />
                ))}
              </Stack>
            </Stack>
          ) : (
            <Stack spacing={1}>
              <Typography variant="body">Social Links</Typography>
              <Typography variant="small">No social links</Typography>
            </Stack>
          )}
        </Stack>
      );
    }

    // Return original content for non-client roles
    return (
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="body">Budget Range</Typography>
          <Typography variant="small" fontWeight="bold">
            ₹{budget_min} - ₹{budget_max}
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Tooltip title={location} arrow placement="top">
            <Stack
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {location}
            </Stack>
          </Tooltip>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {Array.isArray(language)
              ? language.map((lang, index) => (
                  <Chip key={index} label={lang} size="small" />
                ))
              : language && <Chip label={language} size="small" />}
          </Stack>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {Array.isArray(genre)
              ? genre.map((g, index) => (
                  <Chip key={index} label={g} size="small" variant="outlined" />
                ))
              : genre && <Chip label={genre} size="small" variant="outlined" />}
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <CustomCard>
      <CustomCardMedia
        image={role === "client" ? sampleImage : categoryImage || sampleImage}
        title="Card media"
      >
        {formattedDate && <DateOverlay>{formattedDate}</DateOverlay>}
        <AvatarContainer>
          <Avatar
            src={
              role === "client"
                ? `http://api.mochsha.in${provider_data?.profile?.profile_picture}`
                : organizer_avatar
            }
          />
          <CardInfo>
            <UserInfo>
              <Typography variant="body" color="white">
                {role === "client"
                  ? `${provider_data?.profile?.first_name} ${provider_data?.profile?.last_name}`
                  : organizer_name}
              </Typography>
              {event_status && (
                <Chip
                  size="small"
                  label={event_status}
                  color={event_status === "completed" ? "default" : "primary"}
                />
              )}
            </UserInfo>
            <Typography variant="body" color="white">
              {role === "client" ? provider_data?.title : title}
            </Typography>
          </CardInfo>
        </AvatarContainer>
      </CustomCardMedia>

      <CardBody>
        {renderProviderContent()}

        <CustomCardActions>
          {role === "client" && (
            <Button size="small" onClick={handleLearnMoreClick}>
              View Profile
            </Button>
          )}
          {role === "provider" && (
            <Button
              size="small"
              onClick={handleBidNowClick}
              disabled={event_status !== "active"}
            >
              Bid Now!
            </Button>
          )}
        </CustomCardActions>
      </CardBody>
    </CustomCard>
  );
};

export default Card;

import React, { useEffect } from "react";
import { ImageUploadField } from "../Forms/Fields/ImageUploadField";
import { Stack, Typography, Box, IconButton } from "@mui/material";
import { Button } from "../Button";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import { useFieldArray } from "react-hook-form";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faFacebook,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import {
  ResponsiveStack,
  ContentStack,
  FormBox,
  SocialLinkStack,
  PortfolioLinkStack,
} from "./PortfolioInput.styled";

export const PortfolioInput = ({ control, errors, watch, setValue }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "social_links",
  });

  const getSocialIcon = (link) => {
    const lowercaseLink = link?.toLowerCase();

    if (lowercaseLink?.includes("instagram")) return faInstagram;
    if (lowercaseLink?.includes("linkedin")) return faLinkedin;
    if (lowercaseLink?.includes("facebook")) return faFacebook;
    if (lowercaseLink?.includes("twitter")) return faTwitter;
    if (lowercaseLink?.includes("youtube")) return faYoutube;
    return faInstagram;
  };

  // const getSocialMediaName = (link) => {
  //   const lowercaseLink = link?.toLowerCase();

  //   if (lowercaseLink?.includes("instagram")) return "instagram";
  //   if (lowercaseLink?.includes("linkedin")) return "linkedin";
  //   if (lowercaseLink?.includes("facebook")) return "facebook";
  //   if (lowercaseLink?.includes("twitter")) return "twitter";
  //   if (lowercaseLink?.includes("youtube")) return "youtube";
  //   return "instagram";
  // };

  useEffect(() => {
    // Ensure there's always at least one skill field
    if (fields.length === 0) {
      append("");
    }
  }, [fields, append]);

  const handleAddSocialLink = () => {
    append("");
  };

  const handleRemoveSocialLink = (index) => {
    remove(index);
  };

  return (
    <ResponsiveStack>
      <ContentStack gap="2rem">
        <Typography variant="titlePage" fontWeight="bold">
          Showcase Your Talent
        </Typography>
        <Typography variant="subHeading">
          How would you like to showcase your work on your profile?
        </Typography>
      </ContentStack>
      <FormBox>
        <Stack spacing="2rem">
          {fields.map((field, index) => (
            <SocialLinkStack key={field.id}>
              <TextFieldV2
                control={control}
                name={`social_links.${index}`}
                label="Link up your socials"
                error={!!errors.social_links?.[index]}
                helperText={errors.social_links?.[index]?.message}
                placeholder="Enter Social Link"
                required
                {...(getSocialIcon(watch(`social_links.${index}`)) && {
                  slotProps: {
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={getSocialIcon(watch(`social_links.${index}`))}
                          />
                        </InputAdornment>
                      ),
                    },
                  },
                })}
              />
              {fields.length > 1 && (
                <IconButton
                  size="small"
                  onClick={() => handleRemoveSocialLink(index)}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </SocialLinkStack>
          ))}
          <Stack
            direction="row"
            spacing="1rem"
            className="proprofile-skills-buttons"
          >
            <Button size="small" onClick={handleAddSocialLink}>
              Add Social Link
            </Button>
          </Stack>

          <Stack spacing={2}>
            <Typography variant="bodyTiny" fontWeight="bold">
            Add google drive for any unpublished work you would like to share
            </Typography>
            <PortfolioLinkStack>
              <TextFieldV2
                control={control}
                name="drive_link"
                placeholder="Enter your portfolio link"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faInstagram} />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </PortfolioLinkStack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="bodyTiny" fontWeight="bold">
              Please upload your tech rider for this event.
            </Typography>

            <ImageUploadField name="tech_rider" control={control} />
          </Stack>
        </Stack>
      </FormBox>
    </ResponsiveStack>
  );
};

import React from "react";
import {
  FooterContainer,
  FooterContent,
  FooterSection,
  SocialIcons,
  FooterLink,
  FooterTypography,
  FooterLinksContainer,
  FooterBottom,
} from "./FooterSection.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Typography } from "../Typography/Typography";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const FooterContactAndQuickLinks = () => (
  <>
    <FooterSection>
      <FooterTypography variant="subHeading">Contact</FooterTypography>
      <FooterTypography variant="small">
        Email:{" "}
        <FooterLink href="mailto:support@mochsha.in">
          support@mochsha.in
        </FooterLink>
      </FooterTypography>

      <SocialIcons>
        <FooterLink
          href="https://www.linkedin.com/company/mochsha/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </FooterLink>
        <FooterLink
          href="https://www.instagram.com/mochsha.india"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </FooterLink>
        <FooterLink
          href="https://x.com/mochsha"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </FooterLink>
        <FooterLink
          href="https://youtube.com/@mochsha?si=KP61Xa7Xqv1C9GIK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </FooterLink>
      </SocialIcons>
      {/* <FooterTypography variant="small">
    Registered Address: 685, 5th main road, 6th cross, HAL 3rd stage,
    Bangalore, KA 560075
  </FooterTypography> */}
    </FooterSection>

    <FooterSection>
      <FooterTypography variant="subHeading">Quick Links</FooterTypography>
      <FooterLinksContainer display="flex" flexDirection="column">
        <FooterLink href="/about">
          <Typography variant="small">About Us</Typography>
        </FooterLink>
        <FooterLink href="/services">
          <Typography variant="small">Services</Typography>
        </FooterLink>
        <FooterLink href="/terms">
          <Typography variant="small">Terms & Conditions</Typography>
        </FooterLink>
        <FooterLink href="/privacy">
          <Typography variant="small">Privacy Policy</Typography>
        </FooterLink>
      </FooterLinksContainer>
    </FooterSection>
  </>
);
export const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FooterContainer>
      <FooterBottom>
        <FooterTypography variant="tiny">
          &copy; 2024 MOCHSHA, All rights reserved
        </FooterTypography>
        {isMobile ? (
          <FooterContent>
            <FooterContactAndQuickLinks />
          </FooterContent>
        ) : (
          <FooterContactAndQuickLinks />
        )}
      </FooterBottom>
    </FooterContainer>
  );
};

export const languageOptions = [
  {
    value: "english",
    label: "English",
  },
  {
    value: "kannada",
    label: "Kannada",
  },
  {
    value: "malayalam",
    label: "Malayalam",
  },
  {
    value: "tamil",
    label: "Tamil",
  },
  {
    value: "telugu",
    label: "Telugu",
  },
  {
    value: "hindi",
    label: "Hindi",
  },
  {
    value: "marathi",
    label: "Marathi",
  },
];

export const genreOptions = [
  {
    value: "rock",
    label: "Rock",
  },
  {
    value: "pop",
    label: "Pop",
  },
  {
    value: "hiphop",
    label: "Hip Hop",
  },
  {
    value: "rap",
    label: "Rap",
  },
  {
    value: "country",
    label: "Country",
  },
  {
    value: "classical",
    label: "Classical",
  },
  {
    value: "jazz",
    label: "Jazz",
  },
  {
    value: "blues",
    label: "Blues",
  },
  {
    value: "r&b",
    label: "R&B",
  },
  {
    value: "metal",
    label: "Metal",
  },
  {
    value: "reggae",
    label: "Reggae",
  },
  {
    value: "indian classical",
    label: "Indian Classical",
  },
  {
    value: "hindustani",
    label: "Hindustani",
  },
  {
    value: "carnatic",
    label: "Carnatic",
  },
  {
    value: "bollywood",
    label: "Bollywood",
  },
  {
    value: "folk",
    label: "Folk",
  },
  {
    value: "sufi",
    label: "Sufi",
  },
  {
    value: "ghazal",
    label: "Ghazal",
  },
];

export const jobCategoryOptions = [
  {
    value: "vocalist",
    label: "Vocalist",
  },
  {
    value: "instrumentalist",
    label: "Instrumentalist",
  },
];

export const vocalistCategoryOptions = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];

export const instrumentalistCategoryOptions = [
  {
    value: "guitar",
    label: "Guitar",
  },
  {
    value: "piano",
    label: "Piano",
  },
  {
    value: "drums",
    label: "Drums",
  },
  {
    value: "bass",
    label: "Bass",
  },
  {
    value: "violin",
    label: "Violin",
  },
  {
    value: "saxophone",
    label: "Saxophone",
  },
  {
    value: "flute",
    label: "Flute",
  },
  {
    value: "tabla",
    label: "Tabla",
  },
  {
    value: "dhol",
    label: "Dhol",
  },
  {
    value: "mrudangam",
    label: "Mrudangam",
  },
  {
    value: "sitar",
    label: "Sitar",
  },
  {
    value: "synthesizer",
    label: "Synthesizer",
  },
  {
    value: "nadaswaram",
    label: "Nadaswaram",
  },
];

export const isDisabled = (
  currentStepIndex,
  aboutWatch,
  aboutErrors,
  eventDetailsWatch,
  eventDetailsErrors,
  budgetWatch,
  budgetErrors
) => {
  const validationLogic = (watchFn, expectedLength, errors) => {
    return (
      Object.keys(watchFn()).length !== expectedLength ||
      Object.keys(errors).length > 0 ||
      !Object.values(watchFn()).every(
        (value) => value !== null && value !== undefined && value !== ""
      )
    );
  };

  if (currentStepIndex.toString() === "0") {
    return validationLogic(aboutWatch, 6, aboutErrors);
  }
  if (currentStepIndex.toString() === "1") {
    return validationLogic(eventDetailsWatch, 4, eventDetailsErrors);
  }
  if (currentStepIndex.toString() === "2") {
    if (budgetWatch().budget_range) {
      return (
        !budgetWatch().budget_range ||
        !Array.isArray(budgetWatch().budget_range) ||
        budgetWatch().budget_range.length !== 2 ||
        Object.keys(budgetErrors).length > 0
      );
    }
  }

  return false; // Default return value if no conditions match
};

import React from "react";
import { Stack, Box, styled } from "@mui/material";
import { AutocompleteField } from "../Forms/Fields/AutocompleteField/AutocompleteField";
import { Typography } from "../Typography";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import {
  jobCategoryOptions,
  vocalistCategoryOptions,
  instrumentalistCategoryOptions,
  languageOptions,
  genreOptions,
} from "./utils";

// Styled components
const ResponsiveContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  padding: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: theme.spacing(4),
  },
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  width: "30%",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const FormStack = styled(Stack)(({ theme }) => ({
  spacing: "4rem",
}));

const FieldGroup = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  spacing: "3rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

export const About = ({ control, errors, watch, setValue }) => {
  const onCategoryChange = (_, data) => {
    // setValue("subCategory", null);
  };

  const onSubCategoryChange = (_, data) => {};

  return (
    <ResponsiveContainer>
      <ContentStack>
        <Typography variant="titlePage" fontWeight="bold">
          Bring Your Event to Life!
        </Typography>
        <Typography variant="subHeading">Let's get started.</Typography>
      </ContentStack>

      <FormContainer>
        <FormStack spacing={4}>
          <TextFieldV2
            control={control}
            name="title"
            label="Lets start with a clear and catchy job title"
            error={!!errors.jobTitle}
            helperText={errors.jobTitle?.message}
            placeholder="Eg. Live Band/ Sound"
            required
            fullWidth
          />

          <TextFieldV2
            control={control}
            name="description"
            label="Describe the job and its requirements"
            error={!!errors.description}
            helperText={errors.description?.message}
            placeholder="Eg. Looking for a live Band/Sound Engineer vendor for our event"
            required
            multiline
            rows={4}
            fullWidth
          />

          <FieldGroup>
            <Stack
              gap={{ xs: 3, md: 1 }}
              direction={{ xs: "column", md: "row" }}
              width="100%"
            >
              <AutocompleteField
                multiple
                control={control}
                name="language"
                label="Language"
                error={!!errors.language}
                helperText={errors.language?.message}
                placeholder="Select Language"
                options={languageOptions}
                required
                fullWidth
              />
              <AutocompleteField
                multiple
                control={control}
                name="genre"
                label="Genre"
                error={!!errors.genre}
                helperText={errors.genre?.message}
                placeholder="Select Genre"
                options={genreOptions}
                required
                fullWidth
              />
            </Stack>
          </FieldGroup>

          <FieldGroup>
            <Stack
              gap={{ xs: 3, md: 1 }}
              direction={{ xs: "column", md: "row" }}
              width="100%"
            >
              <AutocompleteField
                control={control}
                name="category"
                label="Categorize your job"
                error={!!errors.category}
                helperText={errors.category?.message}
                placeholder="Select Category"
                onInputChange={onCategoryChange}
                options={jobCategoryOptions}
                required
                fullWidth
              />
              <AutocompleteField
                control={control}
                disabled={!watch("category")}
                name="subcategory"
                label="Choose a sub-category"
                onInputChange={onSubCategoryChange}
                error={!!errors.subCategory}
                helperText={errors.subCategory?.message}
                placeholder="Select Sub-Category"
                options={
                  watch().category === "vocalist"
                    ? vocalistCategoryOptions
                    : instrumentalistCategoryOptions
                }
                required
                fullWidth
              />
            </Stack>
          </FieldGroup>
        </FormStack>
      </FormContainer>
    </ResponsiveContainer>
  );
};

import React from "react";
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faSpotify,
  faSoundcloud,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import RemoveIcon from "@mui/icons-material/Remove";
import { toasts } from "../Toast/toasts";
import { Typography } from "../Typography";
import { BottomSheetDialog } from "../BottomSheetDialog";
import { Button } from "../Button";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import { ImageUploadField } from "../Forms/Fields/ImageUploadField";
import { useUser } from "../../context/UserContext";
import axios from "axios";

// Utility function to get social media icon
const getSocialIcon = (url = "") => {
  const lowercaseUrl = url.toLowerCase();
  if (lowercaseUrl.includes("facebook")) return faFacebookF;
  if (lowercaseUrl.includes("instagram")) return faInstagram;
  if (lowercaseUrl.includes("youtube")) return faYoutube;
  if (lowercaseUrl.includes("spotify")) return faSpotify;
  if (lowercaseUrl.includes("soundcloud")) return faSoundcloud;
  if (lowercaseUrl.includes("twitter")) return faTwitter;
  return faFile;
};

const getSocialMediaName = (link) => {
  const lowercaseLink = link?.toLowerCase();

  if (lowercaseLink?.includes("instagram")) return "instagram";
  if (lowercaseLink?.includes("linkedin")) return "linkedin";
  if (lowercaseLink?.includes("facebook")) return "facebook";
  if (lowercaseLink?.includes("twitter")) return "twitter";
  if (lowercaseLink?.includes("youtube")) return "youtube";
  return "instagram";
};

const profileSchema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  display_name: z.string().min(1, "Display name is required"),
  location: z.string().min(1, "Location is required"),
  phone_number: z
    .string()
    .regex(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .min(1, "Phone number is required"),
  gst_info: z
    .string()
    .regex(
      /^[0-9A-Z]{15}$/,
      "GST number must be 15 characters long with only capital letters and numbers"
    )
    .optional()
    .or(z.literal("")),
  social_links: z
    .array(
      z.object({
        link: z.string().min(1, "Social link is required"),
      })
    )
    .min(1, "At least one social link is required"),
  profilePicture: z.any().optional(),
});

export const ProfileDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  onClose,
  onSuccess,
  role,
}) => {
  const { userDetails } = useUser();

  const {
    control,
    handleSubmit: profileHandleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    watch,
  } = useForm({
    mode: "all",
    resolver: zodResolver(profileSchema),
    defaultValues: {
      first_name: userDetails?.profile?.first_name || "",
      last_name: userDetails?.profile?.last_name || "",
      display_name: userDetails?.profile?.display_name || "",
      location: userDetails?.profile?.location || "",
      phone_number: userDetails?.profile?.phone_number || "",
      gst_info: userDetails?.profile?.gst_info || "",
      social_links: userDetails?.profile?.social_links?.length
        ? userDetails.profile.social_links
        : [{ link: "" }],
      profilePicture: userDetails?.profile?.profile_picture
        ? "https://api.mochsha.in" + userDetails?.profile?.profile_picture
        : null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "social_links",
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["profile", "update"],
    mutationFn: async (data) => {
      const formData = new FormData();

      if (data.profilePicture instanceof File) {
        formData.append("profile_picture", data.profilePicture);
      }

      const { profilePicture, ...profileData } = data;
      formData.append("profile_data", JSON.stringify(profileData));

      if (userDetails?.profile?.profile_id) {
        // Update existing profile
        await axios.put(
          `https://api.mochsha.in/api/v1/profiles/${userDetails.profile.user_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        // Create new profile
        await axios.post("https://api.mochsha.in/api/v1/profiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        });
      }
    },
    onSuccess: () => {
      toasts.success({
        title: "Profile updated",
        description: "Your profile has been updated successfully",
      });
      setIsDialogOpen(false);
      reset();
      onSuccess?.();
    },
    onError: (error) => {
      toasts.error({
        title: "Something went wrong",
        description: error.response?.data?.message || "Please try again",
      });
    },
  });

  const handleAddSocialLink = (index) => {
    append({ [getSocialMediaName(watch(`social_links.${index}.link`))]: "" });
  };

  const handleRemoveSocialLink = (index) => {
    remove(index);
  };

  const onSubmit = (data) => {
    console.log("Form data:", { ...data, user_role: role });
    mutate({ ...data, user_role: role });
  };

  const dialogTitle = userDetails?.profile ? "Edit Profile" : "Create Profile";

  return (
    <BottomSheetDialog
      title={
        <Typography variant="heading" fontWeight="bold">
          {dialogTitle}
        </Typography>
      }
      open={isDialogOpen}
      onClose={() => {
        setIsDialogOpen(false);
        reset();
        onClose?.();
      }}
      actions={
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <Button
            autoFocus
            onClick={profileHandleSubmit(onSubmit)}
            disabled={isLoading || !isValid}
          >
            {isLoading ? "Saving..." : "Save Changes"}
          </Button>
        </Stack>
      }
    >
      <Stack spacing={{ xs: 3, md: 4 }}>
        <Stack spacing={{ xs: 1, md: 2 }}>
          <Typography variant="titlePage" fontWeight="bold">
            Tell Us About You
          </Typography>
          <Typography variant="body" fontWeight="regular">
            Add personal details to build trust with professionals.
          </Typography>
        </Stack>

        <Stack spacing={{ xs: 3, md: 4 }}>
          {/* Full Name Section */}
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Typography variant="bodyStrong" fontWeight="bold">
              Full Name
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 3 }}
            >
              <TextFieldV2
                control={control}
                placeholder="First Name"
                name="first_name"
                label="First Name"
                error={!!errors?.first_name}
                helperText={errors?.first_name?.message}
                required
                fullWidth
              />
              <TextFieldV2
                control={control}
                placeholder="Last Name"
                label="Last Name"
                name="last_name"
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                required
                fullWidth
              />
            </Stack>
          </Stack>

          {/* Display Name Section */}
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Typography variant="bodyStrong" fontWeight="bold">
              Display Name
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 3 }}
            >
              <TextFieldV2
                control={control}
                placeholder="Display Name"
                label="Display Name"
                name="display_name"
                error={!!errors.display_name}
                helperText={errors.display_name?.message}
                required
                fullWidth
              />
            </Stack>
          </Stack>

          {/* City Section */}
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Typography variant="bodyStrong" fontWeight="bold">
              Location
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 3 }}
            >
              <TextFieldV2
                control={control}
                name="location"
                label="City"
                error={!!errors.location}
                helperText={errors.location?.message}
                placeholder="Enter your city"
                required
                fullWidth
              />
            </Stack>
          </Stack>

          {/* Phone Number Section */}
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Typography variant="bodyStrong" fontWeight="bold">
              Phone Number
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 3 }}
            >
              <TextFieldV2
                control={control}
                name="phone_number"
                label="Phone Number"
                error={!!errors.phone_number}
                helperText={errors.phone_number?.message}
                placeholder="Enter your 10-digit phone number"
                required
                fullWidth
              />
            </Stack>
          </Stack>

          {/* GST Info Section */}
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Typography variant="bodyStrong" fontWeight="bold">
              GST Information
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 3 }}
            >
              <TextFieldV2
                control={control}
                name="gst_info"
                label="GST Number (Enter only if you are GST registered to claim relevant benefits)"
                error={!!errors.gst_info}
                helperText={errors.gst_info?.message}
                placeholder="Enter your GST number"
                fullWidth
              />
            </Stack>
          </Stack>

          {/* Social Links Section */}
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Typography variant="bodyStrong" fontWeight="bold">
              Social Links
            </Typography>
            <Stack spacing={2} width="100%">
              {fields.map((field, index) => (
                <Stack
                  key={field.id}
                  direction="row"
                  alignItems="flex-end"
                  spacing="2rem"
                >
                  <TextFieldV2
                    fullWidth
                    sx={{ width: "100%" }}
                    control={control}
                    name={`social_links.${index}.link`}
                    label={`Social Link ${index + 1}`}
                    error={!!errors.social_links?.[index]?.link}
                    helperText={errors.social_links?.[index]?.link?.message}
                    placeholder="Paste your social media link here"
                    required
                    {...(getSocialIcon(watch(`social_links.${index}.link`)) && {
                      slotProps: {
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon
                                icon={getSocialIcon(
                                  watch(`social_links.${index}.link`)
                                )}
                              />
                            </InputAdornment>
                          ),
                        },
                      },
                    })}
                  />
                  {fields.length > 1 && (
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveSocialLink(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Stack>
              ))}
              <Button
                onClick={handleAddSocialLink}
                sx={{ alignSelf: { xs: "stretch", sm: "flex-start" } }}
              >
                Add Social Link
              </Button>
              {errors.social_links &&
                typeof errors.social_links === "string" && (
                  <Typography color="error" variant="caption">
                    {errors.social_links}
                  </Typography>
                )}
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Typography variant="bodyTiny" fontWeight="bold">
              {userDetails?.profile?.profile_picture
                ? "Update your profile picture or keep the existing one"
                : "Please upload your profile picture. It's mandatory to upload your profile picture."}
            </Typography>

            <ImageUploadField name="profilePicture" control={control} />
          </Stack>
        </Stack>
      </Stack>
    </BottomSheetDialog>
  );
};

import { motion } from "framer-motion";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Avatar,
} from "@mui/material";

import { Button } from "../../Button";

export const ReviewBidDialog = ({
  open,
  onClose,
  initialData,
  onReviewBid,
  eventDetails,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const currentBid = initialData;
  const { galacticGrape, mochshaGreen } = theme.mochshaColorPalette;

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const contentVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.3 },
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          m: isMobile ? 0 : 2,
          maxHeight: isMobile ? "100%" : "90vh",
          bgcolor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle
        sx={{
          px: { xs: 2, sm: 3 },
          py: 2,
          position: "sticky",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h5" fontWeight="600">
          Review Bid
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          px: { xs: 2, sm: 3 },
          py: 3,
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
          },
        }}
      >
        <Stack
          spacing={3}
          component={motion.div}
          variants={contentVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Provider Section */}
          <Box
            component={motion.div}
            variants={cardVariants}
            sx={{
              p: 3,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: theme.shadows[3],
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: theme.shadows[6],
              },
              border: `1px solid ${theme.palette.divider}`,
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "4px",
                background: `linear-gradient(90deg, ${galacticGrape[500]}, ${mochshaGreen[500]})`,
              },
            }}
          >
            <Stack spacing={2.5}>
              {/* Profile Picture and Name Header */}
              <Box
                component={motion.div}
                variants={itemVariants}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Avatar
                  src={
                    "https://api.mochsha.in" +
                    currentBid.provider.profile.profile_picture
                  }
                  alt={currentBid.provider.profile.display_name}
                  sx={{
                    width: 80,
                    height: 80,
                    border: `2px solid ${galacticGrape[200]}`,
                    boxShadow: theme.shadows[2],
                  }}
                />
                <Box>
                  <Typography
                    variant={isMobile ? "h6" : "h5"}
                    component={motion.div}
                    variants={itemVariants}
                    sx={{ fontWeight: 600, color: galacticGrape[800] }}
                  >
                    {currentBid.provider.profile.display_name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={galacticGrape[600]}
                    sx={{ mt: 0.5 }}
                  >
                    Provider Details
                  </Typography>
                </Box>
              </Box>

              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Contact Information
                </Typography>
                <Stack spacing={0.5}>
                  <Typography variant="body1">
                    Email: {currentBid.provider.email}
                  </Typography>
                  <Typography variant="body1">
                    Phone: {currentBid.provider.profile.phone_number}
                  </Typography>
                  <Typography variant="body1">
                    Location: {currentBid.provider.profile.location}
                  </Typography>
                </Stack>
              </Box>

              {currentBid.provider.profile.social_links?.length > 0 && (
                <Box component={motion.div} variants={itemVariants}>
                  <Typography
                    variant="subtitle2"
                    color={galacticGrape[700]}
                    sx={{ mb: 0.5 }}
                  >
                    Social Links
                  </Typography>
                  <Stack spacing={1}>
                    {currentBid.provider.profile.social_links.map(
                      (social, index) => (
                        <Typography
                          key={index}
                          component="a"
                          href={social.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: galacticGrape[900],
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                              color: galacticGrape[700],
                            },
                            wordBreak: "break-all",
                            transition: "color 0.2s ease",
                          }}
                        >
                          {social.link}
                        </Typography>
                      )
                    )}
                  </Stack>
                </Box>
              )}

              {currentBid.provider.profile.gst_info && (
                <Box component={motion.div} variants={itemVariants}>
                  <Typography
                    variant="subtitle2"
                    color={galacticGrape[700]}
                    sx={{ mb: 0.5 }}
                  >
                    GST Information
                  </Typography>
                  <Typography variant="body1">
                    {currentBid.provider.profile.gst_info}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Event Summary Section */}
          <Box
            component={motion.div}
            variants={cardVariants}
            sx={{
              p: 3,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: theme.shadows[3],
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: theme.shadows[6],
              },
              border: `1px solid ${theme.palette.divider}`,
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "4px",
                background: `linear-gradient(90deg, ${mochshaGreen[500]}, ${galacticGrape[500]})`,
              },
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h5"}
              gutterBottom
              component={motion.div}
              variants={itemVariants}
              sx={{ fontWeight: 600, color: galacticGrape[800] }}
            >
              Event Summary
            </Typography>
            <Stack spacing={2.5}>
              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Event Title
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {eventDetails.title}
                </Typography>
              </Box>

              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Event Details
                </Typography>
                <Stack spacing={0.5}>
                  <Typography variant="body1">
                    Category: {eventDetails.category} ({eventDetails.subcategory})
                  </Typography>
                  <Typography variant="body1">
                    Genre: {eventDetails.genre}
                  </Typography>
                  <Typography variant="body1">
                    Language: {eventDetails.language}
                  </Typography>
                </Stack>
              </Box>

              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Date & Time
                </Typography>
                <Stack spacing={0.5}>
                  <Typography variant="body1">
                    Date:{" "}
                    {new Date(eventDetails.event_date).toLocaleDateString(
                      "en-IN",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                  </Typography>
                  <Typography variant="body1">
                    Time: {eventDetails.start_time.slice(0, 5)} -{" "}
                    {eventDetails.end_time.slice(0, 5)}
                  </Typography>
                </Stack>
              </Box>

              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Budget Range
                </Typography>
                <Typography variant="body1">
                  ₹{Number(eventDetails.budget_min).toLocaleString("en-IN")} - ₹
                  {Number(eventDetails.budget_max).toLocaleString("en-IN")}
                </Typography>
              </Box>

              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Location
                </Typography>
                <Typography variant="body1">{eventDetails.location}</Typography>
              </Box>

              {eventDetails.description && (
                <Box component={motion.div} variants={itemVariants}>
                  <Typography
                    variant="subtitle2"
                    color={galacticGrape[700]}
                    sx={{ mb: 0.5 }}
                  >
                    Description
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: "pre-wrap",
                      bgcolor: theme.palette.background.default,
                      p: 2,
                      borderRadius: 1,
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {eventDetails.description}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Bid Section */}
          <Box
            component={motion.div}
            variants={cardVariants}
            sx={{
              p: 3,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: theme.shadows[3],
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: theme.shadows[6],
              },
              border: `1px solid ${theme.palette.divider}`,
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "4px",
                background: `linear-gradient(90deg, ${mochshaGreen[500]}, ${galacticGrape[500]})`,
              },
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h5"}
              gutterBottom
              component={motion.div}
              variants={itemVariants}
              sx={{ fontWeight: 600, color: galacticGrape[800] }}
            >
              Bid Details
            </Typography>
            <Stack spacing={2.5}>
              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Bid Amount
                </Typography>
                <Typography
                  variant="h5"
                  color={galacticGrape[900]}
                  sx={{ fontWeight: 600 }}
                >
                  ₹{currentBid.amount}
                </Typography>
              </Box>

              {currentBid.proposal && (
                <Box component={motion.div} variants={itemVariants}>
                  <Typography
                    variant="subtitle2"
                    color={galacticGrape[700]}
                    sx={{ mb: 0.5 }}
                  >
                    Proposal
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: "pre-wrap",
                      bgcolor: theme.palette.background.default,
                      p: 2,
                      borderRadius: 1,
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {currentBid.proposal}
                  </Typography>
                </Box>
              )}

              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Status
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textTransform: "capitalize",
                    color:
                      currentBid.status === "pending"
                        ? "warning.main"
                        : "success.main",
                    fontWeight: 500,
                  }}
                >
                  {currentBid.status}
                </Typography>
              </Box>

              <Box component={motion.div} variants={itemVariants}>
                <Typography
                  variant="subtitle2"
                  color={galacticGrape[700]}
                  sx={{ mb: 0.5 }}
                >
                  Submitted On
                </Typography>
                <Typography variant="body1">
                  {new Date(currentBid.created_at).toLocaleString("en-IN", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          px: { xs: 2, sm: 3 },
          py: 2,
          position: "sticky",
          bottom: 0,
          bgcolor: "background.paper",
          borderTop: 1,
          borderColor: "divider",
          flexDirection: isMobile ? "column" : "row",
          "& > button": {
            m: isMobile ? 0.5 : 1,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Button onClick={onClose} fullWidth={isMobile} variant="secondary">
            Close
          </Button>
          <Button onClick={() => onReviewBid(currentBid)} fullWidth={isMobile}>
            Accept Bid
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
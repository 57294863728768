import { styled } from "@mui/material/styles";
import { Alert } from "@mui/material";

export const AlertStyled = styled(Alert)(({ theme }) => {
  const {
    solarSquash,
    orionBerry,
    eclipseCherry,
    auroraLime,
    galacticGrape,
    electricViolet,
  } = theme.mochshaColorPalette;

  const solarSquashStyles = {
    "&.MuiAlert-outlinedSolarSquash": {
      backgroundColor: solarSquash[400],

      "& .MuiTypography-subHeader2": {
        color: solarSquash[900],
      },

      "& .alertIcon": {
        color: solarSquash[900],
      },
    },
  };

  const orionBerryStyles = {
    "&.MuiAlert-outlinedOrionBerry": {
      backgroundColor: orionBerry[400],

      "& .MuiTypography-subHeader2": {
        color: orionBerry[800],
      },

      "& .alertIcon": {
        color: orionBerry[800],
      },
    },
  };

  const eclipseCherryStyles = {
    "&.MuiAlert-outlinedEclipseCherry": {
      backgroundColor: eclipseCherry[400],

      "& .MuiTypography-subHeader2": {
        color: eclipseCherry[700],
      },

      "& .alertIcon": {
        color: eclipseCherry[700],
      },
    },
  };

  const auroraLimeStyles = {
    "&.MuiAlert-outlinedAuroraLime": {
      backgroundColor: auroraLime[400],

      "& .MuiTypography-subHeader2": {
        color: auroraLime[800],
      },

      "& .alertIcon": {
        color: auroraLime[800],
      },
    },
  };

  const galacticGrapeStyles = {
    "&.MuiAlert-outlinedGalacticGrape": {
      backgroundColor: galacticGrape[300],

      "& .MuiTypography-subHeader2": {
        color: galacticGrape[900],
      },

      "& .alertIcon": {
        color: galacticGrape[900],
      },
    },
  };

  const electricVioletStyles = {
    "&.MuiAlert-outlinedElectricViolet": {
      backgroundColor: electricViolet[400],

      "& .MuiTypography-subHeader2": {
        color: electricViolet[900],
      },

      "& .alertIcon": {
        color: electricViolet[900],
      },
    },
  };

  return {
    borderRadius: "8px",
    padding: "1.2rem",
    flexDirection: "column",

    "& .MuiAlert-message": {
      padding: 0,
    },

    ...solarSquashStyles,
    ...orionBerryStyles,
    ...eclipseCherryStyles,
    ...auroraLimeStyles,
    ...galacticGrapeStyles,
    ...electricVioletStyles,
  };
});

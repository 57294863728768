import { forwardRef } from "react";
import { TextFieldStyled } from "../TextInputV2";

export const CommonDatePickerTextFieldV2 = forwardRef(
  ({ onClick, error, inputRef, id, placeholder, ...params }, ref) => {

    return (
      <TextFieldStyled
        {...params}
        error={!!error}
        onClick={onClick}
        id={id ? `${id}-datepicker` : ""}
        ref={inputRef ?? ref}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          ...params.inputProps,
          placeholder: placeholder || "Choose a date",
        }}
      />
    );
  }
);

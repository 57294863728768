import { styled } from "@mui/material/styles";
import { Check, Minus } from "iconoir-react";

export const BoxStyled = styled("span")(({ theme }) => {
  const { galacticGrape, cosmicTangerine } = theme.mochshaColorPalette;

  const style = {
    borderRadius: "2px",
    width: "1.6rem",
    height: "1.6rem",
    backgroundColor: galacticGrape[200],
    border: `1.5px solid ${galacticGrape[700]}`,

    "input:hover ~ &": {
      borderColor: cosmicTangerine[800],
    },

    "input:disabled ~ &": {
      background: galacticGrape[400],
      borderColor: galacticGrape[500],
    },
  };

  return style;
});

export const CheckStyled = styled(Check)(({ theme }) => {
  const { cosmicTangerine } = theme.mochshaColorPalette;

  const style = {
    border: `1.5px solid ${cosmicTangerine[900]}`,
    borderRadius: "2px",
    color: cosmicTangerine[900],
    backgroundColor: cosmicTangerine[600],
    width: "1.6rem",
    height: "1.6rem",

    "input:disabled ~ &": {
      backgroundColor: cosmicTangerine[500],
      borderColor: cosmicTangerine[600],
    },
  };

  return style;
});

export const IndeterminateStyled = styled(Minus)(({ theme }) => {
  const { cosmicTangerine } = theme.mochshaColorPalette;

  const style = {
    border: `1.5px solid ${cosmicTangerine[900]}`,
    borderRadius: "2px",
    color: cosmicTangerine[900],
    backgroundColor: cosmicTangerine[600],
    width: "1.6rem",
    height: "1.6rem",

    "input:disabled ~ &": {
      backgroundColor: cosmicTangerine[500],
      borderColor: cosmicTangerine[600],
    },
  };

  return style;
});

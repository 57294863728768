import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faClipboard,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <h2>Dashboard</h2>
      <div className="dashboard-container">
        <div className="dashboard-item">
          <FontAwesomeIcon icon={faClock} className="dashboard-icon" />
          <div className="dashboard-number">5</div>
          <div className="dashboard-label">Upcoming Shows</div>
        </div>
        <div className="dashboard-item">
          <FontAwesomeIcon icon={faClipboard} className="dashboard-icon" />
          <div className="dashboard-number">12</div>
          <div className="dashboard-label">Active Bids</div>
        </div>
        <div className="dashboard-item">
          <FontAwesomeIcon icon={faCheckSquare} className="dashboard-icon" />
          <div className="dashboard-number">8</div>
          <div className="dashboard-label">Completed</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

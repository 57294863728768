import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const StatsGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Card = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(3),
  height: 180,
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  background: "#ffffff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  transition: "all 0.3s ease",
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 25px rgba(0, 0, 0, 0.1)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "4px",
    background: "inherit",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 56,
  height: 56,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "16px",
  fontSize: 24,
  marginBottom: theme.spacing(1),
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.5),
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
  fontWeight: 500,
}));

export const DashboardSection = ({ dashboard }) => {
  const stats = [
    {
      id: 1,
      label: "Active Events",
      value: dashboard.activeProjects,
      icon: <WorkIcon sx={{ fontSize: 28 }} />,
      color: "#2196F3", // Blue
    },
    {
      id: 2,
      label: "Completed Events",
      value: dashboard.completedProjects,
      icon: <AssignmentTurnedInIcon sx={{ fontSize: 28 }} />,
      color: "#4CAF50", // Green
    },
    {
      id: 3,
      label: "Team Members",
      value: dashboard.teamMembers,
      icon: <PeopleIcon sx={{ fontSize: 28 }} />,
      color: "#9C27B0", // Purple
    },
  ];

  return (
    <DashboardContainer>
      <StatsGrid container spacing={3}>
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={4} key={stat.id}>
            <Card
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              style={{ borderTop: `4px solid ${stat.color}` }}
            >
              <IconWrapper
                style={{
                  backgroundColor: `${stat.color}15`,
                  color: stat.color,
                }}
              >
                {stat.icon}
              </IconWrapper>
              <StatValue>{stat.value}</StatValue>
              <StatLabel>{stat.label}</StatLabel>
            </Card>
          </Grid>
        ))}
      </StatsGrid>
    </DashboardContainer>
  );
};

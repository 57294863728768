import { toast as reactToast } from "react-toastify";
import { Toast } from "./Toast";

function createToast({ title, description, href, linkText }, type, options) {
  return reactToast(
    <Toast
      title={title}
      description={description}
      href={href}
      linkText={linkText}
      type={type}
    />,
    { ...options, icon: false, type }
  );
}

export const toasts = {
  info: (toast, options) => createToast(toast, "info", options),
  success: (toast, options) => createToast(toast, "success", options),
  warning: (toast, options) => createToast(toast, "warning", options),
  error: (toast, options) => createToast(toast, "error", options),
};

const initialState = {
  musicianData: {
    firstName: "",
    lastName: "",
    displayName: "",
    title: "",
    location: {
      state: "",
      city: "",
    },
    profilePicture: "",
    bio: "",
    skills: [],
    genres: [],
    ratings: [],
    media: [],
    rates: {},
  },
};

const musicianReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_MUSICIAN":
      if (action.payload.name.startsWith("location.")) {
        const locationKey = action.payload.name.split(".")[1];
        return {
          ...state,
          musicianData: {
            ...state.musicianData,
            location: {
              ...state.musicianData.location,
              [locationKey]: action.payload.value,
            },
          },
        };
      }
      return {
        ...state,
        musicianData: {
          ...state.musicianData,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_PROFILE_PICTURE":
      return {
        ...state,
        musicianData: {
          ...state.musicianData,
          profilePicture: action.payload,
        },
      };
    default:
      return state;
  }
};

export default musicianReducer;


import { Xmark } from 'iconoir-react';
import { AutocompleteChipStyled } from './AutocompleteChip.styled';
import { IconoirIcon } from '../../IconoirIcon';

export const AutocompleteChip = ({ label, ...props }) => {
  return (
    <AutocompleteChipStyled
      deleteIcon={<IconoirIcon icon={Xmark} />}
      label={label}
      {...props}
    />
  );
};

import { styled } from "@mui/system";
import { FormControlLabel as MuiFormControlLabel } from "@mui/material";

export const FormControlLabel = styled(MuiFormControlLabel)(({ isSelected, theme }) => {
  const { cosmicTangerine, galacticGrape } = theme.mochshaColorPalette;

  return {
    padding: "1.2rem",
    border: "solid 1px",
    borderColor: isSelected ? cosmicTangerine[900] : galacticGrape[500],
    backgroundColor: isSelected ? cosmicTangerine[400] : "inherit",
    borderRadius: "8px",

    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  };
});

import { Checkbox as MuiCheckbox } from "@mui/material";

import { BoxStyled, CheckStyled, IndeterminateStyled } from "./Checkbox.styled";

export function Checkbox({ size = "small", dataCy, ...props }) {

  return (
    <MuiCheckbox
      icon={<BoxStyled />}
      checkedIcon={<CheckStyled strokeWidth={3} />}
      indeterminateIcon={<IndeterminateStyled strokeWidth={3} />}
      size={size}
      disableRipple
      {...props}
    />
  );
}

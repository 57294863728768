import { Calendar } from "iconoir-react";
import { DateTime } from "luxon";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { forwardRef, useState } from "react";
import { Stack } from "@mui/material";

import { DatePickerTextFieldV2 } from "./DatePickerTextFieldV2";
import { InputLabel } from "../InputLabel";

export const defaultDateFormat = "dd-MM-yyyy";

export const DatePickerInputV2 = forwardRef(
  (
    {
      value,
      label,
      error,
      placeholder,
      dateFormat,
      helperText,
      size,
      disabled,
      labelContext,
      id,
      required,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const dateValue =
      value && typeof value === "string" ? DateTime.fromISO(value) : value;

    return (
      <Stack gap="0.8rem">
        <InputLabel
          htmlFor={`${id}-datepicker`}
          label={label}
          disabled={disabled}
          labelContext={labelContext}
          required={required}
        />

        <DesktopDatePicker
          format={dateFormat || defaultDateFormat}
          value={dateValue ?? null}
          ref={ref}
          open={open}
          disabled={disabled}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slots={{
            textField: DatePickerTextFieldV2,
            openPickerIcon: Calendar,
          }}
          slotProps={{
            openPickerButton: {
              disableRipple: true,
            },
            openPickerIcon: {
              width: "2rem",
              height: "2rem",
            },
            textField: {
              size,
              placeholder,
              helperText,
              error,
              disabled,
              id,
              onClick: () => setOpen(true),
            },
          }}
          {...rest}
        />
      </Stack>
    );
  }
);

DatePickerInputV2.displayName = "DatePickerInput";

import React from "react";
import { Stack, Typography, Paper } from "@mui/material";
import { Button } from "../../components/Button";
import { styled, useTheme } from "@mui/material/styles";

const BidSectionCardWrapper = styled(Paper)({
  height: "100%",
  backgroundColor: "#fff",
  textAlign: "left",
});

export function BidSectionCard({ onBidNow, budget, venue, date, time }) {
  const theme = useTheme();
  const { galacticGrape } = theme.mochshaColorPalette;

  return (
    <BidSectionCardWrapper elevation={3}>
      <Stack spacing="1rem" height="100%" padding="2rem">
        <Typography variant="heading">Submit your proposal!</Typography>
        <Stack>
          <Typography
            variant="heading"
            fontWeight="bold"
            color={galacticGrape[900]}
          >
            ₹{budget.min} - ₹{budget.max}
          </Typography>
          <Typography variant="bodySmall" color={galacticGrape[600]}>
            Budget
          </Typography>
        </Stack>

        <Stack spacing="1rem">
          <Stack>
            <Typography variant="bodySmallStrong" color={galacticGrape[900]}>
              Venue
            </Typography>
            <Typography variant="bodySmall" color={galacticGrape[700]}>
              {venue}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="bodySmallStrong" color={galacticGrape[900]}>
              Date and Time
            </Typography>
            <Typography variant="bodySmall" color={galacticGrape[700]}>
              {date}, {time.start} - {time.end}
            </Typography>
          </Stack>
        </Stack>
        <Button color="primary" onClick={onBidNow}>
          Bid Now
        </Button>
      </Stack>
    </BidSectionCardWrapper>
  );
}

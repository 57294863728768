import React, { useRef, useState } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { Controller } from "react-hook-form";
import { Stack } from "@mui/material";
import { TextFieldStyled } from "../../components/Forms/Inputs/TextInputV2/TextInputV2.styled";
import { InputLabel } from "../../components/Forms/Inputs/InputLabel";

const StandaloneSearch = ({
  onSearchFinish,
  control,
  name,
  label,
  placeholder,
  required,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBBhGizRxVExBmzAk41l-rRwvQy4CnfX5Q",
    libraries: ["places"],
  });

  const searchBox = useRef(null);

  const onSearchBoxLoad = (ref) => {
    searchBox.current = ref;
  };

  const [map, setMap] = useState(null);

  const onPlacesChanged = () => {
    const location = searchBox.current.getPlaces()[0].geometry.location;
    const bounds = new window.google.maps.LatLngBounds(location.toJSON());
    map?.fitBounds(bounds);

    const address = searchBox.current.getPlaces()[0].formatted_address;
    onSearchFinish(address);
  };

  return (
    <>
      {isLoaded && (
        <StandaloneSearchBox
          onLoad={onSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}
          style={{
            zIndex: 1000000,
          }}
        >
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Stack gap={1}>
                <InputLabel
                  htmlFor={`${name}input`}
                  label={label}
                  required={required}
                />
                <TextFieldStyled
                  {...field}
                  placeholder={placeholder}
                  type="text"
                  style={{
                    width: "100%",
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "3.6rem",
                    },
                  }}
                  reducesize={true}
                />
              </Stack>
            )}
          />
        </StandaloneSearchBox>
      )}
    </>
  );
};

export default StandaloneSearch;

import React from "react";
import { Box, Typography } from "@mui/material";

const IssuesSection = ({ control, issues, setIssues }) => {
  return (
    <Box mt={4}>
      <Typography variant="h6" gutterBottom>
        Reach out to us at{" "}
        <a href="mailto:support@mochsha.in">support@mochsha.in</a>
      </Typography>
      {/* ... commented out code ... */}
    </Box>
  );
};

export default IssuesSection;

export function SuccessIcon() {
  // Animation style for the circle
  const circleAnimationStyle = {
    strokeDasharray: 1000,
    strokeDashoffset: 1000,
    animation: "drawCircle 3s linear forwards", // 3s for the circle animation
  };

  // Animation style for the checkmark, starting after the circle finishes
  const checkmarkAnimationStyle = {
    strokeDasharray: 1000,
    strokeDashoffset: 1000,
    animation: "drawCheckmark 3s linear 0.5s forwards", // Delay of 3s, same as the circle duration
  };

  // Define keyframes for the animations
  const keyframes = `
      @keyframes drawCircle {
        to {
          stroke-dashoffset: 0;
        }
      }
      @keyframes drawCheckmark {
        to {
          stroke-dashoffset: 0;
        }
      }
    `;

  return (
    <>
      <style>{keyframes}</style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="81"
        viewBox="0 0 80 81"
        fill="none"
      >
        <path
          d="M69.2551 38.8141C69.4866 44.1856 68.2439 49.6832 65.3589 54.6801C57.2786 68.6757 39.3546 73.4784 25.3589 65.398C11.3633 57.3177 6.56059 39.3937 14.641 25.398C22.7213 11.4024 40.6453 6.5997 54.641 14.6801"
          stroke="#161616"
          strokeWidth="4"
          strokeLinecap="round"
          style={circleAnimationStyle}
        />
        <path
          d="M27.7013 38.8662L38.8285 49.9934L67.232 21.5898"
          stroke="#161616"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={checkmarkAnimationStyle}
        />
      </svg>
    </>
  );
}

import { Chip, chipClasses, styled } from "@mui/material";

export const AutocompleteChipStyled = styled(Chip)(({ theme }) => {
  const { galacticGrape, cosmicTangerine } =
    theme.mochshaColorPalette;

  const chipStyles = {
    borderRadius: "4px",
    gap: "0.4rem",
    color: galacticGrape[900],
    fontSize: "1.3rem",
    lineHeight: "2rem",
    backgroundColor: galacticGrape[400],
    height: "2.5rem",
  };

  const chipLabelStyles = {
    [`& .${chipClasses.label}`]: {
      padding: "0.4rem 0.4rem 0.4rem 0.8rem",
      gap: "0.4rem",
    },
  };

  const deleteIconStyles = {
    [`& .${chipClasses.deleteIcon}`]: {
      color: galacticGrape[900],

      "&:hover": {
        color: cosmicTangerine[900],
      },
    },
  };

  return { ...chipStyles, ...chipLabelStyles, ...deleteIconStyles };
});

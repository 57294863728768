import { Box, Radio, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconoirIcon } from "../../../IconoirIcon";
import { Typography } from "../../../Typography";
import { FormControlLabel } from "./RadioCardInput.styled";
import { CheckCircle, CheckCircleSolid } from "iconoir-react";

export function RadioCardInput({
  id,
  icon,
  title,
  description,
  isSelected,
  dataCy,
  dataPTag,
}) {
  const theme = useTheme();

  const selectedColor = isSelected
    ? theme.mochshaColorPalette.cosmicTangerine[900]
    : theme.mochshaColorPalette.galacticGrape[600];

  return (
    <FormControlLabel
      isSelected={isSelected}
      data-cy={dataCy}
      data-ptag={dataPTag}
      value={id}
      control={<Radio sx={{ display: "none",  }} />}
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "1.2rem",
          }}
        >
          <IconoirIcon
            icon={isSelected ? CheckCircleSolid : CheckCircle}
            width="2rem"
            height="2rem"
            color={isSelected ? theme.mochshaColorPalette.cosmicTangerine[900] : theme.mochshaColorPalette.galacticGrape[600]}
          />

          <Stack spacing="1.5rem">
            <Typography
              variant="body"
              fontWeight="medium"
              color={
                isSelected
                  ? theme.mochshaColorPalette.galacticGrape[900]
                  : theme.mochshaColorPalette.galacticGrape[600]
              }
            >
              {title}
            </Typography>

            {description && (
              <Typography
                variant="subText"
                color={
                  isSelected
                    ? theme.mochshaColorPalette.galacticGrape[900]
                    : theme.mochshaColorPalette.galacticGrape[600]
                }
              >
                {description}
              </Typography>
            )}
          </Stack>
        </Box>
      }
    />
  );
}

import React, { useLayoutEffect } from "react";
import { useTheme } from "@mui/material";
import { Otp } from "../../components/Onboarding/Otp";
const OtpPage = () => {
  const theme = useTheme();
  useLayoutEffect(() => {
    document.body.style.backgroundColor =
      theme.mochshaColorPalette.mistyMint[900];
  });
  return (
    <div className="otp-page">
      <Otp />
    </div>
  );
};

export default OtpPage;

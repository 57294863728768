export const jobData = [
  {
    name: "Hashir",
    rating: 3.6,
    role: "Venue Owner",
    categories: ["Live Music", "Vocalist"],
    budget: { min: 5000, max: 10000 },
    date: "June 24th, June 25th",
    location: "Gilly's, Koramangala, Bangalore, KA",
    description:
      "We are seeking a talented vocalist to perform at our upcoming corporate party. The event will be held at abc hall on 06/05/24 and 07/05/24, and we expect an audience of approximately 250. The ideal candidate will have experience performing at corporate or private events, with a repertoire that suits a diverse audience.",
    title: "Need Vocalist for Corporate Party",
    yourBid: 4000,
    budgetDisplay: 5300,
    endDate: "06/05/24",
  },
  {
    name: "Priya",
    rating: 4.2,
    role: "Event Planner",
    categories: ["DJ", "Electronic Music"],
    budget: { min: 15000, max: 25000 },
    date: "July 15th",
    location: "Blue Frog, Lower Parel, Mumbai, MH",
    description:
      "Looking for an experienced DJ for a high-energy club night. The event is part of our summer series and we expect a full house. The ideal candidate should be able to read the crowd and mix various electronic music genres.",
    title: "Experienced DJ Needed for Club Night",
    yourBid: 15000,
    budgetDisplay: 19500,
    endDate: "07/15/24",
  },
  {
    name: "Rahul",
    rating: 3.9,
    role: "Restaurant Manager",
    categories: ["Instrumental", "Jazz"],
    budget: { min: 8000, max: 12000 },
    date: "August 5th, August 6th, August 7th",
    location: "The Piano Man Jazz Club, Safdarjung, New Delhi, DL",
    description:
      "We're looking for a jazz trio (piano, bass, drums) for a three-night residency at our jazz club. The ideal group should have a repertoire of jazz standards and original compositions. This is a great opportunity for exposure in the Delhi jazz scene.",
    title: "Jazz Trio for Weekend Residency",
    yourBid: 10000,
    budgetDisplay: 13000,
    endDate: "08/05/24",
  },
  {
    name: "Ananya",
    rating: 4.7,
    role: "Wedding Planner",
    categories: ["Classical", "Carnatic"],
    budget: { min: 20000, max: 30000 },
    date: "September 3rd",
    location: "Taj Coromandel, Nungambakkam, Chennai, TN",
    description:
      "Seeking a renowned Carnatic vocalist for a high-profile wedding ceremony. The performance will be during the muhurtham and should include traditional wedding songs. The artist should be comfortable performing for about 2 hours.",
    title: "Carnatic Vocalist for Traditional Wedding",
    yourBid: 20000,
    budgetDisplay: 26000,
    endDate: "09/03/24",
  },
  {
    name: "Vikram",
    rating: 4.1,
    role: "Festival Organizer",
    categories: ["Rock", "Indie"],
    budget: { min: 50000, max: 100000 },
    date: "October 15th, October 16th",
    location: "Bacardi NH7 Weekender, Pune, MH",
    description:
      "We're looking for emerging indie rock bands to perform at our upcoming music festival. This is a great platform for exposure to a large audience. Bands should have at least one album or EP released and be comfortable performing a 45-minute set.",
    title: "Indie Rock Bands for Music Festival",
    yourBid: 50000,
    budgetDisplay: 65000,
    endDate: "10/15/24",
  },
];
export const pastBidsData = [
  {
    name: "Arjun",
    rating: 4.5,
    role: "Event Manager",
    categories: ["Rock", "Band"],
    budget: { min: 30000, max: 50000 },
    date: "May 15th",
    location: "Hard Rock Cafe, Worli, Mumbai, MH",
    description:
      "We needed a rock band for our restaurant's anniversary celebration. The event was a huge success!",
    title: "Rock Band for Restaurant Anniversary",
    yourBid: 40000,
    budgetDisplay: 45000,
    status: "Completed",
  },
  // ... add more past bids data
];

export const bidRequestsData = [
  {
    name: "Neha",
    rating: 4.2,
    role: "Corporate Event Planner",
    categories: ["Classical", "Instrumental"],
    budget: { min: 20000, max: 30000 },
    date: "August 10th",
    location: "Taj Bengal, Kolkata, WB",
    description:
      "Looking for a classical instrumentalist for a corporate dinner event. Preference for sitar or sarod players.",
    title: "Classical Instrumentalist for Corporate Dinner",
    suggestedBid: 25000,
    budgetDisplay: 28000,
    deadline: "July 25th",
    bidSection: {
      budget: { min: 20000, max: 30000 },
      venue: "Taj Bengal, Kolkata",
      date: "August 10th, 2024",
      time: "7:00 PM - 9:00 PM",
    },
  },
  {
    name: "Raj",
    rating: 4.5,
    role: "Wedding Planner",
    categories: ["Bollywood", "Live Band"],
    budget: { min: 50000, max: 80000 },
    date: "September 15th",
    location: "JW Marriott, Juhu, Mumbai, MH",
    description:
      "Seeking a versatile live band for a high-profile wedding reception. Should be able to perform a mix of Bollywood and Western songs.",
    title: "Live Band for Celebrity Wedding Reception",
    suggestedBid: 65000,
    budgetDisplay: 70000,
    deadline: "August 20th",
    bidSection: {
      budget: { min: 50000, max: 80000 },
      venue: "JW Marriott, Juhu, Mumbai",
      date: "September 15th, 2024",
      time: "8:00 PM - 12:00 AM",
    },
  },
];

export const draftsData = [
  {
    name: "Karthik",
    rating: 3.8,
    role: "Club Owner",
    categories: ["EDM", "DJ"],
    budget: { min: 40000, max: 60000 },
    date: "September 5th",
    location: "Skyye, UB City, Bangalore, KA",
    description:
      "Need an EDM DJ for our club's reopening night. Looking for someone who can keep the energy high all night.",
    title: "EDM DJ for Club Reopening",
    draftBid: 50000,
    budgetDisplay: 55000,
    lastEdited: "June 30th",
  },
];

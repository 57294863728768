import React from "react";
import { Stack, Typography, Slider } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Controller } from "react-hook-form";

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  gap: "10rem",
  width: "100%",
  alignItems: "flex-start",
  [theme.breakpoints.down("md")]: {
    gap: "5rem",
    flexDirection: "column",
  },
}));

const ContentSection = styled(Stack)(({ theme }) => ({
  width: "30%",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const SliderSection = styled(Stack)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const Budget = ({ control, errors, watch }) => {
  const theme = useTheme();
  const { galacticGrape } = theme.mochshaColorPalette;

  return (
    <ResponsiveStack direction="row" justifyContent="space-between">
      <ContentSection>
        <Typography variant="titlePage" fontWeight="bold">
          Outline Your Budget!
        </Typography>
        <Typography variant="subHeading">
          Set a budget to find the best talent within your range.
        </Typography>
      </ContentSection>

      <SliderSection>
        <Typography
          variant="bodyTiny"
          color={galacticGrape[700]}
          fontWeight="bold"
        >
          Select your budget range
        </Typography>

        <Controller
          name="budget_range"
          control={control}
          defaultValue={[1000, 5000]}
          render={({ field }) => (
            <>
              <Slider
                {...field}
                valueLabelDisplay="auto"
                min={0}
                max={1000000}
                step={500}
                marks={[
                  { value: 0, label: "₹0" },
                  { value: 1000000, label: "₹10,00,000" },
                ]}
                aria-label="Budget Range"
                sx={{
                  color: galacticGrape[500],
                  "& .MuiSlider-thumb": {
                    backgroundColor: galacticGrape[700],
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: galacticGrape[900],
                  },
                  "& .MuiSlider-mark": {
                    backgroundColor: galacticGrape[500],
                  },
                }}
              />
              {errors.budget_range && (
                <Typography color="error" variant="caption">
                  {errors.budget_range.message}
                </Typography>
              )}
              <Typography variant="bodySmall" color={galacticGrape[700]} mt={1}>
                Selected range: ₹{field.value[0]} - ₹{field.value[1]}
              </Typography>
            </>
          )}
        />
      </SliderSection>
    </ResponsiveStack>
  );
};

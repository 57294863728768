export const privacyContent = {
  title: "Privacy Policy",
  lastUpdated: "November 2024",
  version: "1",
  intro:
    'Welcome to Mochsha\'s Privacy Policy ("Privacy Policy" or "Policy"). Mochsha Ventures LLP ("Mochsha", "we", "us", "our") provides a web-based solution that connects users of this platform seeking specific services (hereinafter referred to as "Customer") with musicians, sound engineers, equipment vendors, media service (such as photography and videography) (Collectively referred to as "Musician").',

  sections: [
    {
      id: "introduction",
      title: "1. INTRODUCTION",
      content: [
        'This Policy applies to individuals who access our Platform or who avail our Services or Musician Services or registers on our Platform as a Musician (hereinafter referred to as "you").',
        "By using the Platform, you consent to the collection, storage, use, and disclosure of your personal data as outlined in this Policy. We regularly review and update our Privacy Policy and request you to check it periodically to stay informed. It is important that the personal data we hold about you is accurate and current. Please inform us if your personal data changes during your engagement with us.",
        'The Platform may feature links to external websites, platforms, plug-ins, services, and applications ("Third-Party Services"). When you click on such links, third parties may collect or share information about you. We do not control, or endorse Third-Party Services and are not liable for their privacy practices. When you leave our Platform or access third party links, we recommend reviewing the privacy policy of the relevant third-party service providers.',
      ],
    },
    {
      id: "personal-data",
      title: "2. PERSONAL DATA",
      content:
        "We collect different types of personal data about you, including, but not limited to:",
      listItems: [
        {
          primary: "Contact Data",
          secondary: "Your name, address, email address, mobile number",
        },
        {
          primary: "Identity and Profile Data",
          secondary:
            "Your username or similar identifiers, photographs, and gender",
        },
        {
          primary: "Marketing and Communications Data",
          secondary:
            "Information posted in service requests, offers, wants, feedback, comments, pictures, responses to user surveys and polls, your preferences in receiving marketing communications",
        },
        {
          primary: "Technical Data",
          secondary:
            "Your IP address, browser type, internet service provider, details of operating system, access time, page views, device ID, device type",
        },
        {
          primary: "Transaction Data",
          secondary:
            "Details of the Services or Musician Services you have availed, payment details, GST details, and UPI IDs",
        },
        {
          primary: "Usage Data",
          secondary:
            "Information about how you use the Services, your activity on the Platform, booking history, user interactions",
        },
      ],
    },
    {
      id: "data-collection",
      title: "3. HOW DO WE COLLECT PERSONAL DATA?",
      content: [
        "We collect personal data about you in following ways:",
        "Directly from you: When you provide personal data when you: (i) create an Account on our Platform; (ii) use Services or Musician Services; (iii) request marketing communications; (iv) submit feedback, report a grievance or contact us; or (v) any other personal data that you provide to us.",
        "Automatically: When you access the Platform or use our Services, we automatically collect Technical Data related to your device, browsing behaviour, and interaction with the Platform. We use tools like cookies, web beacons, pixel tags, server logs, and other similar tools for this.",
      ],
    },
    {
      id: "data-usage",
      title: "4. HOW DO WE USE YOUR PERSONAL DATA?",
      content: [
        "We use your personal data only when its legally allowed, primarily to provide you with our Services, help you access Musician Services, or meet any legal obligation.",
        "We may use your personal data to:",
        "• Verify your identity and set up your Platform account",
        "• Analyse trends and customize your experience",
        "• Improve our Services based on feedback",
        "• Track transactions and process payments",
        "• Send you communications",
        "• Market and promote the Services",
        "• Fulfil legal obligations",
        "• Manage and safeguard our business",
      ],
    },
    {
      id: "cookies",
      title: "5. COOKIES",
      content: [
        "Cookies are small data files which are downloaded to your device through your web browser (if allowed). Cookies help websites recognize your browser and capture specific information.",
        "We use cookies to enhance your experience by distinguishing you from other users of the Platform, remembering your preferences, monitoring ads, and gathering data about Platform traffic and interaction.",
        'Most cookies, referred to as "session cookies" are automatically deleted at the end of Your browsing session. You can choose to disable/block the cookies through your device / browser settings.',
      ],
    },
    {
      id: "payments",
      title: "6. PAYMENTS",
      content: [
        "To facilitate secure and efficient payment processing, we integrate with trusted third-party payment gateways. When you make payments through our Platform, your payment information is processed directly by the payment gateway providers.",
        "We do not store your complete payment details on our servers; only limited transactional information necessary for record-keeping, fraud prevention, and compliance may be retained.",
        "The payment gateways we use are compliant with applicable laws and standards, to ensure the security and confidentiality of your payment data.",
      ],
    },
    {
      id: "data-security",
      title: "7. DATA SECURITY",
      content: [
        "We use security protocols and privacy features (such as encryption, password protection, and call masking) to safeguard your data, in compliance with applicable laws.",
        "You're responsible for keeping your Account password confidential. We're not liable for unauthorized access due to lost, stolen, or compromised passwords.",
        "If your password is compromised, inform us immediately to help you reset it.",
      ],
    },
    {
      id: "minors",
      title: "8. MINORS",
      content: [
        "Our Platform and Services are intended for those who can enter into a legally binding contract under the Indian Contract Act, 1872.",
        "We do not knowingly collect personal data from individuals under 18 years old. Individuals under 18 may access the Platform or its content only with parental or guardian supervision and consent.",
      ],
    },
    {
      id: "grievance",
      title: "9. GRIEVANCE OFFICER",
      content:
        "If you have any inquiries regarding this Policy, our handling of your personal data, or any other concerns, please feel free to contact us with your questions, complaints, feedback, or comments at the designated email address.",
      listItems: [
        {
          primary: "Name",
          secondary: "[Grievance Officer Name]",
        },
        {
          primary: "Email",
          secondary: "[Grievance Officer Email]",
        },
      ],
    },
  ],
};

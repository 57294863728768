import {
  TextField,
  formHelperTextClasses,
  outlinedInputClasses,
  inputBaseClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextFieldStyled = styled(TextField, {
  shouldForwardProp(prop) {
    return prop !== "reducesize";
  },
})(({ theme, reducesize }) => {
  const { galacticGrape, cosmicTangerine, eclipseCherry } =
    theme.mochshaColorPalette;

  const style = {
    [`& .${outlinedInputClasses.root}`]: {
      background: galacticGrape[200],
      // height: "3.6rem",
      fontSize: "1.3rem",
      "& fieldset": {
        borderColor: galacticGrape[600],
        top: 0,
        padding: "0.2rem",
      },
      "&:hover fieldset": {
        borderColor: galacticGrape[700],
      },
      "&.Mui-focused fieldset": {
        borderColor: cosmicTangerine[900],
        borderWidth: 1,
        boxShadow: "none",
      },
    },
    [`& .${outlinedInputClasses.error}`]: {
      "&:hover fieldset": {
        borderColor: eclipseCherry[700],
      },
    },
    [`& .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
      padding: reducesize ? "0.35rem" : "",
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.disabled}`]: {
      "& fieldset": {
        borderColor: galacticGrape[500],
      },
      "&:hover fieldset": {
        borderColor: galacticGrape[500],
      },
    },
    [`& .${formHelperTextClasses.root}`]: {
      margin: "0.8rem 0",
    },
    "& legend": {
      display: "none",
    },
  };

  return style;
});

import React from "react";

import { useController } from "react-hook-form";

import _isFunction from "lodash/isFunction";
import { TextInputV2 } from "../../Inputs/TextInputV2";

export function TextFieldV2({
  name,
  control,
  defaultValue,
  label,
  placeholder,
  size,
  helperText,
  getValues,
  setValue,
  ...rest
}) {
  const {
    field: { onBlur, ...restField },
  } = useController({ name, control, defaultValue });

  return (
    <TextInputV2
      label={label}
      size={size}
      helperText={helperText}
      placeholder={placeholder ?? ""}
      aria-label={`${name}-textbox`}
      {...restField}
      onBlur={() => {
        if (_isFunction(getValues) && _isFunction(setValue)) {
          setValue(name, (getValues(name) || "").trim());
        }

        onBlur();
      }}
      {...rest}
    />
  );
}

import { Button, buttonClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { buttonPalette } from "../../styles/buttonPalette";

export const ButtonDivider = styled(Box)({
  blockSize: "0.6em",
  inlineSize: "0.3em",
  borderInlineStart: "1px solid",
  borderColor: "currentColor",
});

export const ButtonStyled = styled(Button, {
  shouldForwardProp(prop) {
    return prop !== "elevated";
  },
})(({ elevated, theme }) => {
  const commonSizeRules = {
    fontWeight: 500,
    padding: "0.6rem 1.6rem",

    [`&.${buttonClasses.sizeLarge}`]: {
      fontSize: "1.5rem",
    },

    [`&.${buttonClasses.sizeMedium}`]: {
      fontSize: "1.3rem",
      blockSize: "3.6rem",
    },

    [`&.${buttonClasses.sizeSmall}`]: {
      fontSize: "1.2rem",
      padding: "0.4rem 1.2rem",
    },
  };

  const disabledButtonStyle = {
    opacity: 0.45,
  };

  const primaryButtonRule = {
    "&.MuiButton-primary": {
      backgroundColor: buttonPalette.primary.backgroundColor, 
      boxShadow: "none",
      border: `1px solid ${buttonPalette.primary.borderColor}`,
      color: buttonPalette.primary.color,

      ...commonSizeRules,

      "&:hover": {
        backgroundColor: buttonPalette.primary.hoverBackgroundColor,
      },

      "&:disabled": {
        color: buttonPalette.primary.disabledColor,
        ...disabledButtonStyle,
      },

      "&:active": {
        backgroundColor: buttonPalette.primary.activeBackgroundColor,
        borderColor: buttonPalette.primary.activeBorderColor,
        color: buttonPalette.primary.activeColor,
      },
    },
  };

  const secondaryButtonRule = {
    "&.MuiButton-secondary": {
      border: `1px solid ${buttonPalette.danger.borderColor}`,
      borderColor: buttonPalette.secondary.borderColor,

      "&:hover": {
        backgroundColor: buttonPalette.secondary.hoverBackgroundColor,
      },

      "&:disabled": {
        ...disabledButtonStyle,
        borderColor: buttonPalette.secondary.disabledBorderColor,
      },

      "&:active": {
        backgroundColor: buttonPalette.secondary.activeBackgroundColor,
      },
    },
  };

  const textButtonRule = {
    "&.MuiButton-text": {
      backgroundColor: buttonPalette.text.backgroundColor,
      border: `1px solid ${buttonPalette.text.borderColor}`,
      color: buttonPalette.text.color,

      ...commonSizeRules,

      "&:hover": {
        backgroundColor: buttonPalette.text.hoverBackgroundColor,
        borderColor: buttonPalette.text.hoverBorderColor,
      },

      "&:disabled": {
        ...disabledButtonStyle,
      },

      "&:active": {
        backgroundColor: buttonPalette.text.activeBackgroundColor,
        borderColor: buttonPalette.text.activeBorderColor,
      },
    },
  };

  const dangerButtonRule = {
    "&.MuiButton-danger": {
      backgroundColor: buttonPalette.danger,
      border: `1px solid ${buttonPalette.danger.borderColor}`,
      borderColor: buttonPalette.danger.borderColor,
      color: buttonPalette.danger.color,
      ...commonSizeRules,

      "&:hover": {
        backgroundColor: buttonPalette.danger.hoverBackgroundColor,
      },

      "&:disabled": {
        ...disabledButtonStyle,
        borderColor: buttonPalette.danger.disabledBorderColor,
      },

      "&:active": {
        backgroundColor: buttonPalette.danger.activeBackgroundColor,
      },
    },
  };

  const styles = {
    ...(elevated && {
      "&.MuiButton-root": {
        "&:disabled": {
          backgroundColor: buttonPalette.root.disabledBackgroundColor,
        },
      },
    }),

    "& .MuiButton-endIcon": {
      alignItems: "center",
    },

    ...primaryButtonRule,

    ...secondaryButtonRule,

    ...textButtonRule,

    ...dangerButtonRule,
  };

  return styles;
});

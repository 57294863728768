import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Container,
  Pagination,
  CircularProgress,
  Chip,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import { Typography } from "../Typography";

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.2s",
  position: "relative",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
}));

const AvatarImage = styled("img")({
  width: 120,
  height: 120,
  borderRadius: "50%",
  objectFit: "cover",
  margin: "0 auto",
  display: "block",
  marginTop: "-60px",
  border: "4px solid white",
  boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
});

const LoadingWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "60vh",
});

const ErrorMessage = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.error.main,
  marginTop: theme.spacing(2),
}));

const PaginationWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const fetchProviders = async ({
  page = 1,
  perPage = 6,
  sortBy = "created_at",
  sortDirection = "DESC",
}) => {
  const response = await axios.get("https://api.mochsha.in/api/v1/providers", {
    params: {
      page,
      per_page: perPage,
      sort_by: sortBy,
      sort_direction: sortDirection,
    },
    withCredentials: true,
  });

  return response.data;
};

export const DiscoverProfile = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");

  const theme = useTheme();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["providers", currentPage, sortBy, sortDirection],
    queryFn: () =>
      fetchProviders({
        page: currentPage,
        sortBy,
        sortDirection,
      }),
  });

  if (isLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }

  if (isError) {
    return (
      <ErrorMessage variant="h6">
        Error loading profiles. Please try again later.
      </ErrorMessage>
    );
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const totalPages = Math.ceil(
    (data?.meta?.totalResults || 0) / (data?.meta?.resultsPerPage || 6)
  );

  const { galacticGrape } = theme.mochshaColorPalette;

  const getHostname = (url) => {
    try {
      // Add https if protocol is missing
      const fullUrl = url.startsWith("http") ? url : `https://${url}`;
      const hostname = new URL(fullUrl).hostname;
      return hostname.split(".")[1] || hostname;
    } catch (error) {
      return url;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Stack gap="2rem">
        <Typography
          variant="body"
          color={galacticGrape[700]}
          fontWeight="semiBold"
        >
          Discover Providers
        </Typography>

        <FilterContainer>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortBy}
              label="Sort By"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value="created_at">Date Created</MenuItem>
              <MenuItem value="title">Title</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Order</InputLabel>
            <Select
              value={sortDirection}
              label="Order"
              onChange={(e) => setSortDirection(e.target.value)}
            >
              <MenuItem value="ASC">Ascending</MenuItem>
              <MenuItem value="DESC">Descending</MenuItem>
            </Select>
          </FormControl>
        </FilterContainer>

        <Grid container spacing={3}>
          {data.data.map((provider) => (
            <Grid item xs={12} sm={6} md={4} key={provider.provider_id}>
              <StyledCard>
                <Box sx={{ height: 100, bgcolor: galacticGrape[100] }} />
                <AvatarImage
                  src={`https://api.mochsha.in/${provider.profile.profile_picture}`}
                  alt={provider.profile.display_name}
                  onError={(e) => {
                    e.target.src = "https://via.placeholder.com/120"; // Fallback image
                  }}
                />
                <CardContent>
                  <Typography variant="h6" align="center" gutterBottom>
                    {provider.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    gutterBottom
                  >
                    {provider.profile.display_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {provider.bio}
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom>
                    Location:
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {provider.profile.location}
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom>
                    Languages:
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    flexWrap="wrap"
                    sx={{ mb: 2 }}
                  >
                    {provider.languages?.map((language, index) => (
                      <Chip
                        key={index}
                        label={language}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Stack>

                  <Typography variant="subtitle2" gutterBottom>
                    Skills:
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    flexWrap="wrap"
                    sx={{ mb: 2 }}
                  >
                    {provider.skills?.map((skill, index) => (
                      <Chip
                        key={index}
                        label={`${skill.skill} (${skill.experience})`}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Stack>

                  {provider.profile.social_links?.length > 0 && (
                    <>
                      <Typography variant="subtitle2" gutterBottom>
                        Social Links:
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        sx={{ mb: 2 }}
                      >
                        {provider.profile.social_links.map((item, index) => (
                          <Chip
                            key={index}
                            label={getHostname(item.link)}
                            size="small"
                            component="a"
                            href={
                              item.link.startsWith("http")
                                ? item.link
                                : `https://${item.link}`
                            }
                            target="_blank"
                            clickable
                          />
                        ))}
                      </Stack>
                    </>
                  )}

                  <Typography variant="subtitle2" gutterBottom>
                    Contact:
                  </Typography>
                  <Typography variant="body2">
                    {provider.profile.phone_number}
                  </Typography>

                  {provider.profile.gst_info && (
                    <>
                      <Typography
                        variant="subtitle2"
                        sx={{ mt: 2 }}
                        gutterBottom
                      >
                        GST Info:
                      </Typography>
                      <Typography variant="body2">
                        {provider.profile.gst_info}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <PaginationWrapper>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .Mui-selected": {
                backgroundColor: `${galacticGrape[100]} !important`,
                color: `${galacticGrape[800]} !important`,
              },
            }}
          />
        </PaginationWrapper>
      </Stack>
    </Container>
  );
};

import { useController } from "react-hook-form";
import { DatePickerInputV2 } from "../../Inputs/DatePickerInputV2";

export function DatePickerFieldV2({
  name,
  control,
  defaultValue,
  placeholder,
  disabled,
  disablePast,
  dateFormat,
  label,
  labelContext,
  size,
  required,
  helperText,
}) {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <DatePickerInputV2
      error={!!fieldState.error?.message}
      helperText={helperText}
      label={label}
      labelContext={labelContext}
      placeholder={placeholder}
      disabled={disabled}
      disablePast={disablePast}
      dateFormat={dateFormat}
      required={required}
      size={size}
      {...field}
    />
  );
}

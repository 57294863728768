import axiosFactory from "axios";

const headers = {
  "Content-Type": "application/json",
};

const axiosFactoryCommonConfig = {
  baseURL: "https://api.mochsha.in",
  headers,
  withCredentials: true,
};

export const rawAxios = axiosFactory.create(axiosFactoryCommonConfig);

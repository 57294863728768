import { useState } from "react";
import { Stack, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box, Avatar, Paper, Link } from "@mui/material";
import { Typography } from "../Typography";
import { useTheme } from "@mui/material/styles";
import { Star, Edit, Activity, CalendarCheck, Archive } from "iconoir-react";
import { useUser } from "../../context/UserContext";
import { IconoirIcon } from "../../components/IconoirIcon";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button } from "../Button";
import { ProfileDialog } from "../ProfileDialog/ProfileDialog";
import { motion } from "framer-motion";

export const MainContentStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
}));

const VenueOwnerInfoWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const ReviewCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "1px solid #E0E0E0",
  borderRadius: "8px",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const mockReviews = [
  {
    id: "1",
    rater_user_id: "John Doe",
    ratee_user_id: "venue123",
    event_id: "event1",
    rating: 4.5,
    comment: "Great performance! Very professional and talented artist.",
    created_at: "2024-03-20T10:00:00Z",
    updated_at: "2024-03-20T10:00:00Z",
  },
  // ... other mock reviews ...
];

export const Profile = () => {
  const { userDetails, role, isLoading } = useUser();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const {
    galacticGrape,
    mochshaGreen,
    cosmicTangerine,
    eclipseCherry,
    auroraLime,
  } = theme.mochshaColorPalette;
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["userDetailsForParticularUser"],
    queryFn: async () => {
      const response = await axios.get(
        role === "client"
          ? `https://api.mochsha.in/api/v1/dashboard/${userDetails?.id}`
          : `https://api.mochsha.in/api/v1/bids/dashboard/${userDetails?.id}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !isLoading && !!userDetails?.id,
  });

  const { data: recentReviews } = useQuery({
    queryKey: ["recentReviews"],
    queryFn: async () => {
      const response = await axios.get(
        `https://api.mochsha.in/api/v1/ratings`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const Card = styled(motion(Paper))(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // gap: theme.spacing(2),
    transition: "all 0.3s ease",
    // position: "relative",
    overflow: "hidden",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 25px rgba(0, 0, 0, 0.1)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "4px",
      background: "inherit",
    },
  }));

  const IconWrapper = styled(Box)(({ theme }) => ({
    width: 56,
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    fontSize: 24,
    marginBottom: theme.spacing(1),
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  }));

  const DashboardCard = ({ icon: Icon, count, label, color }) => (
    <Card
      onClick={() => {
        navigate(`/dashboard?Count=${label.split(" ")[0].toLowerCase()}`);
      }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      sx={{ borderTop: `4px solid ${color}` }}
    >
      <IconWrapper
        style={{
          backgroundColor: `${color}15`,
          color: color,
        }}
      >
        <IconoirIcon icon={Icon} />
      </IconWrapper>
      <Typography variant="body" color={galacticGrape[700]}>
        {count}
      </Typography>
      <Typography variant="body" color={galacticGrape[700]}>
        {label}
      </Typography>
    </Card>
  );

  return (
    <Stack>
      <Stack
        padding={{ xs: "1rem", sm: "1.5rem", md: "2rem" }}
        gap={{ xs: "2rem", sm: "3rem", md: "5rem" }}
      >
        {/* Profile and Reviews Section */}
        <Stack gap="1rem">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body"
              color={galacticGrape[700]}
              fontWeight="semiBold"
            >
              Your Profile
            </Typography>
            <Button
              onClick={() => setIsProfileDialogOpen(true)}
              startIcon={<Edit />}
              size="small"
              variant="secondary"
            >
              Edit Profile
            </Button>
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} spacing="3rem">
            {/* Profile Section */}
            <Stack width="100%" gap="1rem">
              <MainContentStack
                sx={{
                  width: { xs: "100%", md: "100%" },
                }}
                border="1px solid #E0E0E0"
                borderRadius="8px"
              >
                <VenueOwnerInfoWrapper>
                  <Stack
                    spacing={{ xs: "0.75rem", md: "1rem" }}
                    padding={{ xs: "0.75rem", sm: "1rem", md: "1.5rem" }}
                  >
                    {/* Profile Header */}
                    <Stack
                      direction="row"
                      spacing={{ xs: "0.75rem", md: "1rem" }}
                      alignItems="center"
                      backgroundColor={mochshaGreen[400]}
                      padding={{ xs: "0.75rem", md: "1rem" }}
                      borderRadius="8px"
                    >
                      <Avatar
                        alt={userDetails?.profile?.display_name}
                        src={
                          "https://api.mochsha.in" +
                          userDetails?.profile?.profile_picture
                        }
                        sx={{
                          width: { xs: 48, md: 56 },
                          height: { xs: 48, md: 56 },
                          border: "2px solid white",
                        }}
                      />
                      <Stack spacing="0.2rem">
                        <Typography
                          variant="body"
                          color="#1E1E1E"
                          sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
                        >
                          {userDetails?.profile?.display_name}
                        </Typography>
                        <Typography
                          variant="subText"
                          color="#1E1E1E"
                          sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                        >
                          {userDetails?.profile?.user_role}
                        </Typography>
                      </Stack>
                    </Stack>

                    {/* About Me Section */}
                    <Stack spacing="0.5rem">
                      <Typography
                        variant="bodyStrong"
                        color={galacticGrape[900]}
                        fontWeight="semiBold"
                      >
                        About Me
                      </Typography>
                      <Typography
                        variant="bodySmall"
                        color="textSecondary"
                        sx={{
                          lineHeight: 1.6,
                          fontSize: { xs: "0.875rem", md: "1rem" },
                        }}
                      >
                        {(userDetails?.profile?.user_role === "client"
                          ? userDetails?.profile?.bio
                          : userDetails?.provider?.bio) || "No Bio Available"}
                      </Typography>
                    </Stack>

                    {/* Location and Social Links */}
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: "1rem", md: "2rem" }}
                      sx={{ gap: { xs: "1rem" } }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          width: { xs: "100%", sm: "auto" },
                          border: "1px solid #E0E0E0",
                          borderRadius: "8px",
                          transition: "transform 0.2s",
                          "&:hover": {
                            transform: "translateY(-2px)",
                          },
                        }}
                      >
                        <Stack padding={{ xs: "0.75rem", md: "1rem" }}>
                          <Typography
                            variant="bodyStrong"
                            color={galacticGrape[900]}
                          >
                            Location
                          </Typography>
                          <Typography
                            variant="bodySmall"
                            color={galacticGrape[700]}
                          >
                            {userDetails?.profile?.location}
                          </Typography>
                        </Stack>
                      </Paper>

                      {userDetails?.profile?.social_links?.length > 0 && (
                        <Box
                          sx={{
                            border: "1px solid #E0E0E0",
                            padding: { xs: "0.75rem", md: "1rem" },
                            backgroundColor: mochshaGreen[400],
                            borderRadius: "8px",
                            width: { sm: "auto" },
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "translateY(-2px)",
                            },
                          }}
                        >
                          <Typography
                            variant="bodyStrong"
                            color={galacticGrape[900]}
                          >
                            Social Links
                          </Typography>
                          <Link
                            href={userDetails?.profile?.social_links[0].link}
                            target="_blank"
                            color={galacticGrape[700]}
                            variant="bodySmall"
                            sx={{
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {userDetails?.profile?.social_links[0].link}
                          </Link>
                        </Box>
                      )}

                      {userDetails?.profile?.gst_info && (
                        <Paper
                          elevation={0}
                          sx={{
                            width: { xs: "100%", sm: "auto" },
                            border: "1px solid #E0E0E0",
                            borderRadius: "8px",
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "translateY(-2px)",
                            },
                          }}
                        >
                          <Stack padding={{ xs: "0.75rem", md: "1rem" }}>
                            <Typography
                              variant="bodyStrong"
                              color={galacticGrape[900]}
                            >
                              GST Number
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              color={galacticGrape[700]}
                            >
                              {userDetails?.profile?.gst_info}
                            </Typography>
                          </Stack>
                        </Paper>
                      )}

                      {userDetails?.profile?.phone_number && (
                        <Paper
                          elevation={0}
                          sx={{
                            width: { xs: "100%", sm: "auto" },
                            border: "1px solid #E0E0E0",
                            borderRadius: "8px",
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "translateY(-2px)",
                            },
                          }}
                        >
                          <Stack padding={{ xs: "0.75rem", md: "1rem" }}>
                            <Typography
                              variant="bodyStrong"
                              color={galacticGrape[900]}
                            >
                              Phone Number
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              color={galacticGrape[700]}
                            >
                              {userDetails?.profile?.phone_number}
                            </Typography>
                          </Stack>
                        </Paper>
                      )}
                    </Stack>
                  </Stack>
                </VenueOwnerInfoWrapper>
              </MainContentStack>
            </Stack>

            {/* Reviews Section */}
            <Stack width={{ xs: "100%", md: "50%" }} gap="1rem">
              <Typography
                variant="body"
                color={galacticGrape[700]}
                fontWeight="semiBold"
              >
                Recent Reviews
              </Typography>
              <Stack
                sx={{
                  width: { xs: "100%", md: "100%" },
                  gap: "1rem",
                }}
              >
                {mockReviews?.map((review) => (
                  <ReviewCard key={review.id} elevation={0}>
                    <Stack gap="0.5rem">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          variant="bodyStrong"
                          color={galacticGrape[900]}
                        >
                          {review.rater_user_id}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="0.25rem"
                        >
                          <Star style={{ color: "#FFD700", width: 16 }} />
                          <Typography
                            variant="bodySmall"
                            color={galacticGrape[700]}
                          >
                            {review.rating}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Typography
                        variant="bodySmall"
                        color={galacticGrape[700]}
                      >
                        {review.comment}
                      </Typography>
                      <Typography variant="caption" color={galacticGrape[500]}>
                        {new Date(review.created_at).toLocaleDateString()}
                      </Typography>
                    </Stack>
                  </ReviewCard>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* Dashboard Section */}
        <Stack gap="1rem">
          <Typography
            variant="body"
            color={galacticGrape[700]}
            fontWeight="semiBold"
          >
            Dashboard
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 2, md: 2 }}
            // justifyContent="space-between"
          >
            <DashboardCard
              icon={Activity} // Active/In-progress events
              count={data?.activeCount}
              label="Active Postings"
              color={auroraLime[800]}
            />
            <DashboardCard
              icon={CalendarCheck} // Confirmed/Scheduled events
              count={data?.confirmedCount}
              label="Confirmed Events You've Paid For" 
              color={cosmicTangerine[800]}
            />
            <DashboardCard
              icon={Archive} // Completed/Archived events
              count={data?.completedCount}
              label="Completed Events"
              color={eclipseCherry[800]}
            />
          </Stack>
        </Stack>

        {/* Profile Dialog */}
        <ProfileDialog
          isDialogOpen={isProfileDialogOpen}
          setIsDialogOpen={setIsProfileDialogOpen}
          onSuccess={() => {
            queryClient.invalidateQueries(["userDetailsForParticularUser"]);
          }}
          role={userDetails?.profile?.user_role}
        />
      </Stack>
    </Stack>
  );
};

import { useState } from "react";
import { Stack, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "../Typography";
import { DataGrid } from "@mui/x-data-grid";
import { IconoirIcon } from "../../components/IconoirIcon";
import { ArrowLeft } from "iconoir-react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Button } from "../Button";
import { ReviewBidDialog } from "./Dialogs/ReviewBidDialog";
import { ReviewBidDataGridDialog } from "./Dialogs/ReviewBidDataGridDialog";
import { ChatGuidelinesDialog, ConfirmHireDialog } from "./Dialogs/tandcDialog";
import { RazorpayPayment } from "../RazorPay/RazorpayPayment";
import { useUser } from "../../context/UserContext";

export const Dashboard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const countParam = searchParams.get("Count");

  const { role, isLoading: isUserLoading, userDetails } = useUser();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    perPage: 10,
  });

  const [selectedBids, setSelectedBids] = useState(null);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [selectedCurrentBid, setSelectedCurrentBid] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAcceptBidDialogOpen, setIsAcceptBidDialogOpen] = useState(false);
  const [isChatGuidelinesDialogOpen, setIsChatGuidelinesDialogOpen] =
    useState(false);
  const [isConfirmHireDialogOpen, setIsConfirmHireDialogOpen] = useState(false);

  const handleOpenBidDialog = (row) => {
    if (!row.bids?.[0]) return;
    setSelectedEventDetails(row);
    setSelectedBids(row.bids);
    setIsDialogOpen(true);
  };

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-main": {
      // remove overflow hidden overwise sticky does not work
      overflow: "unset",
    },
    "& .MuiDataGrid-columnHeaders": {
      position: "sticky",
    },
    "& .MuiDataGrid-virtualScroller": {
      // remove the space left for the header
      marginTop: "0!important",
    },
  }));

  const columns = [
    {
      field: "title",
      headerName: "Event Name",
      width: 200,
      pinned: "left",
      frozen: true,
    },
    {
      field: "location",
      headerName: "Location",
      width: 200,
      valueGetter: (params) => params || "-",
    },
    {
      field: "event_date",
      headerName: "Date",
      width: 130,
      valueGetter: (params) => {
        try {
          return params ? new Date(params).toLocaleDateString() : "-";
        } catch (error) {
          return "-";
        }
      },
    },
    {
      field: "start_time",
      headerName: "Start Time",
      width: 100,
      valueGetter: (params) => {
        try {
          return params ? params.substring(0, 5) : "-";
        } catch (error) {
          return "-";
        }
      },
    },
    {
      field: "event_status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Typography
          style={{
            color:
              params.row?.event_status === "active"
                ? "#2196f3"
                : params.row?.event_status === "confirmed"
                ? "#4caf50"
                : params.row?.event_status === "completed"
                ? "#9e9e9e"
                : "inherit",
          }}
        >
          {params.row?.event_status || "-"}
        </Typography>
      ),
    },
    {
      field: "budget_min",
      headerName: "Budget Min",
      width: 150,
      valueGetter: (params) => {
        try {
          return params;
        } catch (error) {
          return "-";
        }
      },
    },
    {
      field: "budget_max",
      headerName: "Budget Max",
      width: 150,
      valueGetter: (params) => params || "-",
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      valueGetter: (params) =>
        `${params || ""} ${
          params.row?.subcategory ? `(${params.row.subcategory})` : ""
        }`,
    },
    {
      field: "subcategory",
      headerName: "Subcategory",
      width: 200,
      valueGetter: (params) => params || "-",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) =>
        params.row?.bids?.length ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleOpenBidDialog(params.row);
            }}
            variant="secondary"
            size="small"
            disabled={!params.row?.bids?.length}
          >
            Review Bids
          </Button>
        ) : (
          <Typography sx={{ textAlign: "center" }}>No Bids Yet</Typography>
        ),
    },
  ];

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedBids(null);
    setSelectedCurrentBid(null);
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      "TypeofEvent",
      countParam,
      paginationModel.page,
      paginationModel.perPage,
    ],
    queryFn: async () => {
      const response = await axios.get(
        `https://api.mochsha.in/api/v1/eventsInfo?status=${countParam}&page=${
          paginationModel.page + 1
        }&limit=${paginationModel.perPage}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const handlePayment = RazorpayPayment(selectedCurrentBid);

  console.log(selectedCurrentBid);

  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };

  const handleCloseChatGuidelinesDialog = () => {
    setIsChatGuidelinesDialogOpen(false);
  };

  const handleCloseConfirmHireDialog = () => {
    setIsConfirmHireDialogOpen(false);
  };

  const handleReviewBid = (value) => {
    setIsChatGuidelinesDialogOpen(true);
    setIsAcceptBidDialogOpen(false);
  };

  const handleReviewBids = (value) => {
    setSelectedCurrentBid(value);
    setIsDialogOpen(false);
    setIsAcceptBidDialogOpen(true);
  };

  const handleCloseAcceptBidDialog = () => {
    setIsAcceptBidDialogOpen(false);
  };

  const handleChatAccept = () => {
    setIsConfirmHireDialogOpen(true);
    setIsChatGuidelinesDialogOpen(false);
  };

  const handleConfirmHire = () => {
    setIsConfirmHireDialogOpen(false);
    handlePayment();
  };

  return (
    <Stack
      spacing={3}
      padding={3}
      sx={{
        height: "100vh",
        boxSizing: "border-box",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton onClick={() => navigate("/profile")}>
          <IconoirIcon width="2rem" height="2rem" icon={ArrowLeft} />
        </IconButton>
        <Typography variant="body">Dashboard</Typography>
      </Stack>
      <Stack direction="row" spacing={2} mb={2}>
        <Box
          sx={{
            p: 2,
            bgcolor: "#e3f2fd",
            borderRadius: 1,
            flex: 1,
            border: countParam === "active" ? "2px solid #2196f3" : "none",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/dashboard?Count=active`);
          }}
        >
          <Typography>Submitted Bids</Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            bgcolor: "#e8f5e9",
            borderRadius: 1,
            flex: 1,
            border: countParam === "confirmed" ? "2px solid #4caf50" : "none",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/dashboard?Count=confirmed`);
          }}
        >
          <Typography>Upcoming Confirmed Events</Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            bgcolor: "#f5f5f5",
            borderRadius: 1,
            flex: 1,
            border: countParam === "completed" ? "2px solid #9e9e9e" : "none",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/dashboard?Count=completed`);
          }}
        >
          <Typography>Completed Events</Typography>
        </Box>
      </Stack>
      {!isUserLoading && role === "client" && (
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            minHeight: 0,
          }}
        >
          <StyledDataGrid
            rows={data?.data || []}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            rowCount={data?.meta.totalResults || 0}
            paginationMode="server"
            loading={isLoading}
            onRowClick={(params) => handleOpenBidDialog(params.row)}
          />

          {selectedBids && (
            <ReviewBidDataGridDialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              initialData={selectedBids}
              onReviewBid={handleReviewBids}
              eventDetails={selectedEventDetails}
              countParam={countParam}
            />
          )}

          {selectedCurrentBid && (
            <ReviewBidDialog
              open={isAcceptBidDialogOpen}
              onClose={handleCloseAcceptBidDialog}
              initialData={selectedCurrentBid}
              onReviewBid={handleReviewBid}
              eventDetails={selectedEventDetails}
            />
          )}

          <ChatGuidelinesDialog
            open={isChatGuidelinesDialogOpen}
            onClose={handleCloseChatGuidelinesDialog}
            onChatAccept={handleChatAccept}
          />

          {selectedCurrentBid && (
            <ConfirmHireDialog
              open={isConfirmHireDialogOpen}
              onClose={handleCloseConfirmHireDialog}
              onConfirmHire={handleConfirmHire}
              selectedBid={selectedCurrentBid}
              clientProfile={userDetails.profile}
              providerProfile={selectedCurrentBid.provider.profile}
              eventDetails={selectedEventDetails}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};

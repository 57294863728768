import { styled } from "@mui/material/styles";
import { Box, Link } from "@mui/material";
import { Typography } from "../Typography/Typography";
import { Button } from "../Button/Button";

export const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.mochshaColorPalette.mistyMint[900],
  padding: theme.spacing(4, 2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6, 4),
  },
}));

export const FooterContent = styled(Box)(({ theme }) => ({
  maxWidth: "1200px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    alignItems: "center",
  },
}));

export const FooterSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    alignItems: "center",
    textAlign: "center",
  },
}));

export const FooterLogo = styled("img")(({ theme }) => ({
  height: "3rem",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    height: "4rem",
  },
}));

export const SocialIcons = styled(Box)(({ theme }) => ({
  display: "flex",
  fontSize: "1.6rem",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const FooterTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const FooterButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const FooterLinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    flexDirection: "column",
  },
}));

export const FooterBottom = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(4),
  paddingTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

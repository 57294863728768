import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { Button } from "../../Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledWarningIcon = styled(WarningIcon)({
  color: "#f44336",
  marginRight: "8px",
});

function ChatGuidelinesDialog({ open, onClose, onChatAccept }) {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <StyledWarningIcon />
          <Typography variant="h6">Mochsha Chat Guidelines</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          Welcome to Mochsha! We're thrilled to have you here as part of our
          community. To ensure smooth and fair collaborations, please take a
          moment to read through our chat guidelines.
        </Typography>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>1. Complete Transactions Within Mochsha</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              All payments and transactions must be conducted within the
              platform to ensure security and accountability.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              2. No Sharing of Personal Contact Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              For your safety, please do not share personal contact details such
              as phone numbers or email addresses in chat.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>3. Your Protection is Our Priority</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We prioritize your safety and ensure that all communications and
              transactions are monitored for any discrepancies.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleAccordionChange("panel4")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>4. Respect the Community</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Engage respectfully and maintain a positive environment for all
              users on the platform.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleAccordionChange("panel5")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>5. Ask Us if You Need Help</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              If you encounter any issues or need assistance, don't hesitate to
              reach out to our support team.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            onChatAccept();
          }}
        >
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmHireDialog({
  open,
  onClose,
  onConfirmHire,
  clientProfile,
  providerProfile,
  eventDetails,
  selectedBid,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Format date and time
  const eventDate = new Date(eventDetails.event_date).toLocaleDateString();
  const startTime = eventDetails.start_time.slice(0, 5);
  const endTime = eventDetails.end_time.slice(0, 5);

  // Calculate duration in hours
  const start = new Date(`2000-01-01T${eventDetails.start_time}`);
  const end = new Date(`2000-01-01T${eventDetails.end_time}`);
  const durationHours = (end - start) / (1000 * 60 * 60);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={isSmallScreen ? "xs" : "md"}
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <StyledWarningIcon />
          <Typography variant="h6">Confirm Hire</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          You have chosen to hire {providerProfile.display_name}. This will
          terminate all other active bids related to this job and finalize your
          selection.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Service Agreement
        </Typography>
        <Typography variant="body2">
          This Agreement is made on{" "}
          {new Date().toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}{" "}
          between:
        </Typography>
        <Box
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          justifyContent="space-between"
          my={2}
        >
          <Box mb={isSmallScreen ? 2 : 0}>
            <Typography variant="subtitle2">Client</Typography>
            <Typography>Name: {clientProfile.display_name}</Typography>
            <Typography>Contact: {clientProfile.phone_number}</Typography>
          </Box>
          <Box mb={isSmallScreen ? 2 : 0}>
            <Typography variant="subtitle2">Talent Provider</Typography>
            <Typography>Name: {providerProfile.display_name}</Typography>
            <Typography>Contact: {providerProfile.phone_number}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Platform (acting solely as a facilitator)
            </Typography>
            <Typography>Name: Mochsha Platform</Typography>
            <Typography>Contact: support@mochsha.com</Typography>
          </Box>
        </Box>
        <Typography variant="h6" gutterBottom>
          Event Details
        </Typography>
        <ul>
          <li>Title: {eventDetails.title}</li>
          <li>Date: {eventDate}</li>
          <li>
            Time: {startTime} to {endTime}
          </li>
          <li>Duration: {durationHours} hours</li>
          <li>Location: {eventDetails.location}</li>
          <li>
            Category: {eventDetails.category} - {eventDetails.subcategory}
          </li>
          <li>Genre: {eventDetails.genre}</li>
          <li>Language: {eventDetails.language}</li>
          <li>Total Fee: ₹{selectedBid.amount}</li>
        </ul>
        <Typography variant="h6" gutterBottom>
          Bidding and Payment
        </Typography>
        <Typography variant="body2" paragraph>
          1. Bidding Process: Client posts event; Talent Providers submit bids.
          Client selects based on fee and experience.
          <br />
          2. Payment Terms:
          <ul>
            <li>Total Amount: ₹{selectedBid.amount}</li>
            <li>
              Payment Schedule: 100% payment to be made upfront to Mochsha
              Platform
            </li>
            <li>
              Fund Release: The payment will be released to the talent provider
              after:
              <ul>
                <li>Successful completion of the performance</li>
                <li>Deduction of applicable platform fees</li>
                <li>Deduction of any applicable taxes</li>
              </ul>
            </li>
          </ul>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Terms
        </Typography>
        <Typography variant="body2" paragraph>
          Cancellation: If Client cancels within 7 days, 50% of the fee is due.
          If Talent Provider cancels, the deposit will be refunded, and efforts
          to find a replacement will be made.
          <br />
          Professionalism: Talent Provider agrees to arrive 15 minutes early and
          perform professionally for the agreed duration.
          <br />
          Liability: Client ensures a safe environment. Talent Provider covers
          their own insurance.
        </Typography>
        <Typography variant="body2" paragraph>
          Force Majeure: Neither party is liable for uncontrollable events.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose();
            onConfirmHire();
          }}
        >
          Confirm Hire
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { ChatGuidelinesDialog, ConfirmHireDialog };

import React from "react";
import { Container, Paper, List, ListItem, ListItemText, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "../../components/Typography";
import { servicesContent } from "./ServicesContent";
// Icon imports
import GroupIcon from '@mui/icons-material/Group';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SettingsIcon from '@mui/icons-material/Settings';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaymentsIcon from '@mui/icons-material/Payments';
import ListItemIcon from '@mui/material/ListItemIcon';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    // color: theme.palette.primary.main,
    fontSize: '2rem',
  }
}));

const ListItemWithIcon = styled(ListItem)(({ theme }) => ({
  '& .MuiListItemIcon-root': {
    minWidth: '40px',
  },
//   '& .MuiSvgIcon-root': {
//     color: theme.palette.primary.light,
//   }
}));

const Services = () => {
  // Icon mapping for list items
  const getItemIcon = (title) => {
    if (title.toLowerCase().includes('musician')) return <MusicNoteIcon />;
    if (title.toLowerCase().includes('sound')) return <SettingsIcon />;
    if (title.toLowerCase().includes('light')) return <SettingsIcon />;
    if (title.toLowerCase().includes('equipment')) return <HandshakeIcon />;
    if (title.toLowerCase().includes('search')) return <SearchIcon />;
    if (title.toLowerCase().includes('book')) return <AssignmentIcon />;
    if (title.toLowerCase().includes('pay')) return <PaymentsIcon />;
    return <GroupIcon />;
  };

  return (
    <Container maxWidth="lg" sx={{ pb: 8, pt: 4 }}>
      {/* Main Description */}
      <StyledPaper elevation={0}>
        <SectionTitle>
          <HandshakeIcon fontSize="large" />
          <Typography variant="h4">
            {servicesContent.title}
          </Typography>
        </SectionTitle>
        <Typography variant="body1" paragraph>
          {servicesContent.mainDescription}
        </Typography>
      </StyledPaper>

      {/* Sections */}
      {servicesContent.sections.map((section) => (
        <StyledPaper key={section.id} elevation={1}>
          <SectionTitle>
            {section.id === 'connect' && <GroupIcon />}
            {section.id === 'platform-features' && <SettingsIcon />}
            {section.id === 'how-it-works' && <AssignmentIcon />}
            <Typography variant="h5">
              {section.title}
            </Typography>
          </SectionTitle>
          <Typography variant="body1" paragraph>
            {section.content}
          </Typography>
          <List>
            {section.listItems.map((item, index) => (
              <ListItemWithIcon key={index}>
                <ListItemIcon>
                  {getItemIcon(item.primary)}
                </ListItemIcon>
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                />
              </ListItemWithIcon>
            ))}
          </List>
        </StyledPaper>
      ))}
    </Container>
  );
};

export default Services;

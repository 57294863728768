import {
  listItemClasses,
  listItemTextClasses,
  ListItem,
  List,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const ListItemStyled = styled(ListItem)(({ theme }) => {
  const { galacticGrape, cosmicTangerine } = theme.mochshaColorPalette;

  const style = {
    [`&.${listItemClasses.root}`]: {
      padding: "0.6rem 1.2rem",

      "&.Mui-focused": {
        backgroundColor: galacticGrape[300],
      },

      [`& .${listItemTextClasses.root}`]: {
        display: "flex",
      },

      [`& img`]: {
        borderRadius: "5px",
        marginInline: "0.5rem",
        width: "2rem",
      },

      [`& .${listItemTextClasses.primary}`]: {
        flexGrow: 1,
        fontSize: "1.3rem",
        color: galacticGrape[800],

        [`& p`]: {
          color: galacticGrape[700],
        },
      },

      [`& .${listItemTextClasses.secondary}`]: {
        fontSize: "1.2rem",
      },

      "&[aria-selected=true]": {
        backgroundColor: cosmicTangerine[400],

        [`& .${listItemTextClasses.primary}`]: {
          color: cosmicTangerine[900],
        },
      },
    },
  };

  return style;
});

export const GroupHeader = styled(Box)(({ theme }) => ({
  padding: "0.5rem 0.9rem",
  color: theme.mochshaColorPalette.galacticGrape[800],
  fontWeight: 600,
  fontSize: "1.3rem",
  borderBlockEnd: `1px solid ${theme.mochshaColorPalette.galacticGrape[500]}`,
}));

export const GroupItems = styled(List)({
  padding: 0,
});

import { Menu, menuClasses, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MenuStyled = styled(Menu)(({ theme }) => {
  const style = {
    [`& .${menuClasses.paper}`]: {
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      minWidth: "29rem",
      width: "auto !important",
      margin: "0 0.5rem",
      borderRadius: "8px",
      overflow: "visible",

      "& .MuiList-root": {
        padding: "0",
        overflow: "visible",
      },
    },
  };

  return style;
});

export const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isOpened",
})(({ theme, isOpened }) => {
  const { light, contrastText } = theme.palette.primary;

  const style = {
    paddingInline: "0.2rem 0.8rem",
    paddingBlock: "0.2rem",
    borderRadius: "20px",
    background: isOpened ? light : contrastText,
    border: `1px solid ${isOpened ? light : contrastText}`,
  };

  return style;
});

import React, { useState } from "react";
import { Stepper, Step, StepLabel, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Typography } from "../Typography";
import { useUser } from "../../context/UserContext";

import { BottomSheetDialog } from "../BottomSheetDialog";
import { useStepper } from "../../hooks/useStepper";
import { PortfolioInput } from "./PortfolioInput";
import { ProProfile } from "./ProProfile";
import { isDisabled } from "./utils";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toasts } from "../Toast/toasts";
import { Button } from "../Button";

export const MusicianOnBoardingDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  onClose,
}) => {
  const [formValues, setFormValues] = useState({});

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { userDetails } = useUser();

  const proprofileResolver = async (values) => {
    const errors = {};
    const requiredFields = ["title", "bio", "languages"];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    // Validate skills array
    if (!values.skills || values.skills.length === 0) {
      errors.skills = {
        type: "required",
        message: "At least one skill is required",
      };
    } else {
      const skillErrors = values.skills
        .map((skill, index) => {
          const skillError = {};
          if (!skill.skill) {
            skillError.skill = {
              type: "required",
              message: "Skill is required",
            };
          }
          if (!skill.experience) {
            skillError.experience = {
              type: "required",
              message: "Experience is required",
            };
          }
          return Object.keys(skillError).length > 0 ? skillError : undefined;
        })
        .filter(Boolean);

      if (skillErrors.length > 0) {
        errors.skills = skillErrors;
      }
    }

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: Object.keys(errors).length > 0 ? errors : {},
    };
  };

  const {
    control: proprofileControl,
    watch: proprofileWatch,
    setValue: proprofileSetValue,
    handleSubmit: proprofileHandleSubmit,
    formState: { errors: proprofileErrors },
    reset: proprofileReset,
  } = useForm({
    mode: "all",
    resolver: proprofileResolver,
  });

  const socialsResolver = async (values) => {
    const errors = {};
    const requiredFields = ["social_links"];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = {
          type: "required",
          message: "This field is required",
        };
      }
    });

    if (!values.social_links || values.social_links.length === 0) {
      errors.social_links = {
        type: "required",
        message: "At least one social link is required",
      };
    } else {
      const socialLinkErrors = values.social_links
        .map((link, index) => {
          if (!link) {
            return {
              type: "required",
              message: "Social link is required",
            };
          }
          return undefined;
        })
        .filter(Boolean);

      if (socialLinkErrors.length > 0) {
        errors.socialLinks = socialLinkErrors;
      }
    }

    return {
      values: Object.keys(errors).length === 0 ? values : {},
      errors: Object.keys(errors).length > 0 ? errors : {},
    };
  };

  const {
    control: socialsControl,
    watch: socialsWatch,
    setValue: socialsSetValue,
    formState: { errors: socialsErrors },
    handleSubmit: socialsHandleSubmit,
    reset: socialsReset,
  } = useForm({
    mode: "onBlur",
    resolver: socialsResolver,
  });

  const step1 = (
    <ProProfile
      control={proprofileControl}
      errors={proprofileErrors}
      watch={proprofileWatch}
      setValue={proprofileSetValue}
    />
  );
  const step2 = (
    <PortfolioInput
      control={socialsControl}
      errors={socialsErrors}
      watch={socialsWatch}
      setValue={socialsSetValue}
    />
  );

  const stepperState = {
    stepArray: [step1, step2],
    horizontalStepperList: ["Professional Profile", "Socials"],
  };

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, goTo } =
    useStepper(stepperState.stepArray);

  const handleStepSubmit = (stepIndex) => {
    const submitHandlers = {
      0: proprofileHandleSubmit,
      1: socialsHandleSubmit,
    };

    const submitHandler = submitHandlers[stepIndex];
    if (submitHandler) {
      submitHandler((values) => {
        setFormValues((prev) => ({ ...prev, ...values }));

        // If it's the last step, trigger the mutation with all form values
        if (stepIndex === 1) {
          // Debug log
          mutate(formValues);
        }
      })();
    }
  };

  const { mutate } = useMutation({
    mutationKey: ["onboarding", "musician"],
    mutationFn: async (data) => {
      const formData = new FormData();

      // Add tech rider file if it exists
      if (data.tech_rider) {
        formData.append("tech_rider", data.tech_rider);
      }

      // Remove tech_rider from the data before stringifying
      const { tech_rider, ...providerData } = data;
      formData.append("provider_data", JSON.stringify(providerData));

      console.log("Form Data: ", formData);

      await axios.post("https://api.mochsha.in/api/v1/providers", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
    },
    onSuccess: async () => {
      toasts.success({
        title: "Onboarding successful",
        description: "You can now start using the platform",
      });

      await queryClient.invalidateQueries({
        queryKey: ["userDetails"],
      });

      goTo(0);
      setIsDialogOpen(false);
      setFormValues({});
      proprofileReset();
      socialsReset();
      navigate("/");
    },
    onError: (error) => {
      console.log("Form Values:", formValues);
      toasts.error({
        title: "Something went wrong",
        description: error.response?.data?.message || "Please try again",
      });
    },
  });
  return (
    <Stack>
      <BottomSheetDialog
        title={
          <Typography variant="heading" fontWeight="bold">
            Provider Onboarding
          </Typography>
        }
        open={isDialogOpen}
        onClose={() => {
          goTo(0);
          setIsDialogOpen(false);
          onClose();
        }}
        isCloseIconVisible={false}
        actions={
          <Stack
            direction="row"
            justifyContent={isFirstStep ? "flex-end" : "space-between"}
            width="100%"
          >
            {!isFirstStep && (
              <Button autoFocus onClick={back}>
                Back
              </Button>
            )}
            <Button
              autoFocus
              disabled={isDisabled(
                currentStepIndex,
                proprofileWatch,
                proprofileErrors,
                socialsWatch,
                socialsErrors
              )}
              onClick={() => {
                handleStepSubmit(currentStepIndex);
                if (!isLastStep) {
                  next();
                }
              }}
            >
              {isLastStep ? "Submit" : "Next"}
            </Button>
          </Stack>
        }
      >
        <Stack spacing="3rem">
          <Stepper activeStep={currentStepIndex}>
            {stepperState.horizontalStepperList.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {step}
        </Stack>
      </BottomSheetDialog>
    </Stack>
  );
};

import React, { useState } from "react";
import JourneyDialog from "../../components/Journey/JourneyDialog";

const Journey = () => {
  const [open, setOpen] = useState(true);
  return (
    <JourneyDialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return; // Prevents closing when clicking outside
        }
        setOpen(false);
      }}
    />
  );
};

export default Journey;

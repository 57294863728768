import React, { useState, useCallback, useMemo } from "react";
import {
  HeroContainer,
  Overlay,
  HeroTitle,
  HeroSubtitle,
  Content,
  SearchBar,
} from "./HeroSection.styled";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Button } from "../Button/Button";
import { Stack } from "@mui/material";

const HeroSection = ({ control, watch, User, errors, onSearch }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const debounce = useCallback((func, delay = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  }, []);

  const handleInputChange = useCallback((_, value) => {
    if (value.length > 0) {
      setLoading(true);
      fetch(`https://demo.dataverse.org/api/search?q=${value}`)
        .then((res) => res.json())
        .then((json) => {
          const finalData = json.data.items.map((v) => ({
            value: v.name,
            label: v.name,
          }));
          setOptions((previousState) => [...finalData, ...previousState]);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const optimizedFn = useMemo(
    () => debounce(handleInputChange),
    [debounce, handleInputChange]
  );

  const handleButtonClick = () => {
    onSearch(watch("search"));
  };

  return (
    <HeroContainer>
      <Overlay />
      <Content>
        <HeroTitle>Welcome, {User}</HeroTitle>
        <Stack
          spacing="1rem"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <HeroSubtitle>The Digital Marketplace For Live Music</HeroSubtitle>
          {/* <Stack width={isMobile ? "100%" : "60%"}>
            <SearchBar
              control={control}
            name="search"
            placeholder="Search for Postings, or Professionals..."
            error={!!errors.search}
            helperText={errors.search?.message}
            InputProps={{ style: { backgroundColor: "white" } }}
            sx={{ alignSelf: "center" }}
            loading={loading}
            freeSolo
            options={options}
            onInputChange={optimizedFn}
            />
          </Stack> */}
          {/* <Stack width="100%" alignItems="center" justifyContent="center">
            <Button onClick={handleButtonClick}>Search</Button>
          </Stack> */}
        </Stack>
      </Content>
    </HeroContainer>
  );
};

export default HeroSection;

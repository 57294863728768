import React, { createContext, useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { data: userDetails, isLoading } = useQuery({
    queryKey: ["userDetails"],
    queryFn: async () => {
      const response = await axios.get(
        "https://api.mochsha.in/api/v1/users/details",
        {
          withCredentials: true,
        }
      );
      return response.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const role = userDetails?.profile?.user_role;

  return (
    <UserContext.Provider value={{ userDetails, role, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

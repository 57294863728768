export const palette = {
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      light: "#6FA8FE",
      main: "#0C6CFC",
      dark: "#0B5CD6",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#FF7278",
      main: "#FF2831",
      dark: "#D10D15",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF2831",
      dark: "#D10D15",
      light: "#FF7278",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0C6CFC",
      dark: "#0B5CD6",
      light: "#6FA8FE",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFA522",
      dark: "#E38B09",
      light: "#FAC16D",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#4CAF50",
      dark: "#37A23B",
      light: "#82E386",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    tertiary: {
      main: "#F8761F",
    },
    divider: "#E0E0E0",
    tooltipGrey: "#CCC",
  },
};

export const mochshColorpalette = {
  palette: {
    cosmicTangerine: {
      900: "hsl(24, 94%, 55%)",
      800: "hsl(24, 95%, 75%)",
      700: "hsl(24, 95%, 85%)",
      600: "hsl(25, 95%, 92%)",
      500: "hsl(24, 100%, 97%)",
      400: "hsl(24, 100%, 99%)",
    },
    mochshaGreen: {
      900: "hsl(173, 19%, 27%)",
      800: "hsl(173, 19%, 47%)",
      700: "hsl(173, 19%, 67%)",
      600: "hsl(173, 19%, 77%)",
      500: "hsl(173, 19%, 87%)",
      400: "hsl(173, 19%, 97%)",
    },
    // buttonBlue: {
    //   900: "hsla(216, 98%, 52%, 1)",
    //   800: "hsla(216, 98%, 65%, 1)",
    //   700: "hsla(216, 97%, 75%, 1)",
    //   600: "hsla(216, 97%, 85%, 1)",
    //   500: "hsla(216, 96%, 90%, 1)",
    //   400: "hsla(216, 100%, 98%, 1)",
    // },
    galacticGrape: {
      900: "hsl(212, 31%, 12%)",
      800: "hsl(212, 15%, 25%)",
      700: "hsl(213, 5%, 45%)",
      600: "hsl(210, 5%, 75%)",
      500: "hsl(220, 6%, 90%)",
      400: "hsl(240, 7%, 95%)",
      300: "hsl(0, 0%, 98%)",
      200: "hsl(0, 0%, 100%)",
    },
    auroraLime: {
      900: "hsl(112, 100%, 15%)",
      800: "hsl(112, 89%, 26%)",
      700: "hsl(112, 54%, 41%)",
      600: "hsl(112, 47%, 65%)",
      500: "hsl(112, 68%, 89%)",
      400: "hsl(111, 78%, 96%)",
    },
    orionBerry: {
      900: "hsl(219, 89%, 25%)",
      800: "hsl(212, 80%, 39%)",
      700: "hsl(205, 97%, 57%)",
      600: "hsl(198, 98%, 74%)",
      500: "hsl(191, 96%, 91%)",
      400: "hsl(192, 100%, 97%)",
    },
    solarSquash: {
      900: "hsl(47, 100%, 38%)",
      800: "hsl(48, 100%, 45%)",
      700: "hsl(49, 100%, 64%)",
      600: "hsl(48, 100%, 71%)",
      500: "hsl(49, 100%, 86%)",
      400: "hsl(48, 100%, 95%)",
    },
    eclipseCherry: {
      900: "hsl(0, 100%, 28%)",
      800: "hsl(0, 97%, 36%)",
      700: "hsl(0, 79%, 50%)",
      600: "hsl(0, 80%, 63%)",
      500: "hsl(0, 100%, 82%)",
      400: "hsl(0, 100%, 96%)",
    },
    electricViolet: {
      900: "hsl(252, 74%, 38%)",
      800: "hsl(252, 100%, 64%)",
      700: "hsl(252, 100%, 74%)",
      600: "hsl(260, 100%, 84%)",
      500: "hsl(264, 100%, 93%)",
      400: "hsl(260, 100%, 98%)",
    },
    mistyMint: {
      900: "hsl(160, 17%, 90%)",
      800: "hsl(180, 16%, 92%)",
      700: "hsl(180, 16%, 94%)",
      600: "hsl(180, 16%, 97%)",
      500: "hsl(180, 16%, 99%)",
    },
    deepCharcoal: {
      900: "hsl(0, 0%, 12%)",
      800: "hsl(0, 0%, 17%)",
      700: "hsl(0, 0%, 22%)",
      600: "hsl(0, 0%, 27%)",
      500: "hsl(0, 0%, 32%)",
    },
    softSilver: {
      900: "hsl(0, 0%, 85%)",
      800: "hsl(0, 0%, 88%)",
      700: "hsl(0, 0%, 90%)",
      600: "hsl(0, 0%, 93%)",
      500: "hsl(0, 0%, 95%)",
    },
    pureWhite: {
      900: "hsl(0, 0%, 100%)",
      800: "hsl(0, 0%, 100%)",
      700: "hsl(0, 0%, 100%)",
      600: "hsl(0, 0%, 100%)",
      500: "hsl(0, 0%, 100%)",
    },
    vibrantViolet: {
      900: "hsl(270, 100%, 72%)",
      800: "hsl(270, 100%, 76%)",
      700: "hsl(270, 100%, 80%)",
      600: "hsl(270, 100%, 84%)",
      500: "hsl(270, 100%, 88%)",
    },
  },
};

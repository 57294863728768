import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Autocomplete as MuiAutocomplete,
  Stack,
} from "@mui/material";
import { NavArrowDown, Xmark } from "iconoir-react";
import _isFunction from "lodash/isFunction";
import _noop from "lodash/noop";
import { forwardRef, useState } from "react";

import { AutocompleteChip } from "../../../Chips/AutocompleteChip/AutocompleteChip";
import { IconoirIcon } from "../../../IconoirIcon";
import { Typography } from "../../../Typography";
import { Checkbox } from "../../Inputs/Checkbox";
import { TextInputV2 } from "../TextInputV2";
import {
  GroupHeader,
  GroupItems,
  ListItemStyled,
} from "./AutocompleteInput.styled";

export const AutocompleteInput = forwardRef(
  (
    {
      name,
      error,
      label = "",
      labelContext,
      placeholder = "",
      size = "small",
      variant,
      options,
      multiple,
      helperText,
      disabled,
      groupBy,
      freeSolo,
      loading,
      value,
      required,
      disableClearable = false,
      ...rest
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("");
    const [open, setOpen] = useState(false);
    const [hasChip, setHasChip] = useState(false);

    // This is needed in case of async option and loading is not passed as props
    const isOptionLoading = loading ?? (open && options.length === 0);

    const handleChange = (selectedOptions) => {
      let values = null;
      setHasChip(false);

      if (
        multiple &&
        freeSolo &&
        Array.isArray(selectedOptions) &&
        selectedOptions.length === 0
      ) {
        rest.onChange("");

        return;
      }

      if (Array.isArray(selectedOptions)) {
        values = selectedOptions.map((option) => option.value ?? option);
        setHasChip(values.length > 0);

        if (values.length === 0) {
          values = multiple ? [] : "";
        }
      } else if (
        selectedOptions &&
        typeof selectedOptions === "object" &&
        !Array.isArray(selectedOptions)
      ) {
        values = selectedOptions.value;
      } else {
        values = selectedOptions || (multiple ? [] : "");
      }

      rest.onChange(values);
    };

    const getCurrentValue = () => {
      if (!value) {
        return multiple ? [] : value;
      }

      const currentValue = multiple
        ? (value || []).map((item) => {
            const optionFound = options.find(
              (option) => (option.value || option) === item
            );

            return optionFound || item;
          })
        : options.find((option) => (option.value || option) === value) ||
          value ||
          null;

      return currentValue;
    };

    const getTagLabel = (val) => {
      if (multiple && !freeSolo) {
        return options.find((option) => option.value === val).label;
      }

      return val;
    };

    const isSelected = (option) => {
      const selected = new Set(value);

      return selected.has(option.value);
    };

    return (
      <MuiAutocomplete
        fullWidth
        size={size}
        limitTags={4}
        noOptionsText="No options"
        options={options}
        freeSolo={freeSolo}
        multiple={multiple}
        inputValue={inputValue}
        disableClearable={disableClearable}
        value={getCurrentValue()}
        disabled={disabled}
        loading={isOptionLoading}
        openOnFocus
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(_, newInputValue, reason) => {
          setInputValue(newInputValue);

          if (_isFunction(rest.onInputChange)) {
            rest.onInputChange(_, newInputValue, reason);
          }

          /**
           * This is needed due to one of the controller value update related issue
           * only when the control is freesolo and single
           */

          if (freeSolo && !multiple) {
            handleChange(newInputValue);
          }
        }}
        onBlur={(event) => {
          if (_isFunction(rest.onBlur)) {
            rest.onBlur(event);
          }
        }}
        onChange={(_, newValue) => {
          handleChange(newValue);
        }}
        renderTags={(val, props) =>
          val.map((option, index) => (
            <AutocompleteChip
              label={option.label || option}
              {...props({ index })}
            />
          ))
        }
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        groupBy={groupBy}
        renderOption={(props, option, { selected }) => (
          <ListItemStyled {...props} key={option.value}>
            {multiple && !freeSolo && (
              <ListItemIcon sx={{ minWidth: "unset" }}>
                <Checkbox edge="start" checked={isSelected(option)} />
              </ListItemIcon>
            )}

            {multiple && freeSolo && (
              <ListItemIcon sx={{ minWidth: "unset" }}>
                <Checkbox edge="start" checked={selected} />
              </ListItemIcon>
            )}

            {option.icon && (
              <ListItemIcon sx={{ minWidth: "unset" }}>
                {typeof option.icon === "string" ? (
                  <img
                    loading="lazy"
                    src={option.icon}
                    alt={`${option.label}_img`}
                  />
                ) : (
                  option.icon
                )}
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <>
                  {option.label}
                  <Typography variant="subText">
                    {option.labelContext}
                  </Typography>
                </>
              }
              secondary={
                <Stack
                  component="span"
                  direction="row"
                  alignItems="center"
                  gap="0.5rem"
                >
                  {typeof option.itemSecondaryIcon === "string" ? (
                    <img
                      loading="lazy"
                      src={option.itemSecondaryIcon}
                      alt={`${option.label}_img_secondary`}
                    />
                  ) : (
                    option.itemSecondaryIcon
                  )}
                  {option.suggested ? "Suggested" : ""}
                </Stack>
              }
            />
          </ListItemStyled>
        )}
        popupIcon={<IconoirIcon icon={NavArrowDown} />}
        clearIcon={
          value && <IconoirIcon width="2rem" height="2rem" icon={Xmark} />
        }
        renderInput={(params) => (
          <TextInputV2
            {...params}
            onBlur={_noop}
            onChange={_noop}
            required={required}
            value={undefined}
            name={name}
            label={label}
            labelContext={labelContext}
            error={error}
            ref={ref}
            placeholder={placeholder}
            variant={variant}
            reducesize={hasChip}
            helperText={helperText}
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isOptionLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  }
);

AutocompleteInput.displayName = "AutocompleteInputComponent";

import React from "react";

export function IconoirIcon({
  icon: Icon,
  width = "2.4rem",
  height = "2.4rem",
  ...rest
}) {
  return <Icon width={width} height={height} {...rest} />;
}

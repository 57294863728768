import { styled } from "@mui/material/styles";
import { Card, CardMedia, CardActions, IconButton } from "@mui/material";

export const CustomCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  borderRadius: "12px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
}));

export const CustomCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 200,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
}));

export const DateOverlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "16px",
  right: "16px",
  background: "rgba(255, 255, 255, 0.9)",
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "0.875rem",
}));

export const AvatarContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  padding: "16px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
  background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)",
}));

export const UserInfo = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

export const CardInfo = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  flex: 1,
}));

export const CardBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  justifyContent: "space-between",
}));

export const CustomCardActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(0),
  justifyContent: "space-between",
  marginTop: "auto",
}));

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
}));

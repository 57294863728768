import _isFunction from "lodash/isFunction";
import _truncate from "lodash/truncate";
import { Box, Stack } from "@mui/material";
import { NavArrowDown, NavArrowUp } from "iconoir-react";
import { useState } from "react";

import { IconButtonStyled } from "./UserProfileMenu.styled";
import { IconoirIcon } from "../IconoirIcon";
import { Menu } from "./Menu";
import { MenuItem } from "./MenuItem";
import { Typography } from "../Typography";
import { UserAvatar } from "../Avatars";
import { UserDetails } from "./UserDetails";

export function UserProfileMenuV2({
  userDetails,
  onUserDetailsClick,
  fullNameMaxLength = 25,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const truncatedFullName = userDetails?.display_name
    ? _truncate(userDetails.display_name, {
        length: fullNameMaxLength,
        omission: "...",
      })
    : "";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      <IconButtonStyled size="small" onClick={handleClick} isOpened={open}>
        <Stack direction="row" alignItems="center" gap={1}>
          <UserAvatar
            firstName={userDetails?.profile?.first_name || ""}
            lastName={userDetails?.profile?.last_name || ""}
            src={
              "https://api.mochsha.in" +
                userDetails?.profile?.profile_picture || ""
            }
          />
          <Box>
            <Typography variant="bodySmall" fontWeight="regular">
              {truncatedFullName}
            </Typography>
          </Box>

          {open ? (
            <IconoirIcon width="2.5rem" height="2.5rem" icon={NavArrowUp} />
          ) : (
            <IconoirIcon width="2.5rem" height="2.5rem" icon={NavArrowDown} />
          )}
        </Stack>
      </IconButtonStyled>
      <Menu
        anchorEl={anchorEl}
        open={open}
        closeMenu={handleClose}
        sx={{
          "& .MuiPaper-root": {
            marginTop: "0.5rem",
            width: "29rem",
          },
        }}
        {...props}
      >
        <MenuItem
          key="userDetails"
          onClick={() => {
            if (_isFunction(onUserDetailsClick)) {
              onUserDetailsClick();
            }
          }}
          sx={{
            pointerEvents: _isFunction(onUserDetailsClick) ? "auto" : "none",
            "&:hover": {
              backgroundColor: "unset !important",
              ".MuiTypography-subText": {
                fontWeight: `400 !important`,
              },
            },
          }}
          disableRipple
          disableTouchRipple
        >
          <UserDetails
            avatarUrl={
              "https://api.mochsha.in" + userDetails?.profile?.profile_picture
            }
            fullName={`${userDetails?.profile?.first_name} ${userDetails?.profile?.last_name}`}
            email={userDetails?.email}
            {...userDetails}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}

import React from "react";
import { IconButton } from "@mui/material";

import { ArrowLeft, ArrowRight } from "iconoir-react";

import { IconoirIcon } from "../IconoirIcon";

import { styled } from "@mui/material/styles";

const ArrowButton = styled(IconButton)(({ theme, direction }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  opacity: 0.8,
  transition: "all 0.3s ease",
  padding: theme.spacing(1),
  [direction]: theme.spacing(1),

  "&:hover": {
    backgroundColor: theme.mochshaColorPalette.galacticGrape[800],
    opacity: 1,
    [direction]: theme.spacing(0.5),
  },

  "& svg": {
    fontSize: "2rem",
    color: theme.palette.text.primary,
    transition: "color 0.3s ease",
  },

  "&:hover svg": {
    color: theme.palette.common.white,
  },

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5),
    "& svg": {
      fontSize: "1.5rem",
    },
  },
}));

export const CustomLeftArrow = ({ onClick, ...rest }) => (
  <ArrowButton onClick={() => onClick()} direction="left" {...rest}>
    <IconoirIcon width="2rem" height="2rem" icon={ArrowLeft} />
  </ArrowButton>
);

export const CustomRightArrow = ({ onClick, ...rest }) => (
  <ArrowButton onClick={() => onClick()} direction="right" {...rest}>
    <IconoirIcon width="2rem" height="2rem" icon={ArrowRight} />
  </ArrowButton>
);

import { ButtonStyled } from "./Button.styled";

export const Button = ({
  variant = "primary",
  size = "medium",
  onClick,
  ...rest
}) => {
  return (
    <ButtonStyled
      disableRipple
      onClick={onClick}
      variant={variant}
      size={size}
      {...rest}
    />
  );
};

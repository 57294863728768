import React from "react";
import {  Avatar, Stack, Typography, IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Star } from "iconoir-react";

const HeaderWrapper = styled(Stack)({
  direction: "row",
  backgroundColor: "#1E1E1E",
  padding: "1rem",
  gap: "1rem",
});

export function Header({ avatar, name, rating, role }) {
  const theme = useTheme();
  return (
    <HeaderWrapper>
      <Stack direction="row" gap="1rem" alignItems="center">
        <Avatar alt={name} src={avatar} />
        <Stack spacing="0.2rem">
          <Stack direction="row" gap="0.5rem" alignItems="center">
            <Typography variant="body" color="white">
              {name} • {rating} 
            </Typography>
            <Star color={theme.mochshaColorPalette.galacticGrape[300]}  width={12} height={12}/>
          </Stack>
        <Typography variant="subText" color="white">
          {role}
        </Typography>
        </Stack>
      </Stack>
    </HeaderWrapper>
  );
}

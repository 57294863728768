import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Card } from "../card/"; // Ensure correct import path
import { CustomLeftArrow, CustomRightArrow } from "./CustomArrows";
import { useUser } from "../../context/UserContext";

const CarouselContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const CardCarousel = ({
  cards,
  itemsPerRow = { desktop: 4, tablet: 2, mobile: 1 },
  infiniteLoop = false,
  autoPlay = false,
  slidesToScroll = 1,
  showArrows = true,
  customTransition = "transform 0.8s ease-in-out",
  transitionDuration = 800,
  onBidNowClick,
}) => {
  const { desktop, tablet, mobile } = itemsPerRow;

  const { role } = useUser();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: desktop + 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: desktop,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: tablet,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: mobile,
    },
  };

  const carouselConfig = {
    responsive,
    infinite: infiniteLoop,
    autoPlay,
    keyBoardControl: true,
    customTransition,
    transitionDuration,
    containerClass: "carousel-container",
    removeArrowOnDeviceType: showArrows ? [] : ["tablet", "mobile"],
    customLeftArrow: showArrows ? <CustomLeftArrow /> : null,
    customRightArrow: showArrows ? <CustomRightArrow /> : null,
    slidesToSlide: slidesToScroll,
  };

  return (
    <CarouselContainer>
      <Carousel {...carouselConfig}>
        {cards.map((card, index) => (
          <Box key={index} sx={{ padding: 1 }}>
            {role === "provider" && (
              <Card
                id={card.id}
                title={card.title}
                description={card.description}
                event_date={card.event_date}
                budget_min={card.budget_min}
                budget_max={card.budget_max}
                category={card.category}
                language={card.language}
                genre={card.genre}
                location={card.location}
                event_status={card.event_status}
                organizer_id={card.organizer_id}
                organizer_name={card.organizer_name}
                organizer_avatar={card.organizer_avatar}
                onSaveClick={() => {
                  /* Implement save functionality */
                }}
                onBidNowClick={() => onBidNowClick(card)}
              />
            )}
            {role === "client" && <Card provider_data={card} />}
          </Box>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default CardCarousel;

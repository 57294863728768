import { Stack, Box, styled } from "@mui/material";

export const ResponsiveStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
  },
}));

export const ContentStack = styled(Stack)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "flex-start",
  },
}));

export const FormBox = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const SkillStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "flex-end",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "2rem",
  },
}));

import React from "react";
import "./Reviews.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";

const reviews = [
  {
    name: "Alice Smith",
    rating: 5,
    comment: "Amazing performance! Highly recommend.",
  },
  {
    name: "Bob Johnson",
    rating: 4,
    comment: "Great musician, very professional.",
  },
  {
    name: "Charlie Brown",
    rating: 3,
    comment: "Good, but could improve in some areas.",
  },
];

const Reviews = () => {
  return (
    <div className="reviews-container">
      {reviews.map((review, index) => (
        <div key={index} className="review-card">
          <div className="review-header">
            <h3>{review.name}</h3>
            <div className="review-rating">
              {[...Array(5)].map((_, i) => (
                <FontAwesomeIcon
                  key={i}
                  icon={faStarRegular}
                  className={i < review.rating ? "filled" : ""}
                />
              ))}
            </div>
          </div>
          <p>{review.comment}</p>
        </div>
      ))}
    </div>
  );
};

export default Reviews;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebookF,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/logo/Completelogo.png";
import axios from "axios";
import { Button } from "../Button";
import {
  Stack,
  Typography,
  CircularProgress,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TextFieldV2 } from "../Forms/Fields/TextFieldV2";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.mochshaColorPalette.mistyMint[900],
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "2rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "2rem 2rem",
    gap: "2rem",
  },
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  width: "30%",

  [theme.breakpoints.down("md")]: {
    width: "45%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: "2rem",
  },
}));

const FormStack = styled(Stack)(({ theme }) => ({
  width: "40%",
  [theme.breakpoints.down("md")]: {
    width: "45%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const LogoImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "40rem",
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  width: "5rem",
  height: "5rem",
  borderRadius: "50%",
  border: "1px solid black",
  [theme.breakpoints.down("md")]: {
    width: "4rem",
    height: "4rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "3rem",
    height: "3rem",
  },
}));

const schema = z
  .object({
    email: z.string().email("Invalid email address"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    retypePassword: z.string(),
  })
  .refine((data) => data.password === data.retypePassword, {
    message: "Passwords do not match",
    path: ["retypePassword"],
  });

export const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      retypePassword: "",
    },
  });

  const handleGoogle = async () => {
    try {
      window.location.href = "https://api.mochsha.in/auth/google";
    } catch (error) {
      console.error("Error signing up with Google:", error);
      setError("An error occurred during sign up");
    }
  };

  const handleSignUp = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.mochsha.in/api/v1/users/register",
        {
          email: formData.email,
          password: formData.password,
        }
      );
      if (response && response.data) {
        console.log("Sign up successful:", response.data);
        navigate("/otp", { state: { email: formData.email } });
      } else {
        console.error("Unexpected response structure:", response);
        setError("An error occurred during sign up");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Email already exists");
      } else {
        console.error("Error signing up:", error);
        setError("An error occurred during sign up");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <ContentStack spacing="2rem">
        <Typography variant="titlePage" fontWeight="bold">
          Join the Groove at Mochsha!
        </Typography>
        <Typography variant="titlePage" fontWeight="bold" textAlign="center">
          The Digital Marketplace For Live Music
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <LogoImage src={logo} alt="Website Logo" />
        </Box>
      </ContentStack>
      <FormStack spacing="2rem">
        <Typography variant="bodyStrong" fontWeight="bold" align="center">
          Sign up with
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <SocialIconButton onClick={handleGoogle}>
            <FontAwesomeIcon
              icon={faGoogle}
              size={isSmallScreen ? "sm" : "lg"}
            />
          </SocialIconButton>
        </Stack>
        <Typography align="center">or</Typography>
        <TextFieldV2
          control={control}
          label="Email"
          name="email"
          type="email"
          fullWidth
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextFieldV2
          control={control}
          label="Password"
          name="password"
          type="password"
          fullWidth
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextFieldV2
          control={control}
          label="Retype Password"
          name="retypePassword"
          type="password"
          fullWidth
          error={!!errors.retypePassword}
          helperText={errors.retypePassword?.message}
        />
        {error && <Typography color="error">{error}</Typography>}

        <Stack
          width="100%"
          justifyContent="center"
          alignItems="center"
          paddingY="1.5rem"
        >
          <Button
            onClick={handleSubmit(handleSignUp)}
            disabled={loading}
            sx={{ width: "50%" }}
          >
            {loading ? <CircularProgress size={24} /> : "Sign Up"}
          </Button>
        </Stack>

        <Typography align="center">
          Already have an account? <a href="/login">Log in</a>
        </Typography>
      </FormStack>
    </PageContainer>
  );
};

import { mochshColorpalette } from "./palette";

export const buttonPalette = {
  root: {
    disabledBackgroundColor: mochshColorpalette.palette.deepCharcoal[400],
  },
  primary: {
    activeBackgroundColor: mochshColorpalette.palette.deepCharcoal[400],
    activeBorderColor: mochshColorpalette.palette.deepCharcoal[700],
    activeColor: mochshColorpalette.palette.deepCharcoal[900],
    backgroundColor: mochshColorpalette.palette.deepCharcoal[900],
    borderColor: mochshColorpalette.palette.deepCharcoal[900],
    color: mochshColorpalette.palette.galacticGrape[200],
    disabledColor: mochshColorpalette.palette.galacticGrape[200],
    hoverBackgroundColor: mochshColorpalette.palette.deepCharcoal[800],
  },
  secondary: {
    activeBackgroundColor: mochshColorpalette.palette.galacticGrape[400],
    backgroundColor: mochshColorpalette.palette.galacticGrape[200],
    borderColor: mochshColorpalette.palette.galacticGrape[600],
    color: mochshColorpalette.palette.galacticGrape[900],
    disabledBorderColor: mochshColorpalette.palette.galacticGrape[600],
    hoverBackgroundColor: mochshColorpalette.palette.galacticGrape[300],
  },
  text: {
    activeBackgroundColor: mochshColorpalette.palette.cosmicTangerine[500],
    activeBorderColor: mochshColorpalette.palette.cosmicTangerine[700],
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: mochshColorpalette.palette.cosmicTangerine[900],
    hoverBackgroundColor: mochshColorpalette.palette.galacticGrape[300],
    hoverBorderColor: mochshColorpalette.palette.cosmicTangerine[500],
  },
  danger: {
    activeBackgroundColor: mochshColorpalette.palette.galacticGrape[400],
    backgroundColor: mochshColorpalette.palette.galacticGrape[200],
    borderColor: mochshColorpalette.palette.galacticGrape[600],
    color: mochshColorpalette.palette.eclipseCherry[700],
    disabledBorderColor: mochshColorpalette.palette.galacticGrape[600],
    hoverBackgroundColor: mochshColorpalette.palette.galacticGrape[300],
  },
};

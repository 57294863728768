import { forwardRef } from "react";
import { Box } from "@mui/material";

import { CommonDatePickerTextFieldStyledV2 } from "./CommonDatePickerTextInputV2.styled";

export const DatePickerTextFieldV2 = forwardRef(
  ({ disabled, id, ...params }, ref) => {
    return (
      <Box ref={ref}>
        <CommonDatePickerTextFieldStyledV2
          aria-label={`${id}-datepicker`}
          id={id}
          disabled={disabled}
          {...params}
        />
      </Box>
    );
  }
);

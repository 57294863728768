import React, { useEffect, useState } from "react";
import { Stack, styled } from "@mui/material";
import { useForm } from "react-hook-form";
import { CheckCircle } from "iconoir-react";
import { RadioCardGroupField } from "../Forms/Fields/RadioField";
import { BottomSheetDialog } from "../BottomSheetDialog";
import { Typography } from "../Typography";
import { Button } from "../Button";
import { MusicianOnBoardingDialog } from "../MusicianOnboarding/MusicianOnboardingDialog";
import { ProfileDialog } from "../ProfileDialog/ProfileDialog";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { ClientOnBoardingDialog } from "../ClientOnboarding/ClientOnBoardingDialog";
import { useQueryClient } from "@tanstack/react-query";

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: "800px",
  margin: "0 auto",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
  },
}));

const ContentWrapper = styled(Stack)(({ theme }) => ({
  minHeight: "50vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const RadioGroupWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  margin: "0 auto",
  "& .MuiFormGroup-root": {
    gap: theme.spacing(2),
  },
}));

const ActionStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

const JourneyDialog = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [isSelectJourneyDialogOpen, setIsSelectJourneyDialogOpen] =
    useState(false);

  const [isMusicianOnboardingDialogOpen, setIsMusicianOnboardingDialogOpen] =
    useState(false);

  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);

  const [isClientOnboardingDialogOpen, setIsClientOnboardingDialogOpen] =
    useState(false);

  const { userDetails } = useUser();

  useEffect(() => {
    if (userDetails?.profile) {
      navigate("/");
    }
  }, [userDetails]);

  const journeyOptions = [
    {
      id: "provider",
      icon: CheckCircle,
      title: "I'm a service provider",
      description: " Looking to get hired for music events.",
    },
    {
      id: "client",
      icon: CheckCircle,
      title: "I'm a client",
      description: "Hiring service provider for my music event.",
    },
  ];

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      journeyOption: "provider",
    },
  });

  const onSubmit = (data) => {
    console.log(data, "data");
    setIsSelectJourneyDialogOpen(false);
    setIsProfileDialogOpen(true);
    //   setIsMusicianOnboardingDialogOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return; // Prevents closing when clicking outside
    }
    setIsSelectJourneyDialogOpen(false);
  };

  useEffect(() => {
    setIsSelectJourneyDialogOpen(true);
  }, []);

  return (
    <>
      <ResponsiveStack>
        <BottomSheetDialog
          title={
            <Typography variant="heading" fontWeight="bold">
              Select Your Journey Option
            </Typography>
          }
          open={isSelectJourneyDialogOpen}
          onClose={handleClose}
          actions={
            <ActionStack direction="row" justifyContent="flex-end" width="100%">
              <Button
                autoFocus
                onClick={handleSubmit(onSubmit)}
                sx={{ minWidth: { xs: "100%", sm: "auto" } }}
              >
                Continue
              </Button>
            </ActionStack>
          }
          isCloseIconVisible={false}
          disableEscapeKeyDown
          hideBackdrop
        >
          <ContentWrapper>
            <RadioGroupWrapper>
              <RadioCardGroupField
                name="journeyOption"
                options={journeyOptions}
                control={control}
              />
            </RadioGroupWrapper>
          </ContentWrapper>
        </BottomSheetDialog>
      </ResponsiveStack>
      <MusicianOnBoardingDialog
        isDialogOpen={isMusicianOnboardingDialogOpen}
        setIsDialogOpen={setIsMusicianOnboardingDialogOpen}
        onClose={() => {
          setIsMusicianOnboardingDialogOpen(false);
          setIsSelectJourneyDialogOpen(true);
        }}
      />
      <ClientOnBoardingDialog
        isDialogOpen={isClientOnboardingDialogOpen}
        setIsDialogOpen={setIsClientOnboardingDialogOpen}
        onSuccess={async () => {
          await queryClient.invalidateQueries({
            queryKey: ["userDetails"],
          });
        }}
      />
      <ProfileDialog
        isDialogOpen={isProfileDialogOpen}
        setIsDialogOpen={setIsProfileDialogOpen}
        role={watch("journeyOption")}
        onClose={() => {
          setIsProfileDialogOpen(false);
          setIsSelectJourneyDialogOpen(true);
        }}
        onSuccess={async () => {
          setIsSelectJourneyDialogOpen(false);
          setIsProfileDialogOpen(false);
          if (watch("journeyOption") === "provider") {
            setIsMusicianOnboardingDialogOpen(true);
          }

          if (watch("journeyOption") === "client") {
            setIsClientOnboardingDialogOpen(true);
          }
        }}
      />
    </>
  );
};

export default JourneyDialog;

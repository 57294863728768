import { InputLabel as MuiInputLabel, useTheme, Stack } from "@mui/material";

import React from "react";

export function InputLabel({
  label,
  disabled,
  labelContext,
  htmlFor,
  ...rest
}) {
  const theme = useTheme();

  return (
    <Stack gap="0.4rem">
      <MuiInputLabel
        sx={{
          fontSize: "1.3rem",
          fontWeight: 700,
          color: disabled
            ? theme.mochshaColorPalette.galacticGrape[500]
            : theme.mochshaColorPalette.galacticGrape[700],
        }}
        htmlFor={htmlFor}
        {...rest}
      >
        {label}
      </MuiInputLabel>

      {labelContext && (
        <MuiInputLabel
          sx={{
            fontSize: "1.1rem",
            color: disabled
              ? theme.mochshaColorPalette.cosmicTangerine[600]
              : theme.mochshaColorPalette.cosmicTangerine[800],
            lineHeight: "1.6rem",
            whiteSpace: "break-spaces",
          }}
          htmlFor={htmlFor}
          {...rest}
          required={false}
        >
          {labelContext}
        </MuiInputLabel>
      )}
    </Stack>
  );
}

import { outlinedInputClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CommonDatePickerTextFieldV2 } from './CommonDatePickerTextFieldV2';

export const CommonDatePickerTextFieldStyledV2 = styled(
  CommonDatePickerTextFieldV2
)(() => {
  const style = {
    [`& .${outlinedInputClasses.root}`]: {
      height: '3.6rem',
    },
  };

  return style;
});
